import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { admin_maintenance_type, base_url, date_formats, quiz_name, random_number,api_error_message } from "../../utilities";
import Dropzone from "react-dropzone";
import ProgressBar from 'react-bootstrap/ProgressBar';
// import 'react-dropzone-uploader/dist/styles.css'
// import { getDroppedOrSelectedFiles } from 'html5-file-selector'
// import Dropzone from 'react-dropzone-uploader'
import moment from "moment";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
const FileUpload = (props) => {
  const [flag, setFlag] = useState();
  let [file, setFile] = useState();
  let [file_ext, setFileExt] = useState();
  let [file_size,setFileSize] = useState()
  let [loader,setLoader] = useState(false)
  let [file_name,setFileName] = useState()
  let [loader_flag, setLoaderFlag] = useState(false);
  let [outerloader_flag, setOuterLoaderFlag] = useState(true);
  let [progress, setProgress] = useState(0)
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  let [disable_flag, setDisableFlag] = useState(false);
  let history = useHistory();
  let propsData = JSON.parse(history.location.state.Fileupload_ProgressTracking)
  const [disableSelectButton, setDisableSelectButton] = useState(false);
  let location= useLocation()
  useEffect(() => {
    if(!localStorage.getItem("file_flag"))
    {
      history.push('/modulepage')
    }
    else{
      setOuterLoaderFlag(false)
    }
    document.getElementById("top-nav-bar").style.display = "none";
    const disableBackButton = () => {
      window.history.pushState(null, null, window.location.href);
      window.onpopstate = () => {
        window.history.pushState(null, null, window.location.href);
      };
    };
    disableBackButton();
    return () => {
      window.onpopstate = null; // Cleanup when the component unmounts
    };
  }, []);

  const goBack = () => {
    localStorage.removeItem("file_flag");
    if (!isiPad()) {
       enterFullScreen();
    }
    if(propsData){
      localStorage.setItem("moduleId",propsData.data.module_id)
      localStorage.setItem("course_id",propsData.data.course_id)
      localStorage.setItem("cohort_id",propsData.data.cohort_sch_id)
      localStorage.setItem("lessonId",propsData.data.lesson_id)
    }
    history.push("/lessonpage", {
      id: JSON.parse(localStorage.getItem("id")),
      moduleId: propsData?propsData.data.module_id:localStorage.getItem("moduleId"),
      courseId: propsData?propsData.data.course_id :localStorage.getItem("course_id"),
      lessons_completed: localStorage.getItem("lessons_completed"),
      index: localStorage.getItem("index"),
      cohort_id : propsData?propsData.data.cohort_sch_id:localStorage.getItem("cohort_id"),
      lesson_id: propsData?propsData.data.lesson_id:localStorage.getItem("lessonId")
    });
  };

  const isiPad=()=> {
    // Check if the device has touch capabilities
    const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  
    // Check for specific iPad behaviors or features
    const isiPadBehavior = (
      // Check for iPad-specific touch events
      (hasTouch && typeof Touch !== 'undefined' && typeof TouchEvent !== 'undefined' && typeof TouchList !== 'undefined') ||
      // Check for iPad-specific gesture events
      (hasTouch && typeof window.orientation !== 'undefined')
      // Add more iPad-specific checks if needed
      // ...
    );
  
    // Check if the screen size indicates an iPad
    const isiPadScreenSize = (
      window.screen.width >= 768 && window.screen.height >= 1024
    );
  
    // Check if the device is running on the Windows operating system
    const isWindows = /Windows/i.test(navigator.userAgent);
  
    // Combine all the checks
    return isiPadBehavior && isiPadScreenSize && !isWindows;
  }

  const onDrop = (file) => {
    setDisableSelectButton(true)
    setLoader(true)
    setFileName(file[0].name)
    if (file) {
      let size = file[0].size / (1024 * 1024)
      // toast.success("File selected. Please click SUBMIT to continue", {
      //   toastId: random_number,
      // });
      
    const url = base_url + "sla/save_post_sla_document";
      if (size < 10) {
      let formData = new FormData();
      formData.append("file", file[0]);
      formData.append("sla_id", localStorage.getItem("sla_id"));
      formData.append("user_id", localStorage.getItem("user_id"));
      formData.append(
        "lesson_id",propsData?propsData.data.lesson_id:
        localStorage.getItem("lessonId")
      );
      formData.append("cohort_schedule_id", propsData?propsData.data.cohort_sch_id:localStorage.getItem("cohort_id"));
      setDisableFlag(true);
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: data => {
            //Set the progress value to show the progress bar
            setProgress(Math.round((100 * data.loaded) / data.total))
            if(Math.round((100 * data.loaded) / data.total) == 100){
              setLoaderFlag(true);
            }
          },
        })
        .then((response) => {
          if (response.data.status == 200) {
            setLoaderFlag(false);
            setLoader(false)
            setProgress(0)
            setDisableSelectButton(true)
            handleProgressTracking(response.data.message)
          }
        })
        .catch((err) => {
          console.log(err);
          setLoaderFlag(false);
          setProgress(0)
          toast.warn(api_error_message, {
            toastId: random_number,
            onClose: () => {
              setDisableSelectButton(false);
            },
          });
        });
    } else {
      setLoaderFlag(false);
      toast.error("File size is larger than 10MB", {
        toastId: random_number,
        onClose: () => {
          setDisableSelectButton(false);
        },
      });
    }
    }
    setFileSize(file[0].size / (1024 * 1024)); // Convert file size to MB
 
    const name = file[0].name;
    const ext = name.split(".")[1];
    setFileExt(ext.toLowerCase());
    setFile(file[0]);
  };
  
const handleProgressTracking=(message)=>{
  setDisableSelectButton(true)
  if(propsData)
  {
    let purpose = propsData.purpose;
    const activityEndTime=moment()
    let body = {
      user_id: localStorage.getItem("user_id"),
      course_id: propsData.data.course_id,
      module_id:propsData.data.module_id,
      lesson_id: propsData.data.lesson_id,
      cohort_schedule_id: propsData.data.cohort_sch_id,
      activity_id: propsData.data.activity_id,
      activity_entity_type: propsData.data.acitivity_entity_type,
      is_question: true,
      user_answer:"file",
      is_correct: true,
      question_type:propsData.data.subType,
      answer_label: "upload file",
      is_quiz: purpose ==quiz_name?  true: false,
      time_spent_on_activity:activityEndTime.diff(activityInitiatedTime,'seconds'),
      is_file_upload: true,
      activity_submitted_platform: "ISBo"
    };
    if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
    axios
      .post(base_url + "user/progress_tracking", body)
      .then((res) => {
        localStorage.setItem("moduleId",propsData.data.module_id)
        localStorage.setItem("course_id",propsData.data.course_id)
        localStorage.setItem("cohort_id",propsData.data.cohort_sch_id)
        localStorage.setItem("lessonId",propsData.data.lesson_id)
        setDisableFlag(false);
        toast.success(message, {
          toastId: random_number,
          onClose: () => {
            setDisableSelectButton(false);
          },
        });
      setLoaderFlag(false);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message, {
            toastId: random_number,
            onClose: () => {
              setDisableSelectButton(false);
            },
          });
        }
      });
    }
    else{
      toast.success(message, {
        toastId: random_number,
        onClose: () => {
          setDisableSelectButton(false);
        },
      });
    }
  }
  else{
    setDisableFlag(false);
    setLoaderFlag(false)
    toast.success(message, {
      toastId: random_number,
      onClose: () => {
        setDisableSelectButton(false);
      },
    });   
  }
}
  const enterFullScreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen().catch();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen();
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen();
    }
  };
 
  return (
    <>
    {outerloader_flag?<>
            <div>
                <img
                  className="atrium_loader"
                  src="images/atrium_loader.gif"
                  alt="Loader"
                />
              </div>
    </>:
    <>
      <div className="text-center mt-4">
        <div className="col-9 position-relative d-flex justify-content-center m-auto">
          <span data-testid="clip_path" className="flex-row blue_text">
            {localStorage.getItem("course_name")}&nbsp;
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4465_21304)">
                <path
                  d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                  fill="#708FAB"
                />
              </g>
              <defs>
                <clipPath id="clip0_4465_21304">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>{" "}
            {localStorage.getItem("module_name")}&nbsp;
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_4465_21304)">
                <path
                  d="M3.92188 2.74732L9.17521 8.00065L3.92188 13.254L5.33521 14.6673L12.0019 8.00065L5.33521 1.33398L3.92188 2.74732Z"
                  fill="#708FAB"
                />
              </g>
              <defs>
                <clipPath id="clip0_4465_21304">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            &nbsp;{localStorage.getItem("lesson_name")?.replace(/ ?- ?.*/, "")}
          </span>
        </div>
      </div>
    
      <div className="file_upload_div bg-body col-8">
     
        <div className="p-4 pb-0">
        <div  data-testid="file_upload_text">
          <h3 className="text-left fw-700">Upload Your Submission</h3>
<br/>
<ul>
  <li>Drag and drop your file here or select your file from the file explorer
</li>
  <li>Then, click the Back button to return to the previous screen and continue</li>
</ul>
          </div>
          <br/>
          <Dropzone onDrop={onDrop} multiple={false} disabled={disableSelectButton}
      >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div
                  {...getRootProps(
                    !flag ? { className: "dropzone" } : { className: "" }
                  )}
                >
                  <img src="/images/file_upload.svg"></img>
                  <div data-testid="Drag_and_drop" className="mt-2">
                    <span>Drag & Drop Files here</span>
                    <br></br>
                    <span>or</span>
                    <input {...getInputProps()} />
                  </div>
                {/* </div> */}
                {/* <div> */}
                  <button
                    type="button" data-testid="Select_file" disabled={disableSelectButton}
                    // {...getRootProps()}
                    className="select_file_button btn mt-2"
                  >
                    <b>SELECT FILE</b>
                  </button>
                </div>
              </section>
            )}
          </Dropzone>
          <div className="track_image_class text-center pt-4">
      
         <br/>
            {loader && <div data-testid="fileUpload_progress" className="row progress_div">
              <div className="col-1">
              <img  src="/images/sla_file_upload.svg"></img>
              </div>
              <div className="col-11 text-left" >
                <span >{file_name} {progress == 100? ' Uploaded':' Uploading'}</span>
              
                {progress < 100? <span className="float-end">{ progress}% </span>:<img className="float-end" src="/images/tick.svg"></img>}
              {progress < 100?<ProgressBar now={progress} />:<><br/><span>{Math.round(file_size*1000)}KB</span></>}
              </div>
            
            </div>}
            <br/>
            <button data-testid="back_btn"
            disabled={loader_flag || disable_flag}
              className="btn my_outline_button_cls submit_button mr-4 my_certificate_btn file_upload_buttons"
              onClick={goBack}
            >
              BACK
            </button>
         

            {loader_flag && (
              <div data-testid="loader_atrium">
                <img
                  className="atrium_loader"
                  src="images/atrium_loader.gif"
                  alt="Loader"
                />
              </div>
            )}
            {/* <button
              disabled={loader_flag}
              className="btn my_btn_cls submit_button ml-5 file_upload_buttons"
              onClick={onSubmit}
            >
              SUBMIT
            </button> */}
          </div>
        </div>
      </div>
      </>}
    </>
  );
};
export default withMaintenanceCheck(FileUpload,admin_maintenance_type) 