
// Adressapplication form
export const addressAppplicationFormValidations ={ 
        firstName:{
            required: "First Name is required",
            minLength: {
            value: 3,
            message: 'First Name should be at least 3 characters',
            },
            pattern: {
            value: /^[A-Za-z\s]+$/i,
            message: "First Name should contain only alphabets",
            },
        },
        payeesEmail:{
            required: "Email is required",
            pattern: {
            value:
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            message: "Please enter a valid email",
            },

        },
        panId:{
            required: "Pan Id is required", 
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Pan Id is required",
              },
            minLength: {
            value: 10,
            message:
                "Pan Id must contain 10 characters",
            },
            pattern: {
            value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            message: "Please enter a valid pan card number"
            }
        },
        postalAddress1:{

            required: "Street Address 1 is required",
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Street Address 1 is required",
              },
            maxLength: {
                value: 60,
                message: "Sorry you are exceeding the limit",
            },
        },
        postalCity:{
            required: "City is required",
            minLength: {
            value: 4,
            message: 'City Name must contain between 4 and 20 characters',
            },
            maxLength: {
            value: 20,
            message: 'City Name must contain between 4 and 20 characters',
            },
            pattern:{
            value:/^[a-zA-Z~@#$^*()_+={}|\\,.?: -/[\]]*$/,
            message:'Please enter a valid City Name'
            }
        },
        billingcompany:{
            required: "Company is required",
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Company is required",
              },

        },
        terms_conditions_checkbox: {
          required: "Please accept the Terms and Conditions",
        },

        panCompanyId:{
          pattern: {
            value:/^(?!\s+$).*$/,
            message: "Company Pan is required",
            },
            required: "Company Pan is required", 
            minLength: {
            value: 10,
            message:
                "Pan Id must contain 10 characters",
            },
            pattern: {
            value: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
            message: "Please enter a valid pan card number"
            }
        },
        billingcompanygst:{
            required: "Company GST Number is required",
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Company GST Number is required",
              },
            pattern: {
            value: /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9]{1}[Z]{1}[0-9A-Za-z]{1}$/,
            message: "Please enter a valid gst number"
            },

        },
        billingStreetAddress1:{
            required: "Street Address 1 is required",
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Street Address 1 is required",
              },
            maxLength: {
                value: 60,
                message: "Sorry you are exceeding the limit",
            }
        },
        billingCity:{
            required: "City is required",
            minLength: {
            value: 4,
            message: 'City Name must contain between 4 and 20 characters',
            },
            maxLength: {
            value: 20,
            message: 'City Name must contain between 4 and 20 characters',
            },
            pattern:{
            value:/^[a-zA-Z~@#$^*()_+={}|\\,.?: -/[\]]*$/,
            message:'Please enter a valid City Name'
            }
  }
}

//online Application
export const onlineAppplicationFormValidations ={ 
    firstName:{
        required: "Name is required", 
        // pattern: {
        //   value: /^[A-Za-z ]+$/i,
        //   message: "First Name should contain only alphabets"
        // }
        maxLength: {
          value: 40,
          message: "Name cannot exceed 40 characters",
        },
    },


    Email:{
                  required: "Email is required", pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: 'Please enter a valid email',
                  },
    },
    company:{
                required: "Where do you work now is required",
                  maxLength: {
                    value: 30,
                    message: "Sorry you are exceeding the limit"
                  },
                  pattern: {
                    value:/^(?!\s+$).*$/,
                    message: "Where do you work now is required",
                    },
    },
    role:{
        required: "What’s your position in the company is required",
                  maxLength: {
                    value: 30,
                    message: "Sorry you are exceeding the limit",
                  },
                  pattern: {
                    value: /^(?!\s+$).*$/,
                    message: "What’s your position in the company is required",
                    },
    },
    years_company:{
        required: "How many years have you worked there is required"
    },
    industry:{
        required: "Industry is required"
    },
    total_years:{
        required: "How many years have you been working overall Is required",
    },
    description:{
        required: "Reasons are required"
    },
    Influencer :{
        required: "What influenced you to apply to this programme is required"
    },
    LearnProgram :{
        required: "How did you learn about this programme is required"
    },
    UserConsent1:{
        required: "Please accept"
    },
    UserConsent2: {
        required: "Please accept"
    }
}

//user profile
export const userProfileValidations ={
    first_name: {
        required: "Name is required", 
        // pattern: {
        //   value: /^[A-Za-z ]+$/i,
        //   message: "First Name should contain only alphabets"
        // }
      },

     email: {
        required: "Email is required",
        pattern: {
          value:
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          message: "Please enter a valid email",
        },
      },
      company:{
        required: "Where do you work now is required",
        pattern: {
          value:/^(?!\s+$).*$/,
          message: "Where do you work now is required",
          },
        maxLength: {
          value: 60,
          message: "Sorry you are exceeding the limit"
        }
      },
      role:{
        required: "What’s your position in the company is required",
        pattern: {
          value:/^(?!\s+$).*$/,
          message: "What’s your position in the company is required",
          },
        maxLength: {
          value: 60,
          message: "Sorry you are exceeding the limit",
        }
      },
      years_company:{
        required: "How many years have you worked there is required"
      },
      industry:{
        required: "Industry is required"
      },
      total_years:{
        required: "How many years have you been working overall Is required",
      },
      high_education:{
        required: "Highest academic qualification is required",
        pattern: {
          value:/^(?!\s+$).*$/,
          message: "Highest academic qualification is required",
          },
        maxLength: {
          value: 100,
          message: "Sorry you are exceeding the limit",
        },
      },
      institute_high:   {
            required:"Which institute did you study at",
            pattern: {
              value:/^(?!\s+$).*$/,
              message: "Which institute did you study at",
              },
            maxLength: {
              value: 200,
              message: "Sorry you are exceeding the limit",
            },
      },
      year_graduation:{
        required: "Which year did you graduate is required",
        minLength: {
          value: 4,
          message: "Please enter valid year"
        },
        pattern: {
          value: /^\d{4}$/,
          message: "Please enter valid year"
        },
        
      },
      dob:{
        required: "Date of birth is required",
       },
       gender:{
        required: "Gender is required"
     },
     pronoun:{
        required: "Pronoun is required"
     },
     street_Address_1:{
        required: "Street Address 1 is required",
        pattern: {
          value:/^(?!\s+$).*$/,
          message: "Street Address 1 is required",
          },

         maxLength: {
           value: 200,
           message: "Sorry you are exceeding the limit",
         },
       },
       street_address_2:{
        // required:"Street Address 2 is required",
         maxLength: {
           value: 200,
           message: "Sorry you are exceeding the limit",
         },
       },
       country:{
        required: "Country is required",
      },
      state:{
        required: "State is required",
      },
      postalCity:{
        required: "City is required",
      },
      city:{
        required: "City is required",
        minLength: {
          value: 4,
          message: 'City Name must contain between 4 and 20 characters',
        },
        maxLength: {
          value: 20,
          message: 'City Name must contain between 4 and 20 characters',
        },
        pattern:{
          value:/^[a-zA-Z~@#$^*()_+={}|\\,.?: -/[\]]*$/,
          message:'Please enter a valid City Name'
        }
      }

}

//cohort Add
export const cohortAddValidations={
    courseName:
        {
            required: "Course is required"
        },
        start_date:{
            required: "Start Date is required"
            },
        end_date:{
            required: "End Date is required"
            },
        available_date:{
            required: "Available Date is required"
            }

    
}

//coursesList
export const coursesListValidations={
    course_name:
        {
            required: "Course title required"
        },
    image_url:
       {  
        required: "Image url required"
        },
    file:{
        required: "Please upload a file"
        }
}

    //courseUpload 
    export const courseUploadValidations={
        course_title:
            {
                required: "Course title required"
            },
            LearningTrack:
          {  
            },
            course_cost:{
                required: "Course cost required"
            },
            file:{
                required: "Please upload a file"
            },
            durtnweeks:{

            },
            durtnrsperweek:{

            }
    }
    //createAttributes
    export const createAttributionsValidations={
      attribution_type:
              {
                required: "Course title required"
            },
            module:{
              required: "Module is required"
            },
            course:{
              required: "Course is required"
            }
    }

//isbuserpage
export const isbuserspageValidations={
  first_name:{
    required: "First Name is required",
    pattern: {
      value: /^[A-Za-z ]+$/i,
      message: "First Name should contain only alphabets",
    },
    maxLength: {
      value: 40,
      message: "First Name cannot exceed 40 characters",
    },
  },
  last_name: {
    required: "Last Name is required",
    pattern: {
      value: /^[A-Za-z ]+$/i,
      message: "Last Name should contain only alphabets",
    },
    maxLength: {
      value: 40,
      message: "Last Name cannot exceed 40 characters",
    },
  },
  email: {
    required: "Email is required",
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: "Please enter a valid email",
    },
  },
  course_name:'Course Name is  required'
}
//learningtrackadd
export const learningtrackaddValidations={
  learning_track_name :{
    required: "Learning Track Name is required"
},
image:{required: "Image URL is required"},
Description:{
  required: "Description is required"
}

}
//studentenrollment
export const studentenrollmentValidations={
emailto: 'To Email is required',

programme :{
        required: "programme is required"
      },
subject :{
  required: "Subject is required",
}
}
//freelessonlogin
export const freelessonloginValidations={
  name:{
    required: "Name is required", 
    minLength: {
      value: 3,
      message: 'Name should be atleast 3 characters',
    },
    maxLength: {
      value: 40,
      message: "Name cannot exceed 40 characters",
    },    
    pattern: {
      value: /^[A-Za-z/s ]+$/i,
      message: "Name should contain only alphabets"
    }
  },
  email:{
    required: "Email is required",
     pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email',
    },
  },
  role:{
    required: "Please select any option",
  },
  checkBox :{required: "Please select checkbox"}
  }
//createScheduleEmails
export const createScheduleEmailsValidations={

programme_name:{
  required: "Programme is required",
},
template_name:{
  required: "Template is required",
},
subject:{
  required: "Subject is required",
}
}
//createTemplate
export const createTemplateValidations={
  name:{
    required: "Name is required",
  },
  subject:{
    required: "Subject is required",
  }
}
//genericTemplate
export const genericTemplateValidations={
  emailto: "To Email is required",
  subject:{
    required: "Subject is required",
  }
}
//programmePolicy
export const programmePolicyValidations={
  Certificate_Percentage:{
    required: "Certificate Percentage is required",
  },
  Badge_Percentage :{
    required: "Badge Percentage is required",
  },
  course_duration:{
    required: "Soft Deadline is required",
   },
   course_buffer:{
    required: "Hard Deadline is required",
   },
   support_email: {
    required: "",
    pattern: {
      value: /^[^\s@]+@isb\.edu$/,
      message: 'Please ensure the email address is valid and ends with "@isb.edu"',
    }
  }
}
//scheduleTemplate
export const scheduleTemplateValidations={
  emailto:"To Email is required",
  subject:{
    required: "Subject is required",
  },
  email_body:"Email Body is required"
}