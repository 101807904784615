import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import './activityElementOrdering.css'
import { base_url,api_error_message,random_number, quiz_name, learn_by_doing, check_point, did_i_get_this } from "../../utilities";
import axios from "../../axios-interceptor";
import moment from "moment";
import { MessageComponent } from "./messageComponent";
import { useRef, useLayoutEffect } from "react";
import { List, arrayMove } from 'react-movable';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
export const ActivityElementOrdering = (props) => {
  const history = useHistory();
  const activity_element_Ordering = useRef(null)
  useLayoutEffect(() => {
    props.i == 0 ?
      setTimeout(() => {
        window.scrollTo({
          top: window.scrollY,
          left: 0,
          behavior: "smooth",
        });
      }, 350) :
      setTimeout(() => {
        window.scrollTo({
          top: activity_element_Ordering.current.offsetTop - 40,
          left: 0,
          behavior: "smooth",
        });
      }, 350)
  }, []);
  const [list, setList] = useState([]);
  let text = '', images = [];
  const [list_objs, setListObjs] = useState([]);
  const [suceess_msg, setSuccessMessage] = useState('')
  let [points_data,setPointsData] = useState({})
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  var [flag, setFlag] = useState(true)
  let [peer_flag, setPeerFlag] = useState(false)
  var [msg, setMsg] = useState('')
  let title = props.title;
  let purpose = props.purpose;
  
  // let text 
  // for (const value of props.data.content.stem.content) {
  //   if (value?.children) {
  //      if(value?.type=='h2')
  //     {
  //       text += `<h2 class="question-title">${value.text}</h2>`

  //     }}}

  for (const value of props.data.content.stem.content) {
    if (value?.children) {
       if(value?.type=='ol')
      {
        recursive_ol_data(value.children);
      }
      else if(value?.type=='ul')
      {
        recursive_ul_data(value.children);
      }
      for (const value1 of value.children) {
        if (value1.type === 'a') {
          text += `<a target= 'blank' href='${value1.href}'>${value1.children[0].text}</a>`
        }
        else if (value.type === 'img') {
          images.push(value.src);
        }
        else if (value1.type === 'img') {
          images.push(value1.src);
        }
        else if (value.type === 'h2') {
          text += `<h2 class="question-title">${value1.text}</h2>`
        }
        else {
          if (value1?.strong) {
            text += '<strong class="summary_header_cls">';
            text += value1?.text ? value1.text : ''
            text += '</strong>';
          } else {
            if(value1?.text ){
              text += `<span class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              if(value1.em){
                text += `<em class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</em>';
              }else if (value1?.strong) {
                text += `<strong class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</strong><br/>';
              }
              else{
                text += value1?.text ? value1.text : ''
                text += '</span>';
              }
            }
          
          }
        
        }
      }
    }
  }
  function recursive_ol_data(data){
    let str_li = '<ol class="summary_uList_cls">';
    for(const value of data){         
        for(const value1 of value.children){
          if (value1?.strong) {
            str_li += '<li class="summary_list_cls"><strong class="summary_header_cls">';
            str_li += value1.text;
            str_li += '</strong>';
          }else{
            str_li += '<li class="summary_list_cls">'+value1.text
          }
        }
        str_li += '</li>' 
      }
     text+=str_li+ '</ol>';
  }
  function recursive_ul_data(data){
    for(const value of data){ 
      if(value?.type !== 'li'){
        recursive_ul_data(value.children)
      }else{          
        let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
        for(const value1 of value.children){
          if (value1?.strong) {
            str_li += '<strong class="summary_header_cls">';
            str_li += value1.text;
            str_li += '</strong>';
          }else{
            str_li += value1.text
          }
          }
        str_li += '</li>' 
        text +=str_li+ '</ul>';
      }
    }
  }
  text=text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
  props.data.final_text = text;

  useEffect(() => {
    if(localStorage.getItem('re_attempt')){
      props.data.is_answered = false
    }
    if (props.data.is_answered) {
      setList([])
      let samp_list = []
      props.parentCallback('false');
      props.data.user_answer.map(item=>{
        props.data.content.choices.map((item1) => {
          if(item == item1.id){
            item1.content.map((i) => {
              i.children.map((item2) => {
                 samp_list.push(item2.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " "))
              });
            });
          }
         
        });
      })
      setList(samp_list)
      setFlag(false)
      setPeerFlag(true)
    }
    else{
      if (props.data && props.data.subType == "oli_ordering") {
        props.data.content.choices.map((item) => {
          item.content.map((i) => {
            i.children.map((item1) => {
              setList(prev => [...prev, item1.text])
              setListObjs(prev => [...prev, { text: item1.text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " "), id: item.id }])
            });
          });
        });
      }
    }
    const container = document.createElement('div');
    container.innerHTML = props.data.final_text;
    const h2Text = container.querySelector('h2.question-title')?.innerText.trim();
    const text = container.querySelectorAll('.summary_header_cls');
    const liElements = document.querySelectorAll('#checkAllthatApply .summary_list_cls');
    const headerTexts = Array.from(text).map(element => element.textContent.trim())
    const liTexts = Array.from(liElements).map(li => li.textContent.trim());
    let choice_array = []
    props.data.content.choices.map(item=>{
      item.content.map(item1=>{
        item1.children.map(i=>{
          choice_array.push(i.text)
        })
      })
    })
    const combinedText = [...headerTexts,...liTexts,'Arrange the options in the correct order', ...choice_array].join('. ');
    localStorage.setItem('prev_title',h2Text)
    localStorage.setItem('prev_text',combinedText)
  }, [])
  const onSubmitAnswer = () => {
    let final_list = []
    list.map(item=>{
      for(let obj of list_objs){
        if(obj.text == item){
           final_list.push(obj.id)
        }
      }
    })
    setTimeout(() => {
      window.scrollTo({
        top: window.scrollY + window.innerHeight,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
    let is_correct
    setFlag(false)
   
    if (JSON.stringify(props.data.content.authoring.correct[0]) == JSON.stringify(final_list)) {

      is_correct = true
      setSuccessMessage('')
      setSuccessMessage( props.data.content.authoring.parts[0].responses[0].feedback.content)
      // .map(item => {
      //   item.children.map(item1 => {
      //     setSuccessMessage(prev => [...prev, item1.text])
      //   })
      // })
      setMsg('yes')
    } else {
      setSuccessMessage('')
      is_correct = false
      setSuccessMessage(props.data.content.authoring.parts[0].responses[1].feedback.content)      
      // .map(item => {
      //   item.children.map(item1 => {
      //     setSuccessMessage(prev => [...prev, item1.text])
      //   })
      // })
      setMsg('no')
    }
    if (final_list.length) {
      setPeerFlag(true)
      const activityEndTime = moment()
      var body = {
        user_id: localStorage.getItem("user_id"),
        course_id: props.data.course_id,
        module_id: props.data.module_id,
        lesson_id: props.data.lesson_id,
        cohort_schedule_id: props.data.cohort_sch_id,
        activity_id: props.data.activity_id,
        activity_entity_type: props.data.acitivity_entity_type,
        is_question: true,
        user_answer: final_list,
        is_correct: is_correct,
        question_type: props.data.subType,
        answer_label: "Test",
        is_quiz: purpose ==quiz_name ?  true: false,
        time_spent_on_activity: activityEndTime.diff(activityInitiatedTime, 'seconds'),
        activity_submitted_platform: "ISBo"
      };
      if(localStorage.getItem("user_id") && window.location.pathname.toLowerCase().includes('freelessonpage') == false&&window.location.pathname.toLowerCase().includes('freelessonbdlink') == false){
      axios
        .post(base_url + "user/progress_tracking", body)
        .then((res) => {
          if(typeof(res.data.points_break_down) == 'object'){
            setPointsData(res.data.points_break_down)
          }
          setActivityInitiatedTime(moment())
          props.parentCallback('false');
        })
        .catch((err) => {
          console.log(err);
          props.parentCallback('');
          if(err.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        });
      }else{
        setPointsData({"free_lesson":"1"})
        props.parentCallback('false');
      }
    }
  }

 
  return (<>
    {/* <h2>{props.data && props.data.title}</h2> */}
    <div data-testid="ActivityElementOrdering_content">
    <div ref={activity_element_Ordering} className="col-12 p-0 custm_shadow_cls bg-body">
      <div className="row data-component" >
        <div className="d-flex flex-row justify-content-between align-items-end" style={{}}>
          <strong className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
            {purpose == learn_by_doing ? <p className="mb-0">
              <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

              PRIMING ACTIVITY</p> : (purpose == check_point ? <p>CHECK POINT</p> : (purpose == quiz_name? <p>QUESTION</p> : (purpose == did_i_get_this ? <p>DID I GET THIS?</p> : '')))}
          </strong>
        </div>
        <h2>{title}</h2>
        <div className="">
          <div className="row">
        
            <div className="col-lg-12">
              {/* <h2>{props.data && props.data.content.authoring.previewText}</h2> */}
              <div dangerouslySetInnerHTML={{ __html: props.data.final_text }}></div>
              <div className="summary_header_cls"><i>Arrange the options in the correct order</i></div>
              <div>
                <List
                  values={list}
                  onChange={({ oldIndex, newIndex }) =>
                    setList(arrayMove(list, oldIndex, newIndex))
                  }
                  renderList={({ children, props }) => <ul  {...props}>{children}</ul>}
                  renderItem={({ value, props }) => <li className="list-items" {...props}><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>&nbsp;&nbsp;{value}</span></li>}
                />
                <button className="btn my_btn_cls submit_button check_answ_button"  disabled={peer_flag} onClick={onSubmitAnswer}>
                  CHECK ANSWER
                </button>
              </div>
            </div>
            {/* removed peer response image */}
            {/* <div className={`${peer_flag ? "col-4" : ""}`}>
              {!flag && <img className="mx-auto d-block imgs" src="/images/peer-choice.svg" ></img>}
            </div> */}
          </div>
        </div>
      </div>
    </div>
   {(msg!=='' && Object.keys(points_data).length != 0 )&& <MessageComponent msg={msg} points={points_data} feedbackContent={suceess_msg} />} 
   </div>
  </>)
}