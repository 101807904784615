
import { useRef,useLayoutEffect,screen,useEffect,useState} from "react";
import Confetti from 'react-confetti';
import html2canvas from 'html2canvas';
// import { FacebookShareButton, TwitterShareButton, LinkedinShareButton, FacebookIcon, TwitterIcon, LinkedinIcon  } from 'react-share';
import axios from "../../axios-interceptor";
import { base_url } from "../../utilities";
import { toast } from "react-toastify";
function Congratulationsscreeen(props) {
const congratulations_stepper=useRef(null);
const imageRef = useRef(null);
const [shareButtons, setShareButtons] = useState(null);
const { module_name,course_name,certificate_url } = props;
const [showShare,setShowShare]=useState(false)
const [confettiDimensions, setConfettiDimensions] = useState({
  width: 0,
  height: 0,
});
useEffect(() => {
if(certificate_url)
{
  setShowShare(false)
  addSharebuttons(certificate_url)
}
else{
  setShowShare(true)  
}
  const handleResize = () => {
    const { width, height } = congratulations_stepper.current.getBoundingClientRect();
    setConfettiDimensions({ width, height });
  };
  window.addEventListener("resize", handleResize);
  handleResize();
  return () => {
    window.removeEventListener("resize", handleResize);
  };
}, []);
  useLayoutEffect(() => {
    setTimeout(() => {
        window.scrollTo({
        top:  window.scrollY+window.innerHeight,
        left: 0,
          behavior: "smooth",
        });
      }, 350)
  }, []);
  const shareHandler=()=>{
    html2canvas(imageRef.current).then((canvas) => {
      canvas.toBlob((blob) => {
        const fd = new FormData();
       let user_id= localStorage.getItem("user_id")
       let course_id= localStorage.getItem("course_id")
       let module_id= localStorage.getItem('moduleId')
       let cohort_schedule_id= localStorage.getItem("cohort_id")
        if(user_id&&course_id&&module_id&&cohort_schedule_id)
        {
          fd.append("user_id",user_id)
          fd.append("course_id",course_id)
          fd.append("module_id",module_id)
          fd.append("cohort_schedule_id",cohort_schedule_id)
          fd.append("file",blob,`${module_name}-${course_name}.svg`)  
          axios.post(base_url+'badges/generate_badge_url',fd)
          .then(res=>{
            setShowShare(false)
          const shareUrl =res.data.badge_url
          addSharebuttons(shareUrl)
          })
          .catch(err=>{
            console.log(err);
          })
        }
        else{
          toast.warning("Something went wrong")
        }
  }, 'image/png');
});
  }

  const addSharebuttons=(shareUrl)=>{
    const buttons = (
      <div>
        {/* <FacebookShareButton  url={shareUrl} target="_blank" title="Facebook">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        &nbsp;
        <TwitterShareButton url={shareUrl} target="_blank" title="Twitter">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        &nbsp;
        <LinkedinShareButton url={shareUrl} target="_blank" title="Linkedin">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton> */}
      </div>
    );
    setShareButtons(buttons); // Store the share buttons in sta
  }

    return ( 
      <>
        <div ref={congratulations_stepper} className="col-12  mt-2 p-5 mt-4  container myContainerCls congratulations_container custm_shadow_cls align-items-center"  style={{background: '#ffffff'}}>
        <Confetti
        width={confettiDimensions.width}
        height={confettiDimensions.height}
        numberOfPieces={100}
        // confettiSource={{ x: confettiDimensions.width/2, y: confettiDimensions.height/2}}
        />
        <div className="congratulations_badge"  ref={imageRef}>
          <div className="congratulations_badge_text">
              <p className="text-center mb-2 white_color">{module_name.length >= 43 ? module_name.slice(0, 43)+ '...' : module_name }</p>
              {/* <p className="text-center white_color">{course_name.length >= 29 ? course_name.slice(0, 29) + '...' : course_name }</p> */}
          </div>
          </div>
          <h3 className="mt-2 fw-bold">Congratulations!</h3>
          <p className="mt-2 black_color">You have successfully earned a module badge</p>
          {/* {showShare&&<><button className="btn my_btn_cls mt-0" onClick={shareHandler}>Share</button></>}

          <div className="mt-2">
          {shareButtons}
          </div> */}
        </div>
      </>
    );
}

export default Congratulationsscreeen;