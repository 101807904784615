import axios from "../../axios-interceptor";
import { useState, useEffect } from "react";
import {random_number, base_url,api_error_message } from "../../utilities";
import { BarChart } from "./barchart";
import { MessageComponent } from "./messageComponent";
import { useRef, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export const FeedbackMultipleChoice = (props) => {
  const history = useHistory();
  let [value_text, setValueText] = useState("");
  let [option_flag,setOptionFlag] =useState(false)
  let [flag,setFlag] = useState(false)
  let [ele_data ,setEleData] =useState()
  const feedback_multiple_choice=useRef(null)
  useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
        top:  feedback_multiple_choice.current.offsetTop-40,
        left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
  useEffect(()=>{ 
    
    
     if(props.data.is_answered){
      const list = { ...props.data };
   

    list.options.map(item=>{
      item['is_checked'] = false
     })
    
      setOptionFlag(true)
      props.parentCallback('false');
      setFlag(true)
     
      list.options.map(item=>{
        if(item.text.toLowerCase() === props.data.user_answer.toLowerCase()){
          item['is_checked'] = true
        }
      }) 

      setEleData(list)
     }else{
      setEleData(props.data)
     }
  },[])

  const onChangeRadio = (e) => {
    setValueText(e.target.value);
  };
  const onSubmit = () => {
    if(!flag){
      if(value_text){
        setOptionFlag(true) 
        setFlag(true)
        setTimeout(() => {
          window.scrollTo({
            top: window.scrollY +window.innerHeight,
            left: 0,
            behavior: "smooth",
          });
        }, 300);
        let body = {
          user_id: localStorage.getItem("user_id"),
          course_id: localStorage.getItem("course_id"),
          lesson_id:
            localStorage.getItem("lessonId") == 1
              ? localStorage.getItem("cohort_id")
              : localStorage.getItem("lessonId"),
          cohort_schedule_id: localStorage.getItem("cohort_id"),
          type: localStorage.getItem("lessonId") == 1 ? 1 : 3,
          feedback: value_text,
          question_id:props.data.question_id
        };
          axios
        .post(base_url + "feedback/save_feedback", body)
        .then((res) => {
          props.parentCallback("false");
        }).catch(err=>{
          console.log(err);
          props.parentCallback("");
          if(err.response?.statusText=='Unauthorized'){
            localStorage.clear();
            history.push('/')
            window.location.reload();
          }else{
            toast.warn(api_error_message,{toastId:random_number});
          }
        })
    }
    }
    
    
  };
  return (
    <>
      <div data-testid="FeedbackMultipleChoice_content" ref={feedback_multiple_choice} className="data-component col-12 custm_shadow_cls bg-body" >
        {props.data.type == 3 && (
          <>
            <h2 className="question-title">Feedback</h2>
            <span className="summary_header_cls">{props.data.question}</span>
          </>
        )}
        {props.data.type == 1 && (
          <>
            <h2 className="question-title">{props.data.question}</h2>
            {props.data.sub_question && (
              <span className="summary_header_cls">
                {props.data.sub_question}
              </span>
            )}
          </>
        )}

        <div className="row">
          <div className=" col-12">
            {ele_data?.options.map((item, index) => {
              return (
                <>
                  <label className="">
                    <div className="option_choise" key={index}>
                      <div className="option_label">
                        <input
                          className=""
                          type="radio"
                          value={item.text}
                          name={`${ele_data?.question_id}_name`}
                          checked={item.is_checked}
                          onChange={(e) => {
                            onChangeRadio(e);
                          }}
                          disabled={option_flag}
                          id={item.text}
                          style={{
                            height: "40px",
                            width: "30px",
                            accentColor: "black",
                          }}
                        ></input>{" "}
                        &nbsp;
                      </div>
                      <div className="option_content ">
                        <span className="">{item.text}</span>
                      </div>
                    </div>
                  </label>
                  <br />
                </>
              );
            })}
            <button
              className="btn my_btn_cls submit_button mt-3" disabled={flag}
              onClick={onSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
