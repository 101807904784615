import React from "react";
import { Controller,useForm } from "react-hook-form";
import List from "../listofStates";
import Countries from "../ListofCountries";

import { Link, useLocation,useParams } from "react-router-dom";
import { button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useState, useEffect ,useRef } from "react";
import {formatSentence, base_url,setUrl,date_formats, random_number,storefront_url,default_country_code,default_dial_code,Program_type,api_error_message,standalone_program_type,user_maintenance_type, wrong_link_error_message} from "../utilities";
import axios from "../axios-interceptor";
import 'react-toastify/dist/ReactToastify.css';
import {toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import {YearCompany,Industries,InfluencerList,LearnProgramList,CurrentYears} from '../ObjectiveData'
import { onlineAppplicationFormValidations } from "../formValidationsRules";
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
import { useCallback } from "react";
///const Salutions = [];
//const allGenders=["Mr.", "Miss.", "Mrs.", "Other"]
const colCls = "col-lg-6 col-md-6 col-sm-12 my_custom_col";
// const listofCountries = function (countries) {
//   return <option key={countries.country} value={countries.country}>{countries.country}</option>;
// };

// const listofitems = function (states) {
//   return <option key={states}>{states}</option>;
// };
const OnlineApplication = (props) => {
  const { learningtrack_id,program_id } = useParams()
  
  const courseId= localStorage.getItem('courseIdNew')
  // let cohortId
  // if(localStorage.getItem('cohortIdNew')){
  //    cohortId=localStorage.getItem('cohortIdNew')
  // }
 
  if(learningtrack_id){
    localStorage.setItem('learningTrackId',learningtrack_id)
  }
  if(program_id){
    localStorage.setItem('programId',program_id)
  }
  let learningTrackId
  let programId
  if(localStorage.getItem('learningTrackId')){
    learningTrackId=localStorage.getItem('learningTrackId')
  }
  if(localStorage.getItem('programId')){
    programId=localStorage.getItem('programId')
  }else{
    // window.location.href = storefront_url;
  }
  
  
  // const {courseId,cohortId}=props.match.params
  // { user, signOut }
  const { register, control,handleSubmit,setValue,getValues, formState: { errors } } = useForm({"mode":"all"});
  // console.log(Auth.user?.attributes.sub)  

// if(!localStorage.getItem('login')){
//   setUrl();
//   loginWithRedirect()
// }
  let program_value = ''
  let [stateOption, setStateOption] = useState([])
  let [Salutions, setSalutions] = useState([])
  let [cohortScheduleList, setcohortScheduleList] = useState([])
  let [programsList,SetProgramsList]=useState([])
  let [programsListObj,SetProgramsListObj]=useState({})
  let [selectedProgram, setselectedProgram] = useState('')
  let [programeOption, setProgrameOption] = useState(null)
  let [pronounOption, setPronounOption] = useState('')
  let [loading_flag,setLoading_flag]=useState(true)
  let [showExpform,setShowExpform]=useState(false)
  let [show_next,setShow_next]=useState('')
  let [firstName,setFirstname]=useState('')
  let [email,setEmail]=useState('')
  let [totalYearsErr,setTotalYearsErr]=useState(false);
  const [phone, setPhone] = useState("");
  const [invalidaPage, setInvalidaPage] = useState(false)
  const [countryCode, setCountryCode] = useState(default_country_code);
 const [dialCode, setDialCode] = useState(default_dial_code);
  const [mobileErr, setMobileErr] = useState(false);
  const today = new Date().toISOString().slice(0, 10)
  let [wordCount, setWordCount] = useState(200);
  let [descriptionValue, setDescriptionValue] = useState("");
  let [lead_id,setLeadId] = useState()
  const { leadform_id } = useParams();
  let [userData, setUserData] = useState({
    "id": localStorage.getItem('user_id'),
    "first_name": "",
    "email": "",
    "mobile": "",
    "dob": "",
    "gender": "",
    "pronoun": "",
    "state": "Telangana",
    "country": "India",
    "postcode": "",
    "city": "Hyderabad",
    "role": "",
    "company": "",
    "industry": "",
    "years_company": "",
    "total_years": "",
    "high_education": "",
    "year_graduation": "",
    "institute_high": "",
    "prev_education": "",
    "institute_prev": "",
    "cohort":"",
    "lead_id":''
  });
  
  // if(userData.dob.slice(0, 4) > today.slice(0,4)){
  //   toast.warning('Please check date of birth',{toastId:random_number})
  //       }  
  const handleButtonClick = () => {
    window.location.href = storefront_url
  }

  const handleApiError = (err) => {
    console.log(err);
    if (err.response?.statusText === 'Unauthorized' || err.response?.data?.statusText === 'Unauthorized') {
      localStorage.clear();
      history.push('/');
      window.location.reload();
    } else {
      toast.warn(api_error_message, { toastId: random_number });
    }
  };
  
            
  useEffect(() => {
    setLoading_flag(false)
    if(leadform_id){
      axios.post(base_url+'get_lead_data',{
      "lead_id" : leadform_id
    })
    .then(res=>{
      let result=res.data.data;
      localStorage.setItem("learningTrackId",result.learning_track_id);
      localStorage.setItem("programId",result.program_id);
      learningTrackId =result.learning_track_id;
      programId = result.program_id
      if(!result.program_id ){
        const CustomToast = ({ closeToast }) => (
          <div>
              <p className="mb-0">{wrong_link_error_message}</p>
              <button className="mt-0 btn btn-primary btn-sm float-end " onClick={() => handleButtonClick(closeToast)}>Start Over</button>
          </div>
      );
        setInvalidaPage(true)
        toast.warn(<CustomToast />,{toastId:random_number,
          onClose: () => window.location.href = storefront_url
        });
      }else{
        const now = new Date();
        const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
        document.cookie = `leadform_name=${result.first_name.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_email=${result.email?.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_mobile=${ result.mobile}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_country_code=${result.country_code ? result.country_code : default_dial_code}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_ProgramId=${result.program_id}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_role=${result.role}; expires=${expirationDate.toUTCString()}; path=/;`;
        document.cookie = `leadform_id=${result._id}; expires=${expirationDate.toUTCString()}; path=/;`;
        get_program_by_learningtrack_program()
      }
    }) .catch(handleApiError); 
  }else{
    if(learningTrackId&&programId)
    {
      get_program_by_learningtrack_program()
    }
    }
  },[])

  const get_program_by_learningtrack_program = () => {
    const cookies = document.cookie.split(';')
    .map(cookie => cookie.trim())
    .reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
    axios.post(base_url+'get_program/learningtrack_id/program_id',{
      "learning_track_id" :learningTrackId,
      "program_id":programId
    })
    .then(res=>{
      if(res?.data?.data?.length == 0){
        toast.error("Something went wrong!!")
        history.push('/')
      }else{
      res.data.data.map((item,index)=>{
        item['stringDate']= date_formats.human_date_format(item.start_date)
        if (programId) {
          if (programId === item._id) //if cohort is exists
          {
            SetProgramsListObj(item)
            
            if (item.end_date < new Date().getTime()) {
              const CustomToast = ({ closeToast }) => (
                <div>
                  <p className="mb-0">{"This programm is expired. Please start over to continue."}</p>
                  <button className="mt-0 btn btn-primary btn-sm float-end " onClick={() => handleButtonClick(closeToast)}>Start Over</button>
                </div>
              );
              setInvalidaPage(true)
              toast.warn(<CustomToast />, {
                toastId: random_number,
                onClose: () => window.location.href = storefront_url
              });

             
            }
          } else{
            setLoading_flag(false)
            const CustomToast = ({ closeToast }) => (
              <div>
                <p className="mb-0">{wrong_link_error_message}</p>
                <button className="mt-0 btn btn-primary btn-sm float-end " onClick={() => handleButtonClick(closeToast)}>Start Over</button>
              </div>
            );
            setInvalidaPage(true)
            toast.warn(<CustomToast />, {
              toastId: random_number,
              onClose: () => window.location.href = storefront_url
            });
          }
        } 
      })
      SetProgramsList(res?.data?.data);
      setLoading_flag(false)
    }
    }).catch(err=>{
      if(err.response.status == 400){
        toast.error("Something went wrong!!")
        history.push('/')
       }else{
        if (err.response?.statusText === 'Unauthorized' || err.response?.data?.statusText === 'Unauthorized') {
          localStorage.clear();
          history.push('/');
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
       }
    }

    );

   let prefilName= cookies.leadform_name ? formatSentence(cookies.leadform_name.trim().replace(/\s+/g, ' ')) : '';
   let prefilEmail= cookies.leadform_email
   let prefilMobile=  cookies.leadform_mobile;
   setLeadId(cookies.leadform_id)
   let cookies_country_code = cookies.leadform_country_code ? (cookies.leadform_country_code.includes("{") ? JSON.parse(cookies.leadform_country_code)['dialCode'] :cookies.leadform_country_code ):dialCode

   setPhone(cookies.leadform_mobile ? (cookies_country_code+cookies.leadform_mobile):cookies_country_code+phone,{ shouldDirty: true });
   setDialCode(cookies_country_code);

    if(prefilName)
    {
      setValue('first_name',prefilName, { shouldDirty: true })
      setFirstname(prefilName);
    }
    if(prefilEmail)
    {
      setValue('email', prefilEmail, { shouldDirty: true })
      setEmail(prefilEmail);
    }
    if(prefilMobile)
    {
      setValue('mobile', prefilMobile, { shouldDirty: true });
    }    
    if(localStorage.getItem('user_id')){
      axios
      .post(base_url + "user/my_courses", {
        // user_id: Auth.user?.attributes.sub,
        user_id: localStorage.getItem('user_id')
      })
      .then((res) => {
        let result = res.data.data;

        setValue('first_name',result.first_name, { shouldDirty: true })
        setFirstname(result.firstName);
        setValue('email',result.email, { shouldDirty: true })
        setEmail(result.email);
        setValue('role',result.role, { shouldDirty: true })
        setValue('total_years',result.total_years, { shouldDirty: true })
        setValue('years_company',result.years_company, { shouldDirty: true })
        setValue('industry',result.industry, { shouldDirty: true })
        setValue('company',result.company, { shouldDirty: true })

        let country_code = result.country_code  ? (result.country_code.startsWith("+") ? result.country_code.replace('+', '').trim():result.country_code) : dialCode;

        setPhone(result.mobile ? (country_code+result.mobile):cookies_country_code+cookies.leadform_mobile,{ shouldDirty: true });

        setDialCode(result.country_code  ? country_code : cookies_country_code)

        showNextElement();
      }).catch(handleApiError);
    }


    Countries.map(item => {
      if (item.country == 'India') {
        setStateOption(item.states)
      }
    })
  }

  useEffect(() => {
    if(email=="" && firstName=="") 
    {
      setShow_next('')
    }
    else{
     setShow_next('show')
    }
  }, [email,firstName])
  const totalYearsRef = useRef(null);

  let [formErrors, setFormErrors] = useState({});
  let history = useHistory();
 let location =useLocation();
  // let cohort_details = location.state.cohort
  const onSubmit = (data) => { 
    let mobile;
   
    if(phone == dialCode){
      setMobileErr(true);
      window.scrollTo({'top':50,behavior:'smooth'})
      return false;
    }
    if(phone){
      mobile = phone.substring(dialCode.length);
      if(mobile.toString().length < 4 || mobile.toString().length > 13){
        setMobileErr(true);
        window.scrollTo({'top':50,behavior:'smooth'})
        return false;
      }else if(dialCode ==="91" && mobile.toString().length <10){
        setMobileErr(true);
        window.scrollTo({top: 550, behavior: "smooth",})       
        return false;
      }else if((dialCode ==="61" || dialCode ==="65") && ((mobile.toString().length <8) || (mobile.toString().length >9) )){
        setMobileErr(true);
        window.scrollTo({top: 550, behavior: "smooth",})       
        return false;
      }else if((dialCode ==="971") && ((mobile.toString().length <8) || (mobile.toString().length >9) )){
        setMobileErr(true);
        window.scrollTo({top: 550, behavior: "smooth",})       
        return false;
      }else{
        setMobileErr(false);
      }
    }
    // else{
    //   setMobileErr(true);
    //   window.scrollTo({'top':50,behavior:'smooth'})
    //   return false;
    // }
    let { city, company, country, dob, email, first_name, high_education, industry, institute_high, institute_prev , postcode, prev_education, pronoun, role, state, gender, total_years, year_graduation, years_company,Influencer,LearnProgram,ParticipantName,programmeYear,UserConsent1,UserConsent2,description } = data
    const now = new Date();
    const expirationDate = new Date(now.getTime() + 365 * 24 * 60 * 60 * 1000); // one year from now
    document.cookie = `leadform_email=${email.trim()}; expires=${expirationDate.toUTCString()}; path=/;`;
    
    let SaveObj = {
      "first_name": formatSentence(first_name.trim().replace(/\s+/g, ' ')),
      "email": email,
      "mobile": mobile?mobile:null,
      "role": role.trim().replace(/\s+/g, ' '),
      "company": company.trim().replace(/\s+/g, ' '),
      "years_company": years_company,//
      "industry": industry,
      "total_years": total_years,//
      "program_id":localStorage.getItem('programId'),
      "learning_track_id":localStorage.getItem('learningTrackId'),
      "learning_track_title": localStorage.getItem('learningtrack')?localStorage.getItem('learningtrack'):"",
      "influencer":Influencer,
      "learn_program":LearnProgram,
      "participant_name":ParticipantName.trim().replace(/\s+/g, ' '),
      "program_year":programmeYear,
      "user_consent1":UserConsent1,
      "description":descriptionValue.trim().replace(/\s+/g, ' '),
      "user_consent2":UserConsent2,
      "country_code":dialCode,
      "lead_id":lead_id
    }

      if(parseInt(years_company)>parseInt(total_years))
      {
        setTotalYearsErr(true)
        totalYearsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        return false;
      }
    else{
      axios
      .post(base_url + "user/save_program_application", SaveObj)
      .then((res) => {
        if (res.status == 200) {
          if(res.data.status==403)
          {
            // localStorage.setItem('user_id',Auth.user?.attributes.sub)
            toast.success(({ closeToast }) =><div>You have already applied to this programme. Please <a href="/" style={{fontSize:"18px"}}>login</a> to view this programme.</div>,{toastId:random_number,autoClose:10000});
            // history.push('/dashboard')
            // window.location.reload()
          }
          else{
            localStorage.removeItem("prev_url")
            localStorage.removeItem('programId')
            localStorage.setItem('application_email',SaveObj.email)
            history.push("/applicationrecvd");
            // localStorage.setItem('user_id',Auth.user?.attributes.sub)
            // localStorage.setItem('user_id',user.sub)
          }
        }
      }).catch(handleApiError);
    }
  }
  useEffect(() => {
    if(!localStorage.programId && !leadform_id){
      const CustomToast = ({ closeToast }) => (
        <div>
            <p className="mb-0">{wrong_link_error_message}</p>
            <button className="mt-0 btn btn-primary btn-sm float-end " onClick={() => handleButtonClick(closeToast)}>Start Over</button>
        </div>
    );
      setInvalidaPage(true)
      toast.warn(<CustomToast />,{toastId:random_number,
        onClose: () => window.location.href = storefront_url
      });
    }
  }, [localStorage.programId])
  

  const handleChange1 = (e) =>{
    if(e.target.name=="UserConsent1" || e.target.name=="UserConsent2"){
      setValue(e.target.name,e.target.checked , { shouldValidate: true })
    }
  }


  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value1 = e.target.value
    let value = value1.trim();

    // if(name==='postcode'||name=='mobile')
    // {
    //   setValue(e.target.name,e.target.value.split(" ").join("") , { shouldValidate: true })
    // }else{
    //   setValue(e.target.name,e.target.value , { shouldValidate: true })
    // }
    
    // if(name==='years_company'||name==='total_years'){
    //   YearCompany.map(item=>{
    //     if(item.id==value){
    //       setValue(e.target.name,item.name, { shouldValidate: true })
    //     }
    //   })
    // }
    if(name==='years_company'||name==='total_years')
    {
      setValue(name, value);
      const {years_company,total_years}=getValues()
      if(total_years!==""){
        if(parseInt(years_company)>parseInt(total_years))
        {
        errors[name] = {
          type: "required",
          message: "",
        };
          setTotalYearsErr(true)
        }
        else{
          setTotalYearsErr(false)
        }
      }
      else{
        setTotalYearsErr(false);
      } 
    }

    // if(name == 'Influencer'){
    //   InfluencerList.map(item=>{
    //     if(item.id == value){
    //       setValue(e.target.name,item.name, { shouldValidate: true })
    //     }
    //   })
    // }
    // if(name == 'LearnProgram'){
    //   LearnProgramList.map(item=>{
    //     if(item.id == value){
    //       setValue(e.target.name,item.name, { shouldValidate: true })
    //     }
    //   })
    // }
    // if (name == 'country') {
    //   Countries.map((cntry) => {
    //     if (cntry.country == value) {
    //       setStateOption(cntry.states)
    //     }
    //   })
    // }
    // if (name == 'gender') {  
    //   if (value== "Male" ) {
    //       setSalutions(["Mr."])
    //       setPronounOption("Mr.")
    //        setValue('pronoun',"Mr." , { shouldValidate: true })
    //     }
    //     else if(value== "Female")
    //     {
    //       setSalutions(["Miss.", "Mrs."])
    //       setPronounOption("")
    //       setValue('pronoun', "", { shouldDirty: true })
    //     }
    //     else if(value== "Other")
    //     {
    //       setSalutions(["Other"])
    //       setPronounOption("Other")
    //       setValue('pronoun', "Other", { shouldValidate: true })
    //     }
    // }
  //  if(name == 'industry'){
  //   Industries.map(item=>{
  //     if(item.id == value){
  //       setValue(name, item.name, { shouldDirty: true })
  //     }
  //   })
  //  }
    // if (name == 'pronoun') {
    //   if (value!== "" ) {
    //     setPronounOption(value)
    //   setValue('pronoun', value, { shouldDirty: true })
    //   }
    // }
    // if (name == 'program') {
    
    //   if (value!== "" ) {
    //     programsList.map(obj=>{
    //       if(value==obj._id && localStorage.getItem('user_id'))
    //       {
    //         setProgrameOption(obj)
    //         program_value = value
    //         setselectedProgram(value)
    //         checkValuesAvailable()
    //       }
    //     })
    //     setValue('pronoun', value, { shouldDirty: true })
    //   }
    // }
      if(name=='company'||name=='role')
      {
        const pattern = /^[A-Za-z]+$/;
        if (value === "" || pattern.test(value)) {
          setUserData({ ...userData, [name]: value });
        }
      }
      // if(name=='dob')
      // {
      //   setUserData({ ...userData, [name]: value });
      // }
      if(name=='first_name')
      {
        setFirstname(value);
      }
      if(name=='email')
      {
        setEmail(value);
       
        
      }
  };

  
  const handleOnChange = (value, country) => {
    if(value == ""){
      setShow_next('');
      setMobileErr(true);
      return false
    }else{
      setShow_next('show');
      setMobileErr(false);
    }
    setPhone(value.toString());
    setDialCode(country.dialCode)
  }

  const showNextElement=e=>{
     setShowExpform(true)
     setTimeout(() => {
      window.scrollTo({
        top: window.scrollY+window.innerHeight-150,
        left: 0,
        behavior: "smooth",
      });
    }, 300);
  }
  

  const checkValuesAvailable=()=>{
    if(email!==""&&firstName!=="")
     {
       setShow_next('show');
     }
     else{
      setShow_next('')
     }
  }

  const handleWordCounter = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/\s+/).map((element) => element.trim()).filter((element) => element !== "");
    
    if (words.length < 201) {
      const wordCount =200- words.length;
      setWordCount(wordCount);
      setDescriptionValue(inputValue);
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
    { loading_flag || (!invalidaPage && !programsListObj['learning_track_name'])? (
    <div>
    <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
  </div>
  ):<><div className={`${!invalidaPage ? "" : "d-none"}`}>
  
       {/* <LandNavbar/> */}
       <div className="container container-onlineApp mt-5">
        <div className="online_applictn_form_p1">
          <div className="online_application_heading mb-2">ISB Online Application Form for {programsListObj['learning_track_name']}
          <>
          {programsListObj['program_type']  != standalone_program_type ? (
                <span>  (Starting {date_formats.human_date_format(programsListObj['start_date'])}) </span>  
             ) :(
                ""
               )}
                                              
              </> 
             <span></span></div>
          <span className="online_application_main_des d-inline-block mb-4">
          Welcome to the ISB Online form. Please fill out this application form to apply to ISB Online. Once you submit your application form, the ISB Online Admissions team will review and confirm your applicability, and you will receive a confirmation via email. After this you can complete the payment and join the course. 
          </span>
          <span className="online_application_main_des">
          Please fill out the information accurately to process your certificate and certificate delivery. Please note all submitted information is secured and will only be used for admissions processes, and is compliant with ISB Privacy Policies. Click here to read the <span><a className="privacy_policy" target="_blank" href="https://www.isb.edu/en/legal/privacy-cookies-policy.html">Privacy Policy</a>.</span>
          </span>
        </div>
        </div>
        <div className="container container-onlineApp mt-5">
        <div className="containe form_field_container_cls_online_applictn">
          <form autoComplete='false'>
            <div className="row form_field_cls">
            {/* <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">Select Program <span className="requiredInput">*</span></label>
                <select {...register("program", {
                  required: "program is required"
                })}
                  id="program"
                  name="program"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  value={selectedProgram}
                  onChange={handleChange}>
                  <option value=''>Select</option>
                  {programsList.map((item,index) => {
                     return <option  key={index} value={item._id} >{item.stringDate}</option>;
                  })}
                </select>
                {errors.cohort?.message && <span style={{ color: "red" }}>{errors.cohort?.message}</span>}
              </div>
              <div className={colCls}>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" "
                  style={{ display: "none" }}
                />
              </div> */}
             

              <h3 className="online_app_main_head mb-4">What name would you like to be printed on the certificate? </h3>
              <div className="col-12">
                <label htmlFor="exampleInput" className="InputLabel">Name on Certificate<span className="requiredInput">*</span></label>
                <input {...register("first_name", onlineAppplicationFormValidations.firstName)}
                  type="text"
                  className="form-control boder-radius-0"
                  id="first_name"
                  data-testid="first_name"
                  name="first_name"
                  placeholder=" "
                  autoFocus
                  required
                  // role="presentation" 
                  autoComplete="off"
                  onChange={handleChange}
                  onKeyUp={checkValuesAvailable}
                  
                />
                {errors.first_name?.message && <span style={{ color: "red" }}>{errors.first_name?.message}</span>}
              </div>
            </div>
          </form>
        </div>
        </div>

        <div className="container container-onlineApp mt-5">
        <div className="containe form_field_container_cls_online_applictn">
          <form autoComplete='false'>
            <div className="row form_field_cls">
              <h3 className="online_app_main_head mb-4">How do we get in touch with you? </h3>
              {/* <div className="row  form_field_cls"> */}
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">Email <span className="requiredInput">*</span></label>
                <input {...register("email",onlineAppplicationFormValidations.Email )}
                  type="email"
                  id="email"
                  data-testid="email"
                  name="email"
                  onChange={handleChange}
                  onKeyUp={checkValuesAvailable}
                  className="form-control boder-radius-0"
                  placeholder=""
                  // role="presentation"
                  autoComplete="off"
                />


                {errors.email?.message && (
                  <span style={{ color: "red" }}>{errors.email?.message}</span>
                )}
                {/* {errors.email && <span style={{ color: "red" }}>This field is required</span>} */}

              </div>
              <div className={colCls}>
              <label htmlFor="MobileInput" className="InputLabel">Mobile <span className="requiredInput">*</span></label>
                <PhoneInput
                  id="MobileInput"
                  country={countryCode}
                  value={phone}
                  onChange={handleOnChange}
                  separateDialCode={true} // Display flag and code in one block
                />
                <span className={`flag-icon flag-icon-${countryCode}`}></span>
                {mobileErr && <span data-testid="mobile_error" style={{ color: "red" }}>Please enter a valid mobile</span>}
                {/* <input {...register("mobile", {
                minLength: {
                    value: 10,
                    message: 'Mobile number must contain between 10 and 13 characters',
                  },
                  maxLength: {
                    value: 13,
                    message: 'Mobile number must contain between 10 and 13 characters',
                  },
                  pattern: {
                    value: /^[+-]?[0-9]+$/,
                    message: 'Please enter valid mobile number'
                  }
                })}
                  type="text"
                  id="mobile"
                  name="mobile"
                  onChange={handleChange}
                  className="form-control boder-radius-0"
                  role="presentation" autoComplete="off"
                />
                {errors.mobile?.message && <span style={{ color: "red" }}>{errors.mobile?.message}</span>} */}

              </div>


            </div>
          </form>
        </div>
        </div>
        {/* =========================SECTION TWO=========================== */}

        {showExpform==false&& (
          <div className="navigation d-flex justify-content-center pb-45">
            { (
              <>
                <button
                  className="btn btn-lg my_btn_cls shadow border border-light border-4 rounded-circle mt-4"
                  onClick={() => showNextElement()}
                  data-testid="Down-Arrowbutton"
                  disabled={show_next == "" ? true : false}
                >
                  <img
                    className="bg-transparent"
                    src="/images/downarrow.png"
                    alt=""
                  />
                </button>
              </>
            )}
          </div>
        )}

       {showExpform&& <><div className="container container-onlineApp mt-5" ref={totalYearsRef}>
        <div className="containe form_field_container_cls_online_applictn">
          <form className="section_two">
          <h3 className="online_app_main_head">Professional Experience<span className="requiredInput">*</span> </h3>
            <p className="online_app_main_head_comnt">
            Help us understand your background for the application review.
            </p>
      
            <div className="row form_field_cls mt-4">
           
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">Which company do you work for now? <span className="requiredInput">*</span></label>
                <input {...register("company", onlineAppplicationFormValidations.company
                )}
                  id="company"
                  name="company"
                  className="form-control boder-radius-0"
                  placeholder=" "
                  data-testid="company"
                  role="presentation" 
                  autoComplete="off"
                  // value={userData.company}
                  onChange={handleChange}
                  required
                ></input>
                {errors.company?.message && <span role="alert" style={{ color: "red" }}>{errors.company?.message}</span>}

              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">What’s your position in the company? <span className="requiredInput">*</span></label>
                <input {...register("role", onlineAppplicationFormValidations.role)}

                
                  id="role"
                  name="role"
                  data-testid="role"
                  className="form-control boder-radius-0"
                  placeholder=" "
                  role="presentation" autoComplete="off"
                  // value={userData.role}
                  onChange={handleChange}
                  required
                />
                {errors.role?.message && <span role="alert" style={{ color: "red" }}>{errors.role?.message}</span>}

              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">How many years have you worked there?<span className="requiredInput">*</span></label>
                {/* <input {...register("years_company", {
                  required: "How many years have you worked in your current company is required",
                  maxLength: {
                    value: 2,
                    message: "Sorry you are exceeding the limit"
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input should be in numbers"
                  }
                })}
                  id="years_company"
                  name="years_company"
                  type="nubmer"
                  className="form-control boder-radius-0"
                  placeholder=" "
                  role="presentation" autoComplete="off"
                  onChange={handleChange}
                  required
                ></input> */}
                   <select {...register("years_company", onlineAppplicationFormValidations.years_company)}
                  id="years_company"
                  name="years_company"
                  data-testid="years_company"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  required
                  onChange={handleChange}>
                  <option value=''>Select years of experience</option>
                  {YearCompany.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.text}</option>;
                  })}
                </select>
                {errors.years_company?.message && <span role="alert" style={{ color: "red" }}>{errors.years_company?.message}</span>}

              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">What industry is your company in? <span className="requiredInput">*</span></label>
                {/* <input {...register("industry", {
                  required: "What industry is your company in is required",
                  maxLength: {
                    value: 30,
                    message: "Sorry you are exceeding the limit"
                  }
                })}
                  id="industry"
                  name="industry"
                  className="form-control boder-radius-0"
                  placeholder=" "
                  role="presentation" autoComplete="off"
                  // value={userData.industry}
                  onChange={handleChange}
                  required
                ></input> */}
                  <select {...register("industry", onlineAppplicationFormValidations.industry)}
                  id="industry"
                  name="industry"
                  data-testid="industry"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  required
                  onChange={handleChange}>
                  <option value=''>Select Industry</option>
                  {Industries.map((item,index) => {
                     return <option  key={index} value={item} >{item}</option>;
                  })}
                </select>
                {errors.industry?.message && <span role="alert" style={{ color: "red" }}>{errors.industry?.message}</span>}

              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">
                How many years have you been working overall? <span className="requiredInput">*</span>
                </label>
                {/* <input {...register("total_years", {
                  required: "How many years have you been working overall Is required",
                  maxLength: {
                    value: 2,
                    message: "Sorry you are exceeding the limit"
                  },
                  pattern: {
                    value: /^[0-9]*$/,
                    message: "Input should be in numbers"
                  }
                })}
                  id="total_years"
                  type="nubmer"
                  name="total_years"
                  className="form-control boder-radius-0"
                  placeholder=" "
                  role="presentation" autoComplete="off"
                  onChange={handleChange}
                  required
                ></input> */}
                 <select {...register("total_years", onlineAppplicationFormValidations.total_years)}
                  id="total_years"
                  name="total_years"
                  data-testid="total_years"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  required
                  onChange={handleChange}>
                  <option value=''>Select years of experience</option>
                  {YearCompany.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.text}</option>;
                  })}
                </select>
                {errors.total_years?.message && <span role="alert" style={{ color: "red" }}>{errors.total_years?.message}</span>}

              </div>
              <div className={colCls}>
                <input
                  type="text"
                  className="form-control"
                  placeholder=" "
                  style={{ display: "none" }}
                />
              </div>
              <div>
              {totalYearsErr&& <span data-testid="totalYearsErr" style={{ color: "red" }}>The years you have worked overall should be greater than or equal to the years worked in current company</span>}
              </div>
            </div>
          </form>
        </div>
        </div> 
        <div className="container container-onlineApp mt-5">
        <div className="containe form_field_container_cls_online_applictn">
        <form className="section_three">
          <h3 className="online_app_main_head" data-testid="objectives">Objectives<span className="requiredInput">*</span> </h3>
            <p className="online_app_main_head_comnt" data-testid="objectives_mainheading">
            Help us understand what motivated you to apply for this programme.
            </p>
            <div className="row form_field_cls mt-4">
            <div className='colCls onlineapplication_textarea'>
            <label htmlFor="exampleInput" className="InputLabel" data-testid="objectives_labelcontent">Please explain your reasons for applying to this programme, in terms of objectives and the learning you expect to gain. We are strong proponents of diversity and would also like to understand how you bring that to the cohort through your achievements, experience, or thoughts. Please keep your answer to less than 200 words.<span className="requiredInput">*</span></label>
            <textarea id="description" name="description" {...register("description", onlineAppplicationFormValidations.description)}
                onChange={(e) => handleWordCounter(e)} 
                value={descriptionValue}
                data-testid="description"
                rows="5" cols="20"></textarea>
              {errors.description?.message && <span role="alert" style={{ color: "red" }}>{errors.description?.message}</span>}
              <div className="float-right">
                <span data-testid="word_count"> <span className="font-weight-bold" data-testid="current_count">{wordCount}</span> {wordCount==1 ?"word":"words"} remaining out of <span className="font-weight-bold" data-testid="total_count">200</span></span>
              </div>
            </div> 
            <div className="row row_margin">
            <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">What influenced you to apply to this programme?<span className="requiredInput">*</span></label>
                <select {...register("Influencer", onlineAppplicationFormValidations.Influencer)}
                  id="Influencer"
                  name="Influencer"
                  data-testid="Influencer"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  required
                  onChange={handleChange}>
                  <option value=''>Select One</option>
                  {InfluencerList.map((item,index) => {
                     return <option  key={index} value={item} >{item}</option>;
                  })}
                </select>
                {errors.Influencer?.message && <span role="alert" style={{ color: "red" }}>{errors.Influencer?.message}</span>}
              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">How did you learn about this programme?<span className="requiredInput">*</span></label>
                <select {...register("LearnProgram", onlineAppplicationFormValidations.LearnProgram)}
                  id="LearnProgram"
                  name="LearnProgram"
                  className="form-select"
                  data-testid="LearnProgram"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  required
                  onChange={handleChange}>
                  <option value=''>Select one</option>
                  {LearnProgramList.map((item,index) => {
                     return <option  key={index} value={item} >{item}</option>;
                  })}
                </select>
                {errors.LearnProgram?.message && <span role="alert" style={{ color: "red" }}>{errors.LearnProgram?.message}</span>}
              </div>
              </div>
              <p className="online_app_main_head_comnt mt-4" data-testid="participants_content">
              If you were recommended by a previous participant/alumnus of an ISB programme, please specify:
            </p>
            <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">Participant Name</label>
                <input  {...register("ParticipantName", 
                {
                  // required: "Last Name is required",
                  // pattern: {
                  //   value: /^[A-Za-z ]+$/i,
                  //   message: "Last Name should contain only alphabets"
                  // }
                })}
                  type="text"
                  className="form-control boder-radius-0"
                  id="ParticipantName"
                  data-testid="ParticipantName"
                  name="ParticipantName"
                  placeholder=" "
                  // required
                  role="presentation" autoComplete="off"
                  onChange={handleChange}
                  onKeyUp={checkValuesAvailable}
                />
                
              </div>
              <div className={colCls}>
                <label htmlFor="exampleInput" className="InputLabel">Programme / Year</label>
                <select {...register("programmeYear")}
                  id="programmeYear"
                  name="programmeYear"
                  data-testid="programmeYear"
                  className="form-select"
                  aria-label="Default select example"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={handleChange}>
                  <option value=''>Select Option</option>
                  {CurrentYears.map((item,index) => {
                     return <option  key={index} value={item} >{item}</option>;
                  })}
                </select>
              </div>
            </div>
            </form>
        </div>
          </div>
          <div className="container container-onlineApp mt-5">
        <div className="containe form_field_container_cls_online_applictn py-2 px-4">
        <form className="section_four">
        <div className="row form_field_cls mt-4">
            <div className='colCls form-group_checkbox'>
              <input type="checkbox" name="UserConsent1" data-testid="UserConsent1"  id="UserConsent1" {...register("UserConsent1",onlineAppplicationFormValidations.UserConsent1)} onChange={handleChange1}/>
              <label className="row" for="UserConsent1">
                <div className="col  mt-1"> I understand that it is my responsibility to notify Indian School of Business of any change in employment status that may happen during the time this application is submitted to the start of the programme.</div>
              </label>
              {errors.UserConsent1?.message && <span role="alert" style={{ color: "red" }}>{errors.UserConsent1?.message}</span>}
            </div>
            <div className='colCls form-group_checkbox'>
              <input type="checkbox" name="UserConsent2" data-testid="UserConsent2"  id="UserConsent2" {...register("UserConsent2",onlineAppplicationFormValidations.UserConsent2)} onChange={handleChange1}/>
              <label className="row" for="UserConsent2">
                <div className="col  mt-1"> I certify that all information provided in this application is true and accurate to the best of my knowledge. ISB reserves the right to investigate any statements made in this application and revoke my admission if any information provided is found to be false or misleading. ISB also reserves the right to modify program requirements or cancel the program at any time without prior notice.</div>
              </label>
              {errors.UserConsent2?.message && <span role="alert" style={{ color: "red" }}>{errors.UserConsent2?.message}</span>}
            </div>
            </div>
        </form>
        </div>

        </div>
          </>
        }

        {/* =======================================SECTION THREE============================================ */}

        {/* <Link to='/onlineappparttwo'> */}
       {showExpform&& <div className="goto_part_2_btn d-block text-center pb-5">
          <button
            type="button"
            data-testid="Submit-button"
            className="btn my_btn_cls col-sm-3 col-lg-2 col-md-2 online_app_submit"
            value="Go To Part 2"
            onClick={handleSubmit(onSubmit)}
            // disabled={!firstname}
          >
            Submit
          </button>
      </div> }
     
      
  </div>


   </>
 }
 
    </>
  );

// }


}

// export default OnlineApplication;

export default withMaintenanceCheck(OnlineApplication,user_maintenance_type) 
