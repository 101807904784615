import { useRef,useLayoutEffect,useEffect} from "react";
import { check_point, did_i_get_this, learn_by_doing, quiz_name } from "../../utilities";
export const DataComponent = (props) => {
    const data_Component=useRef(null)
useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
         top:  data_Component.current?.offsetTop-40,
         left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
    let data = props.data;
    let purpose = props.purpose;
    useEffect(()=>{
      
      const container = document.createElement('div');
      container.innerHTML = data.str_data; 
      const text = container.querySelectorAll('.summary_header_cls');
      const liElements = document.querySelectorAll('.data_component .summary_list_cls');
   
      const headerTexts = Array.from(text).map(element => element.textContent.trim())
      const liTexts = Array.from(liElements).map(li => li.textContent.trim());
      let combinedText = [...headerTexts,...liTexts].join('. ')
      localStorage.setItem('prev_title',data?.title_data)
      localStorage.setItem('prev_text',combinedText)

      //To remove the gap between the paragraph and a link
      const elementsWithClass = document.getElementsByClassName('data_component_dynamic_content');
      // Loop through each element with the class "shortanswers"
      for (let i = 0; i < elementsWithClass.length; i++) {
        const element = elementsWithClass[i];
        // Find the anchor tag within the current element
        const anchorTag = element.querySelector('a');
        // If an anchor tag is found
        if (anchorTag) {
          // Get the previous sibling of the anchor tag
          let sibling = anchorTag.previousElementSibling;

          // Loop through all previous siblings until we reach the <span> elements
          while (sibling) {
            // Check if the current sibling is a <span> element
            if (sibling.tagName.toLowerCase() === 'span') {
              // Remove the class 'pb-4' and add 'pb-0' to the <span> element
              sibling.classList.remove('pb-4');
              sibling.classList.add('pb-0');
            }
            // Move to the previous sibling
            sibling = sibling.previousElementSibling;
          }
        }
    }
    },[])
    return (
        <>
            <div data-testid="DataComponent_content">
                <div className="col-12 data-component bg-body custm_shadow_cls" ref={data_Component}>
                    <div className="d-flex flex-row justify-content-between align-items-end" style={{}}>
                        <strong className=" mb-0 align-items-end" style={{ letterSpacing: "1px", opacity: "50%", }}>
                            {purpose == learn_by_doing ? <p className="mb-0">
                                <img style={{ opacity: "50%" }} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA7ElEQVRIieWVMQrCMBhGH3Vyap1FcBYcPIpCr1Do0mPoFTyFCOIJqoIO3sLiWMShqFCH/IVQaEkhmfpBaBOS99ImJNCnDIE7cHYl2AClFOuZAx9XAg+4aHDrgkSgDxeCCfAS6NKFYC/Ag9StCkKB5cC4JqiXJxB1gQdAJoNjrT1tkfwA31SwlUEn1C5qywwoUNs4MIGPpHMhg9viySRKmZRRfNTnNv2KVOsbS1tmOvsqEWrhmiSgFj6XetgF3hZdsJP3oy24LljJ8w1MXQiqYyOxCdcFJXADBq4EX2BhG64L1i7goK7LK+r67En+G2pg6MDEsykAAAAASUVORK5CYII=" />

                                PRIMING ACTIVITY</p> : (purpose == check_point ? <p>CHECK POINT</p> : (purpose == quiz_name ? <p>QUESTION</p> : (purpose == did_i_get_this? <p>DID I GET THIS?</p> : '')))}
                        </strong>
                    </div>
                    <h2>{data?.title_data}</h2>
                    <div className='data_component data_component_dynamic_content' id="data_component" dangerouslySetInnerHTML={{ __html: data.str_data }} />
                </div>
            </div>
        </>
    )
}