
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route,Link,useHistory, useLocation } from "react-router-dom";
import { base_url,admin_role,crypto_secret_key,program_management_role, random_number,enrollment_management_role, admin_maintenance_type} from "../../utilities";
import axios from "../../axios-interceptor";
import Nav from 'react-bootstrap/Nav';
import  AdminApprovalModules  from "../AdminApprovalModules";
import CourseLearningTrackList from "./coursesList";
import CohortsAdd from "./cohortsAdd";
import LearningTrackAdd from "./learningTrackAdd";
import AdminApproval from "../AdminApprovals/AdminApproval";
import CourseView from "./courseView";
import  IsbUser  from "./isbuserpage";
import CryptoJS from 'crypto-js'
import ProgramList from "./ProgramsList";
import  CreateProgram  from "../ProgramManagement/createProgram";
import { CreateSLAs } from "../ConfigureSLAs/CreateSLAs";
import  EditSLAs  from "../ConfigureSLAs/EditSLAs";
import { HandoutSLAs } from "../ConfigureSLAs/HandoutsSLAs";
import AdminDashboardRoleA from "./AdminDashboardRoleA";
import CourseUpload from "./courseUpload";
import  CourseList  from "../ProgramManagement/coursesList";
import StudentEnrollment from "../AdminFlow/StudentEnrollment";
import Attributions from "../AdminFlow/Attributions";
import CreateAttributions from "../AdminFlow/CreateAttributions";
import { ModuleTakeaways } from "./ModuleTakeaways";
import UpdateHeroImage from "./UpdateHeroImage";
import ListFreeLesson from "./ListFreeLesson";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";


const AdminFlow = (props) => {
    const history = useHistory()
    if(!localStorage.getItem('Admin_Acesstoken'))
    {
      history.push('/adminlogin')
    }else{
        let role =localStorage.getItem('role');
        let bytes = CryptoJS.AES.decrypt(role, crypto_secret_key);
        let decrypted_role = bytes.toString(CryptoJS.enc.Utf8);
        if(decrypted_role!=admin_role){
            if(decrypted_role==program_management_role){
                history.push('/programmanagementdashboard')
            }else if(decrypted_role == enrollment_management_role){
                history.push('/enrollmentmanagementdashboard')
            }
            else{
                history.push('/adminlogin')
            }
        }
    }

    let [activeLink, setActiveLink] = useState('admindashboardrolea');
    let [activeIcon,setActiveIcon]=useState('dashboard_icon')

    const handleListSelect = (selectedKey,IconId) => {
    setActiveLink(selectedKey);
    localStorage.setItem('adminSelectedTab',selectedKey)
    setActiveIcon(IconId)
            const listOfIds=['dashboard_icon',
            'viewModule_icon',
            'create_new_folder_icon',
            'view_list_icon',
            'drive_folder_upload_icon',
            'approval_FILL_icon',
            'supervised_user_circle_icon',
            'Create_SLA_icon',
            'template_icon',
            'attribute_icon',
            'assignment_icon',
            'updateHeroImage_icon'
        ]
        listOfIds.map(item=>{
            if(item==IconId)
            {
                const svg = document.getElementById(IconId);
                svg.querySelector("path").setAttribute("fill", "#ffffff");
            }
            else{
                const svg = document.getElementById(item);
                svg.querySelector("path").setAttribute("fill", "#708FAB");
            }
        })
  };

    useEffect(() => {
        let selectedTab = localStorage.getItem("adminSelectedTab");
        if (selectedTab && document.getElementById(selectedTab)) {
        document.getElementById(selectedTab).click();
        } else {
            let defaultTab = document.getElementById(activeLink);
            if (defaultTab) {
                defaultTab.click();
            } else {
                console.error(`The default tab with ID "${activeLink}" does not exist.`);
            }
        }
        localStorage.removeItem("admin_course")

    }, [])

    return (
        <>
            <div className=" row " data-testid="Admin_Sidebar">
                <div className="bg-body mt-3 col-lg-2 custm_shadow_cls " id='AdminNav'>
                    {/* New sidebar */}
                    <Nav className="flex-column nav nav-tabs row m-3 my_viewport">
                        <Nav.Link as={Link} to="/admindashboard/admindashboardrolea" id="admindashboardrolea" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'admindashboardrolea' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('admindashboardrolea','dashboard_icon')}>
                        <svg className="Dashoard_icon mr-2" id="dashboard_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M510 486V216h330v270H510ZM120 606V216h330v390H120Zm390 330V546h330v390H510Zm-390 0V666h330v270H120Z"/></svg>
                            Dashboard
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admindashboard/programlist" id="programlist" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'programlist' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('programlist','viewModule_icon')}>
                        <svg className="Dashoard_icon mr-2" id="viewModule_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M621 561V296h219v265H621Zm-250 0V296h219v265H371Zm-250 0V296h219v265H121Zm0 295V591h219v265H121Zm250 0V591h219v265H371Zm250 0V591h219v265H621Z"/></svg>
                        Programmes
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admindashboard/createprogram" id="createprogram" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'createprogram' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('createprogram','create_new_folder_icon')}>
                        <svg className="Dashoard_icon mr-2" id="create_new_folder_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M570 736h60v-90h90v-60h-90v-90h-60v90h-90v60h90v90ZM141 896q-24 0-42-18.5T81 836V316q0-23 18-41.5t42-18.5h280l60 60h340q23 0 41.5 18.5T881 376v460q0 23-18.5 41.5T821 896H141Z"/></svg>
                        Create Program
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/listcourses" id="listcourses" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'listcourses' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('listcourses','view_list_icon')}>
                        <svg className="Dashoard_icon mr-2" id="view_list_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M350 896h470q24.75 0 42.375-17.625T880 836V722H350v174ZM80 430h210V256H140q-24.75 0-42.375 17.625T80 316v114Zm0 233h210V490H80v173Zm60 233h150V722H80v114q0 24.75 17.625 42.375T140 896Zm210-233h530V490H350v173Zm0-233h530V316q0-24.75-17.625-42.375T820 256H350v174Z"/></svg>
                        List Courses
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/moduletakeaway" id="moduletakeaway" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'moduletakeaway' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('moduletakeaway','assignment_icon')}>
                        <svg className="Dashoard_icon mr-2" id="assignment_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 384 512" width="18"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z"/></svg>
                        Module Takeaway
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/courseupload" id="courseupload" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'courseupload' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('courseupload','drive_folder_upload_icon')}>
                        <svg className="Dashoard_icon mr-2" id="drive_folder_upload_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M450 776h60V571l74 74 42-42-146-146-146 146 42 42 74-74v205ZM141 896q-24 0-42-18.5T81 836V316q0-23 18-41.5t42-18.5h280l60 60h340q23 0 41.5 18.5T881 376v460q0 23-18.5 41.5T821 896H141Z"/></svg>
                        Course Upload
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/adminapprovalslist" id="adminapprovalslist" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'adminapprovalslist' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('adminapprovalslist','approval_FILL_icon')}>
                        <svg className="Dashoard_icon mr-2" id="approval_FILL_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M160 976V716q0-24 18-42t42-18h520q24 0 42 18t18 42v260H160Zm60-160h520V716H220v100Zm260-160L281 376q0-83 58.208-141.5Q397.415 176 480 176q82.585 0 140.792 58.5Q679 293 679 376L480 656Zm0-78 139-196v-6q0-58.333-40.559-99.167Q537.882 236 479.941 236T381.5 276.833Q341 317.667 341 376v6l139 196Zm0 0Z"/></svg>
                        Admin Approvals
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/isbusercreation" id="isbusercreation" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'isbusercreation' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('isbusercreation','supervised_user_circle_icon')}>
                        <svg className="Dashoard_icon mr-2" id="supervised_user_circle_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M373.121 636Q316 636 275.5 595.621q-40.5-40.379-40.5-97.5t40.379-97.621q40.379-40.5 97.5-40.5t97.621 40.379q40.5 40.379 40.5 97.5T470.621 595.5q-40.379 40.5-97.5 40.5ZM373 576q32.5 0 55.25-22.75T451 498q0-32.5-22.75-55.25T373 420q-32.5 0-55.25 22.75T295 498q0 32.5 22.75 55.25T373 576Zm301 106q-44 0-75-31t-31-75q0-44 31-75t75-31q44 0 75 31t31 75q0 44-31 75t-75 31ZM422 912q48-89 122.5-121.5T674 758q23 0 42.5 3.5T757 771q26-37 44.5-88.118Q820 631.765 820 576q0-142.375-98.812-241.188Q622.375 236 480 236t-241.188 98.812Q140 433.625 140 576q0 51 15 98.5t41 85.5q38-20 85-32t92-12q27.429 0 52.714 3.5Q451 723 473 731q-19 10-35 21.5T409 777q-10-1-19-1h-17q-34 0-73.5 9.5T231 809q38 40 87.5 67.5T422 912Zm58.468 64Q398 976 325 944.5q-73-31.5-127.5-86t-86-127.266Q80 658.468 80 575.734T111.5 420.5q31.5-72.5 86-127t127.266-86q72.766-31.5 155.5-31.5T635.5 207.5q72.5 31.5 127 86t86 127.032q31.5 72.532 31.5 155T848.5 731q-31.5 73-86 127.5t-127.032 86q-72.532 31.5-155 31.5Z"/></svg>
                        ISB User Creation
                        </Nav.Link>
                        <Nav.Link  as={Link} to="/admindashboard/createsla" id="createsla" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'createsla' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('createsla','Create_SLA_icon')}>
                        <svg className="Dashoard_icon mr-2" id="Create_SLA_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M0 816v-53q0-39.464 42-63.232T150.398 676q12.158 0 23.38.5T196 678.727q-8 17.273-12 34.842-4 17.57-4 37.431v65H0Zm240 0v-65q0-65 66.5-105T480 606q108 0 174 40t66 105v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773 696 765 678.727q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960 763v53H780ZM149.567 646Q121 646 100.5 625.438 80 604.875 80 576q0-29 20.562-49.5Q121.125 506 150 506q29 0 49.5 20.5t20.5 49.933Q220 605 199.5 625.5T149.567 646Zm660 0Q781 646 760.5 625.438 740 604.875 740 576q0-29 20.562-49.5Q781.125 506 810 506q29 0 49.5 20.5t20.5 49.933Q880 605 859.5 625.5T809.567 646ZM480 576q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600 456q0 50-34.5 85T480 576Z"/></svg>
                        Create SLA
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admindashboard/studentEnrollment" id="studentEnrollment" className={`pr-2 nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'studentEnrollment' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('studentEnrollment','template_icon')}>
                        <svg className="Dashoard_icon mr-2" id="template_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 0 18 18" width="18"><path d="M15 3H3C2.175 3 1.5075 3.675 1.5075 4.5L1.5 13.5C1.5 14.325 2.175 15 3 15H15C15.825 15 16.5 14.325 16.5 13.5V4.5C16.5 3.675 15.825 3 15 3ZM15 6L9 9.75L3 6V4.5L9 8.25L15 4.5V6Z"/></svg>
                        Student Enrollment
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admindashboard/attributions" id="attributions" className={`pr-2 nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'attributions' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('attributions','attribute_icon')}>
                        <svg width="18"  className="mr-2" id="attribute_icon" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.625 15.75H13.125C13.6223 15.75 14.0992 15.5525 14.4508 15.2008C14.8025 14.8492 15 14.3723 15 13.875V4.875C15 4.37772 14.8025 3.90081 14.4508 3.54917C14.0992 3.19754 13.6223 3 13.125 3H5.625C5.12772 3 4.65081 3.19754 4.29917 3.54917C3.94754 3.90081 3.75 4.37772 3.75 4.875V13.875C3.75 14.3723 3.94754 14.8492 4.29917 15.2008C4.65081 15.5525 5.12772 15.75 5.625 15.75ZM9 4.125C9 4.02554 9.03951 3.93016 9.10983 3.85984C9.18016 3.78951 9.27554 3.75 9.375 3.75H12.375C12.4745 3.75 12.5698 3.78951 12.6402 3.85984C12.7105 3.93016 12.75 4.02554 12.75 4.125V7.575C12.75 7.67085 12.7255 7.76511 12.6788 7.84884C12.6322 7.93256 12.5649 8.00297 12.4834 8.05339C12.4018 8.1038 12.3088 8.13255 12.213 8.1369C12.1173 8.14125 12.022 8.12106 11.9362 8.07825L10.875 7.54425L9.81375 8.07525C9.72822 8.11795 9.63322 8.13815 9.53771 8.13394C9.44221 8.12973 9.34935 8.10124 9.26791 8.05118C9.18647 8.00111 9.11913 7.93112 9.07226 7.8478C9.02538 7.76449 9.00051 7.6706 9 7.575V4.125ZM6 4.125C6 4.02554 6.03951 3.93016 6.10983 3.85984C6.18016 3.78951 6.27554 3.75 6.375 3.75C6.47446 3.75 6.56984 3.78951 6.64017 3.85984C6.71049 3.93016 6.75 4.02554 6.75 4.125V14.625C6.75 14.7245 6.71049 14.8198 6.64017 14.8902C6.56984 14.9605 6.47446 15 6.375 15C6.27554 15 6.18016 14.9605 6.10983 14.8902C6.03951 14.8198 6 14.7245 6 14.625V4.125Z" fill="white"/>
                        </svg>
                        Attributions
                        </Nav.Link>
                        <Nav.Link as={Link} to="/admindashboard/listFreeLesson" id="updateHeroImage" className={`pr-2 nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'updateHeroImage' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('updateHeroImage','updateHeroImage_icon')}>
                        <svg width="18"  className="mr-2" id="updateHeroImage_icon" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 12V1.5C13.5 0.675 12.825 0 12 0H1.5C0.675 0 0 0.675 0 1.5V12C0 12.825 0.675 13.5 1.5 13.5H12C12.825 13.5 13.5 12.825 13.5 12ZM4.125 7.875L6 10.1325L8.625 6.75L12 11.25H1.5L4.125 7.875Z" fill="white"/>
                        </svg>
                        Free Lesson Image
                        </Nav.Link>
                        </Nav>
                </div>
                {/* <LandNavbar/> */}
                <div className="tab-content col-lg-9 ">
                <div>
                        <div className="custm_shadow_cls form_field_container_cls">
                            <Switch>
                                <Route path="/admindashboard/admindashboardrolea">
                                   <AdminDashboardRoleA />
                                </Route>
                                <Route path="/admindashboard/programlist">
                                   <ProgramList />
                                </Route>
                                <Route path="/admindashboard/createprogram">
                                   <CreateProgram />
                                </Route>
                                <Route path="/admindashboard/listcourses">
                                   <CourseLearningTrackList />
                                </Route>
                                <Route path="/admindashboard/moduletakeaway">
                                   <ModuleTakeaways />
                                </Route>
                                <Route path="/admindashboard/courseupload">
                                   <CourseUpload />
                                </Route>
                                <Route path="/admindashboard/adminapprovalslist">
                                   <AdminApprovalModules />
                                </Route>
                                <Route path="/admindashboard/adminapprovals">
                                   <AdminApproval />
                                </Route>
                                <Route path="/admindashboard/isbusercreation">
                                   <IsbUser />
                                </Route>
                                <Route path="/admindashboard/createsla">
                                   <CreateSLAs />
                                </Route>
                                <Route path="/admindashboard/editsla">
                                   <EditSLAs />
                                </Route>
                                <Route path="/admindashboard/handoutsla">
                                   <HandoutSLAs />
                                </Route>
                                <Route path="/admindashboard/learningtrackadd">
                                <LearningTrackAdd />
                                </Route>
                                <Route path="/admindashboard/cohortsadd">
                                   <CohortsAdd />
                                </Route>
                                <Route path="/admindashboard/courseview">
                                   <CourseView />
                                </Route>
                                <Route path="/admindashboard/courselist">
                                   <CourseList />
                                </Route>
                                <Route path="/admindashboard/studentEnrollment">
                                   <StudentEnrollment />
                                </Route>
                                <Route path="/admindashboard/attributions">
                                   <Attributions />
                                </Route>
                                <Route path="/admindashboard/createattributions">
                                   <CreateAttributions />
                                </Route>
                                <Route path="/admindashboard/listFreeLesson">
                                <ListFreeLesson />
                                </Route>
                                <Route path="/admindashboard/updateHeroImage">
                                <UpdateHeroImage />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
                <div className="col-lg-1">
                    <span className="visible-hidden">text</span>
                </div>
            </div>

        </>
    );
};

export default withMaintenanceCheck(AdminFlow,admin_maintenance_type) 



