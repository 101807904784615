import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import { useEffect,Fragment,useState } from "react";
import { useRef,useLayoutEffect,screen} from "react";
import { Popover, Tooltip } from "react-bootstrap";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
function Stepperprogress(props) {
  const progress_stepper=useRef(null)
  const [height, setHeight] = useState();
  useLayoutEffect(() => {
     setTimeout(() => {
         window.scrollTo({
          top:  window.scrollY+window.innerHeight,
          left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
    const { data,view } = props;
    let [progressdata,setprogressdata]=useState([])
    let [progressMaxVal,setProgressMaxVal]=useState(0)
     useEffect(() => {
       // setProgressMaxVal(300)
       if(data.length>0)
       {
        const maxval=data[data.length-1].value
        data.map(obj=>{
          obj['width']=parseInt((obj.value/maxval)*100)
         }) 
        data.sort((a,b)=>parseInt(a.value)-parseInt(b.value))
          if(data.length == 4){
            if((data[1].width - data[0].width)<15){                
                data[1].width = data[1].width + 8
              if((data[2].width-data[1].width) <15)
              {
                data[2].width = data[2].width + 16
              }
              if(data[3].width - data[2].width < 15)
              {
                data[2].width = data[2].width - 8
              }
              }else if(data[3].width - data[2].width < 15){
                data[1].width = data[1].width - 16
                data[2].width = data[2].width - 8
              }else if(data[2].width - data[1].width < 15){
                data[1].width = data[1].width + 8
                data[2].width = data[2].width + 16
             }
             if(data[1].value == maxval && data[2].value == maxval){
              data[1].label = "Course completed";
              data[1].mobile_label = "Course completed";
              data[1].width = 100;
              data.pop();
              data.pop();
            }
           else if(data[2].value == maxval||data[2].value > maxval)
            {
              data[2].label = "You Reached Max Points";
              data[2].mobile_label = "You Reached Max Points";
              data[2].width = 100;
              data.pop();
            }
          }
          if(data.length == 3){
            if((data[1].width - data[0].width)<15){
              data[1].width = data[1].width + 8
            }else if(data[2].width - data[1].width < 15){
              data[1].width = data[1].width - 8
           }
           if(data[1].value == maxval){
             data[1].label = "Course completed";
             data[1].mobile_label = "Course completed";
             data[1].width = 100;
             data.pop();
           } 
          }
          
          data.forEach((obj,i) => {
            if (obj.width < 7&&i!==0) {
              obj.width = 7;
            }
          });

          for (let i = 1; i < data.length - 1; i++) {
            const currentObj = data[i];
            const nextObj = data[i + 1];
            if (nextObj.width - currentObj.width < 7) {
              currentObj.width = nextObj.width - 7;
              if((data[i].width-data[i-1].width)<7&&i-1!==0)
              {
                let remainingCount=7-(data[i].width-data[i-1].width)
                data[i-1].width-=(data[i].width-data[i-1].width)+remainingCount
              }
            }
          }
        setprogressdata(data) 
       }
     }, [])
    return ( 
        <>
        {data.length>0&&<>
        <div ref={progress_stepper} style={{ margin:view=="WEB"?data.length==3?'25px 20px 86px 10px':'25px 20px 55px 10px':(data.length==3?'12px 5px 28px 2px':'20px 5px 22px 2px') }}>
          <ProgressBar
            width={"100%"}
            percent={100}
            height={view=="WEB"?4:1}
            // stepPositions={[0,50,100]}
            // filledBackground="#fefb72"
            >
        {data.map((step, index, arr) => {
          const tooltipId = `tooltipProgressBar-${index}`;
          return (
            <Fragment key={index}>
                     <Step
                position={step.width} 
                children={({ accomplished }) => (
                  <>
                  {(index!=0&&index!=data.length-1&&window.innerWidth<768)?
                  <>
                             <OverlayTrigger id={tooltipId} rootClose  trigger="click" placement="top" overlay={<Tooltip style={{backgroundColor: step.color}} className="progressbar_tooltip">{step.mobile_label}
              <br />
              {step.value}
              </Tooltip>}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      textAlign:'center',
                      justifyContent: "center",
                      transform:"rotate(45deg)",
                      width:view=="WEB"?20:12,
                      height:view=="WEB"?20:12,
                      color: "black",
                      backgroundColor: step.color
                    }}
                  >   
                  <div>
                      {index>0&&<span className={view=="WEB"?'progress_countValue':'progress_countValue_mobile'} style={{color:`${step.color}`}} >{view=="WEB"?step.value :(index==data.length-1&&step.value)}</span> } 
                  </div>
                
                  <span className={view=="WEB"?((data.length==3&&index==1)?'progressLabel_Onjoined':'progressLabel'):'progressLabel_mobile'}>
                          {view=="WEB"?step.label:((index==0||index==data.length-1)&& step.mobile_label)}
                          </span>
                  </div>
                    </OverlayTrigger>
                  </>:<>
                  <div
                  className="Step_diamond"
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      textAlign:'center',
                      justifyContent: "center",
                      transform:"rotate(45deg)",
                      width:view=="WEB"?20:12,
                      height:view=="WEB"?20:12,
                      color: "black",
                      backgroundColor: step.color
                    }}
                  >   
                  <div>
                      {index>0&&<span className={view=="WEB"?'progress_countValue':'progress_countValue_mobile'} style={{color:`${step.color}`}} >{view=="WEB"?step.value :(index==data.length-1&&step.value)}</span> } 
                  </div>
                
                  <span className={view=="WEB"?((data.length==3&&index==1)?'progressLabel_Onjoined':'progressLabel'):'progressLabel_mobile'}>
                          {view=="WEB"?step.label:((index==0||index==data.length-1)&& step.mobile_label)}
                          </span>
                  </div>
                  </>}
                  </>
                )}
              />
            </Fragment>
          )
        })}
          </ProgressBar>
        </div>
</>}
      
      </>

     );
}

export default Stepperprogress;