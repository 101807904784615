import { useRef, useLayoutEffect,useEffect, useState } from "react";

export const QuoteElement = (props) => {
  const quote_component = useRef(null);
  const quote_component_mobile=useRef(null)
  let data = props.data;
  let purpose = props.purpose;
  useLayoutEffect(() => {
    props.i == 0
      ? setTimeout(() => {
          window.scrollTo({
            top: window.scrollY,
            left: 0,
            behavior: "smooth",
          });
        }, 350)
      : setTimeout(() => {
          window.scrollTo({
            top: window.outerWidth < 821 ? quote_component_mobile.current.offsetTop - 30: quote_component.current.offsetTop - 30,
            left: 0,
            behavior: "smooth",
          });
        }, 350);
  }, []);
  useEffect(()=>{
    const container = document.createElement('div');
    container.innerHTML = data.str_data; 
    const text = container.querySelectorAll('.summary_header_cls');
    const liElements = document.querySelectorAll('#quoteelement .summary_list_cls');
    const headerTexts = Array.from(text).map(element => element.textContent.trim())
    const liTexts = Array.from(liElements).map(li => li.textContent.trim());
    let combinedText = [...headerTexts,...liTexts,data.img].join('. ')
    localStorage.setItem('prev_title',data?.title_data)
    localStorage.setItem('prev_text',combinedText)
   
},[])
  return (
    <>
      <div  data-testid="QuoteElement_content" className="container myContainerCls mt-5 quote-block-bg-color">
        <div className="quote-block row quote_element_web" ref={quote_component}>
          <div className="col-3">
            <img className="profile-img img-fluid" src={data.img}></img>
          </div>
          <div className="col-2"><span className="visible-hidden">Temp</span></div>
          <div className="col-7 blockquote pb-4 mt-5 pr-3">
            <div>        
                 <div className="cite-last-name"> 
                    <div><blockquote>{data.title_data.toUpperCase()}</blockquote></div>
                    <br />
                    <div id="quoteelement" dangerouslySetInnerHTML={{ __html: data.str_data.toUpperCase() }}>
                   </div>
                </div>
            </div>
          </div>
        </div>

        <div className="quote-block quote_element_mobile" ref={quote_component_mobile}>
          <div className="blockquote mt-5 ml-4">
            <div className="d-flex">        
                 <div className="quote_elmnt_one"> 
                    <div><blockquote>{data.title_data.toUpperCase()}</blockquote></div>
                </div>
            </div>
          </div>
          <div className="d-flex align-items-center quote_element_finaltext">
              <div style={{width:'20%'}}>
                <img className="img-fluid quote_Image" src={data.img}></img>
              </div>
              <div style={{width:'80%'}}>
              <div className="d-block text-center" id="quoteelement" dangerouslySetInnerHTML={{ __html: data.str_data.toUpperCase() }}>
                   </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
