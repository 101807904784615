import { useHistory } from "react-router-dom";



const NotFoundPage = (props) => {
    const history = useHistory();
    const backToDashboard = () =>{
        history.push("/")
      }

return(
<>

<div className="container content_div justify-content-center" style={{textAlign:'center',color:'rgb(5, 112, 146)'}}>
  <h3>Right now the course doesn't have the access,will get back to you soon...!</h3>
  <button
                className="btn my_btn_cls mt-0 align-self-end"
                onClick={backToDashboard}
              >
                Dashboard
              </button>{" "}
</div>
</>
)


}

export default NotFoundPage;