import { useEffect,useState,React,Fragment } from "react";
import Slider from "react-slick";
import { OverlayTrigger,Tooltip } from 'react-bootstrap';
import { base_url, one_point } from "../../utilities";
import axios from "../../axios-interceptor";
function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}>
            <img src="/images/arrow_backward.svg"></img>
        </div>
    
    );
  }
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style }}
        onClick={onClick}>
          <img src="/images/arrow_forward.svg"></img>
      </div>
    );
  }
export const SliderComponent = (props) =>{
  let [slider_data,setSliderData] = useState([])
  let [isProfessor,setIsProfessor] = useState(false)
  let [isSubmittedReeval,setIsSubmittedReeval] = useState(false)
  let [reevalCompleted,setReevalCompleted] = useState(false)
  let [reevalPending,setReevalPending] = useState(false)
  let [activityData,setActivityData]=useState({})
  let [reevaluation_status,setReevaluation_status]=useState(11)
  let [activityQuestion,setActivityQuestion]=useState("")
  const handleSlideChange = (oldIndex, newIndex) => {
    // Do something when the slide changes
    if(slider_data[newIndex])
    {
      let content=slider_data[newIndex]
      const hasSpecificId = content.includes('id="SliderProfessor"');

    if (hasSpecificId) {
      setIsProfessor(true)
    }
    else{
      setIsProfessor(false)
    } 
    }
  };
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows:true,
        autoplay:false,
        adaptiveHeight: true,
        autoplaySpeed: 3000,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        beforeChange: (oldIndex, newIndex) => handleSlideChange(oldIndex, newIndex), // Attach the event handler
      };
    useEffect(()=>{
        setActivityData(props.activityData)
        if(props.activityData.reevaluation_status !== null && props.activityData.reevaluation_status !== undefined)
        {
          setReevaluation_status(props.activityData.reevaluation_status)
        }
        else{
          setReevaluation_status(3)
        }
        let questionArr=document.getElementsByClassName('questionText')
        const lastIndex = questionArr.length - 1;
        if (lastIndex >= 0) {
          const lastQuestionText = questionArr[lastIndex].textContent;
          setActivityQuestion(lastQuestionText)
        } 
        if(props.data&&props.data.length>0)
        {
          let content=props.data[0]
          const hasSpecificId = content.includes('id="SliderProfessor"');
          if (hasSpecificId) {
            setIsProfessor(true)
          } 
        }
        setSliderData(props.data)
    },[props.data])
    const handleSubmitReevalution=()=>{
      let facultyResponse=""
      const elements = document.querySelectorAll('.peerresponse_mobile'); // Replace with your actual class name
      // Loop through each element and extract the text content
      elements.forEach(element => {
        const textContent = element.textContent;
        const hasProfessor = textContent.includes('- Faculty');
        if(hasProfessor)
        {
          const extractedText = textContent.replace(/- Faculty/g, '');
          facultyResponse=extractedText
        }
      });
      setIsSubmittedReeval(true)
      axios.post(base_url+'user/save_reevaluation',{
        "user_id":localStorage.getItem("user_id"),
        "course_id":localStorage.getItem("course_id"),
        "module_id":localStorage.getItem("moduleId"),
        "lesson_id":localStorage.getItem("lessonId"),
        "program_id":localStorage.getItem("programe_id"),
        "cohort_schedule_id":localStorage.getItem("cohort_id"),
        "activity_id":activityData.activity_id,
        "question":activityQuestion,
        "user_answer":props.user_answer,
        "faculty_response":facultyResponse
      })
      .then(res=>{
        setReevaluation_status(res.data?.result?.status)
      })
      .catch(err=>{
        console.log(err)
      })
    }
 return(
<>
<div className="container peerResponse_Slider" data-testid="slider_component">
  {
   <div className="slider_points blue_text">
    {props.points && <>
      { (<span  data-testid="earnedText_heading" id={"registerTip0"}  className="ml-2 message-text">You’ve earned {props.points.attempting_points+props.points.accuracy_points} out of {props.points.total_points == one_point ? '1 point' : props.points.total_points + ' points'}</span>)}
  <OverlayTrigger placement="top" overlay={
    <Tooltip className="module_tooltip"
    id={"registerTip0" }>   
     <span>You received {props.points.attempting_points+props.points.accuracy_points == one_point ? '1 point' : props.points.attempting_points+props.points.accuracy_points + ' points'}</span>
    </Tooltip>} offset={[0,10]}>
    <span data-testid="slider_points_info" className="cursor-pointer"> ⓘ</span>
  </OverlayTrigger>
    </>}
  <div className="float-right">
       {/* 1-rejected */}
   {/* 3- not applied for reevaluation */}
    {/* {reevaluation_status==3&&
    <>
    <div className="d-none" data-testid="submitRe_evaluationTooltipContent">
      <span>Submit for Re-evaluation</span>
    </div>
     <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="reevaluation_tooltip" id={"registerTip0"}>
                  <span>Submit for Re-evaluation</span>
                </Tooltip>
              }
              offset={[0, 10]}
            >
              <span className="cursor-pointer" data-testid="Submit_reevaluation">
           <svg  onClick={handleSubmitReevalution} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M18.9993 13.168C15.791 13.168 13.166 15.793 13.166 19.0013C13.166 22.2096 15.791 24.8346 18.9993 24.8346C22.2077 24.8346 24.8327 22.2096 24.8327 19.0013C24.8327 15.793 22.2077 13.168 18.9993 13.168Z" fill="#057092"/>
            <path d="M16.8 0H2.4C1.08 0 0 1.08 0 2.4V21.6C0 22.92 1.08 24 2.4 24H13.8C12.6 22.68 12 21 12 19.2C12 15.24 15.24 12 19.2 12V2.4C19.2 1.08 18.12 0 16.8 0ZM9.6 20.4H3.6C2.88 20.4 2.4 19.92 2.4 19.2C2.4 18.48 2.88 18 3.6 18H9.6C10.32 18 10.8 18.48 10.8 19.2C10.8 19.8 10.32 20.4 9.6 20.4ZM9.6 15.6H3.6C2.88 15.6 2.4 15.12 2.4 14.4C2.4 13.68 2.88 13.2 3.6 13.2H9.6C10.32 13.2 10.8 13.68 10.8 14.4C10.8 15 10.32 15.6 9.6 15.6ZM12 10.8H3.6C2.88 10.8 2.4 10.2 2.4 9.6C2.4 8.88 2.88 8.4 3.6 8.4H12C12.72 8.4 13.2 8.88 13.2 9.6C13.2 10.2 12.72 10.8 12 10.8ZM12 6H3.6C2.88 6 2.4 5.4 2.4 4.8C2.4 4.08 2.88 3.6 3.6 3.6H12C12.72 3.6 13.2 4.08 13.2 4.8C13.2 5.4 12.72 6 12 6Z" fill="#057092"/>
            <path d="M18.4588 21.835H19.4313V20.8625H18.4588V21.835ZM18.945 16C17.8704 16 17 16.8704 17 17.945H17.9725C17.9725 17.4101 18.4101 16.9725 18.945 16.9725C19.4799 16.9725 19.9175 17.4101 19.9175 17.945C19.9175 18.9175 18.4588 18.7959 18.4588 20.3762H19.4313C19.4313 19.2822 20.89 19.1606 20.89 17.945C20.89 16.8704 20.0196 16 18.945 16Z" fill="white"/>
          </svg>
      </span>
            </OverlayTrigger>
    </>} */}
    {/*1 rejected */}
    {/* {reevaluation_status==1&&
      <>
      <div className="d-none" data-testid="reevalutionAwaiting_tooltipContent">
      <span>We regret to inform that,</span> 
                  <br />
                    <span>
                    after careful consideration,
                    </span>
                    <br />
                    <span>
                    your re-evaluation request
                    </span>
                    <br />
                    <span>
                    is declined
                    </span>
      </div>
      <div>
        <span className="reevalutionAwaiting">        
        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="reevaluation_tooltipRejected" id={"registerTip0"}>
                  <span>We regret to inform that,</span> 
                  <br />
                    <span>
                    after careful consideration,
                    </span>
                    <br />
                    <span>
                    your re-evaluation request
                    </span>
                    <br />
                    <span>
                    is declined
                    </span>
                </Tooltip>
              }
              offset={[0, 10]}
            >
              <span data-testid="slider_ReevaluationDeclined" className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
              <circle cx="19" cy="19" r="6.5" fill="#D77A69" stroke="white"/>
              <path d="M14.7656 18.8828L17.7656 21.8828" stroke="white" stroke-linecap="round"/>
              <path d="M22.7656 16.8828L17.7656 21.8828" stroke="white" stroke-linecap="round"/>
              <path d="M16.8 0H2.4C1.08 0 0 1.08 0 2.4V21.6C0 22.92 1.08 24 2.4 24H13.8C12.6 22.68 12 21 12 19.2C12 15.24 15.24 12 19.2 12V2.4C19.2 1.08 18.12 0 16.8 0ZM9.6 20.4H3.6C2.88 20.4 2.4 19.92 2.4 19.2C2.4 18.48 2.88 18 3.6 18H9.6C10.32 18 10.8 18.48 10.8 19.2C10.8 19.8 10.32 20.4 9.6 20.4ZM9.6 15.6H3.6C2.88 15.6 2.4 15.12 2.4 14.4C2.4 13.68 2.88 13.2 3.6 13.2H9.6C10.32 13.2 10.8 13.68 10.8 14.4C10.8 15 10.32 15.6 9.6 15.6ZM12 10.8H3.6C2.88 10.8 2.4 10.2 2.4 9.6C2.4 8.88 2.88 8.4 3.6 8.4H12C12.72 8.4 13.2 8.88 13.2 9.6C13.2 10.2 12.72 10.8 12 10.8ZM12 6H3.6C2.88 6 2.4 5.4 2.4 4.8C2.4 4.08 2.88 3.6 3.6 3.6H12C12.72 3.6 13.2 4.08 13.2 4.8C13.2 5.4 12.72 6 12 6Z" fill="#057092"/>
            </svg>
      </span>
            </OverlayTrigger>
        </span>
      </div>
      </>} */}
     {/* 2-approved */}
    {/* {reevaluation_status==2&&
      <> 
      <div className="d-none" data-testid="submitionComplete_tooltipContent">
      <span>Your submission was re-</span><br />
                  <span>evaluated and score is</span><br />
                  <span>now confirmed</span>
      </div>
      <div>
        <span className="reevalutionAwaiting">
        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="reevaluation_tooltip reevaluation_tooltip_complete" id={"registerTip0"}>
                  <span>Your submission was re-</span><br />
                  <span>evaluated and score is</span><br />
                  <span>now confirmed</span>
                </Tooltip>
              }
              offset={[0, 10]}
            >
              <span data-testid="slider_Reevaluationsubmitted" className="cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
            <circle cx="19" cy="19" r="6.5" fill="#2DAF8C" stroke="white"/>
            <path d="M14.7656 18.8828L17.7656 21.8828" stroke="white" stroke-linecap="round"/>
            <path d="M22.7656 16.8828L17.7656 21.8828" stroke="white" stroke-linecap="round"/>
            <path d="M16.8 0H2.4C1.08 0 0 1.08 0 2.4V21.6C0 22.92 1.08 24 2.4 24H13.8C12.6 22.68 12 21 12 19.2C12 15.24 15.24 12 19.2 12V2.4C19.2 1.08 18.12 0 16.8 0ZM9.6 20.4H3.6C2.88 20.4 2.4 19.92 2.4 19.2C2.4 18.48 2.88 18 3.6 18H9.6C10.32 18 10.8 18.48 10.8 19.2C10.8 19.8 10.32 20.4 9.6 20.4ZM9.6 15.6H3.6C2.88 15.6 2.4 15.12 2.4 14.4C2.4 13.68 2.88 13.2 3.6 13.2H9.6C10.32 13.2 10.8 13.68 10.8 14.4C10.8 15 10.32 15.6 9.6 15.6ZM12 10.8H3.6C2.88 10.8 2.4 10.2 2.4 9.6C2.4 8.88 2.88 8.4 3.6 8.4H12C12.72 8.4 13.2 8.88 13.2 9.6C13.2 10.2 12.72 10.8 12 10.8ZM12 6H3.6C2.88 6 2.4 5.4 2.4 4.8C2.4 4.08 2.88 3.6 3.6 3.6H12C12.72 3.6 13.2 4.08 13.2 4.8C13.2 5.4 12.72 6 12 6Z" fill="#057092"/>
          </svg>
      </span>
            </OverlayTrigger>
        </span>
      </div>
      </>} */}
     {/* 0- pending */}
    {/* {reevaluation_status==0&& 
     <>
     <div className="d-none" data-testid="reevaltion_awaiting">
       <span>Awaiting Re-Evaluation</span>
     </div>
      <div>
        <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="reevaluation_tooltip" id={"registerTip0"}>
                  <span>Awaiting Re-Evaluation</span>
                </Tooltip>
              }
              offset={[0, 10]}
            >
              <span data-testid="slider_awaiting_reevalution" className="cursor-pointer">
              <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
              <path d="M18.9993 13.168C15.791 13.168 13.166 15.793 13.166 19.0013C13.166 22.2096 15.791 24.8346 18.9993 24.8346C22.2077 24.8346 24.8327 22.2096 24.8327 19.0013C24.8327 15.793 22.2077 13.168 18.9993 13.168ZM20.166 21.1596C19.8743 21.3346 19.5243 21.218 19.3493 20.9263L18.4743 19.293C18.416 19.1763 18.416 19.118 18.416 19.0013V16.0846C18.416 15.7346 18.6493 15.5013 18.9993 15.5013C19.3493 15.5013 19.5827 15.7346 19.5827 16.0846V18.8263L20.3993 20.343C20.516 20.6346 20.4577 20.9846 20.166 21.1596Z" fill="#057092"/>
              <path d="M16.8 0H2.4C1.08 0 0 1.08 0 2.4V21.6C0 22.92 1.08 24 2.4 24H13.8C12.6 22.68 12 21 12 19.2C12 15.24 15.24 12 19.2 12V2.4C19.2 1.08 18.12 0 16.8 0ZM9.6 20.4H3.6C2.88 20.4 2.4 19.92 2.4 19.2C2.4 18.48 2.88 18 3.6 18H9.6C10.32 18 10.8 18.48 10.8 19.2C10.8 19.8 10.32 20.4 9.6 20.4ZM9.6 15.6H3.6C2.88 15.6 2.4 15.12 2.4 14.4C2.4 13.68 2.88 13.2 3.6 13.2H9.6C10.32 13.2 10.8 13.68 10.8 14.4C10.8 15 10.32 15.6 9.6 15.6ZM12 10.8H3.6C2.88 10.8 2.4 10.2 2.4 9.6C2.4 8.88 2.88 8.4 3.6 8.4H12C12.72 8.4 13.2 8.88 13.2 9.6C13.2 10.2 12.72 10.8 12 10.8ZM12 6H3.6C2.88 6 2.4 5.4 2.4 4.8C2.4 4.08 2.88 3.6 3.6 3.6H12C12.72 3.6 13.2 4.08 13.2 4.8C13.2 5.4 12.72 6 12 6Z" fill="#057092"/>
            </svg>
      </span>
            </OverlayTrigger>
      </div>
      </>} */}
  </div>
  </div>
}
<div className="d-flex justify-content-center flex-column align-items-center">
 
  {/* <h3 className="blue_text title">{isProfessor?'Points for Further Reflection':'Peer Responses'}</h3> */}
  <h3 data-testid="slider_content_heading" className="blue_text title pb-1">{isProfessor? 'Feedback':'Peer Responses'}</h3>
</div>
<Slider {...settings}>
    {
     slider_data.length !=0 &&   slider_data.map((item,index)=>{
            return(
                <Fragment key={index}>
                 <div className="d-flex container justify-content-center flex-column align-items-center ">
                  <div className="p-3">
                    <div data-testid="slider_content" className="peerresponse_mobile" dangerouslySetInnerHTML={{ __html: item }}></div>
                    {/* <p>{item.user_answer}</p> */}
                    {/* <span className="blue_text user_data_span"> {item.first_name? '- ' + item.first_name.toUpperCase():""}{item.city? ', '+item.city.toUpperCase():""}</span> */}
                  </div>  
                 </div>
                </Fragment>
            )
        })
    }
  
</Slider>
</div>
</>
 )
}