import { useRef,useEffect,useLayoutEffect ,useState,screen} from "react";
export const VideoComponent = (props) => {
   // console.log(props,"checking for prop 5")
    let data = props.data;
    localStorage.setItem('prev_title',data?.title_data)
    localStorage.setItem('prev_text',data?.video)
    const video_Component=useRef(null)
useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
          top:  video_Component.current?.offsetTop-20,
          left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
  
  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // Use the viewport as the root
      rootMargin: '0px',
      threshold: 0.5, // Adjust the threshold as needed
    });

    // Observe the video element
    if (video_Component.current) {
      observer.observe(video_Component.current);
    }

    // Cleanup observer on component unmount
    return () => {
      if (video_Component.current) {
        observer.unobserve(video_Component.current);
      }
    };
  }, []);
  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        // Video is in the viewport
      } else {
        pauseVideoRoll()
        // Video is not in the viewport
      }
    });
  };
  const pauseVideoRoll = (id) => {
    var iframes = document.getElementsByClassName("panaptoVideo");
    if (iframes.length !== null) {
      Array.from(iframes).map((item) => {
        var iframeSrc = item.src;
        item.src = iframeSrc;
      });
    }
  };
    return (
        <>
            <div data-testid="VideoComponent_content">
                <div className="col-12 video-component custm_shadow_cls bg-body" ref={video_Component}>
                    <h2>{data?.title_data}</h2>
                    <iframe
                        key={indexedDB}
                        className="mt-3 panaptoVideo"
                        width="100%"
                        height="630"
                        auto="true"
                        src={data?.video + '&showTitle=false&interactivity=none'}
                        title="Panapto Video Player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    ></iframe>
                </div>
            </div>
        </>
    )
}