import React from 'react';
import { useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { button } from 'react-bootstrap';
import { useHistory , useLocation } from "react-router-dom";
import axios from "../../axios-interceptor";
import {base_url, storefront_url, user_maintenance_type} from '../../utilities'
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';

const ApplicationRecvd = () => {
    const [course, setCourse] = useState([]);
  //  console.log(course)
    const [course_title,setCourseTitle] = useState('');
    const history = useHistory();
    // if(!localStorage.getItem('login')){
    //   history.push('/');
    // }
    var [modules,setModules] =useState([]) ;
    var id='';

      const sendDatatoModule = (item) => {
        localStorage.removeItem('application_email')
        history.push('/dashboard')
        window.location.reload();
      }
      const gotoLogin = ()=>{
        localStorage.clear();
        window.location.href = storefront_url
      }
    return (

        <>
        <div className='container-sm app_recvd_contnr custm_shadow_cls' >
            <h1 className='app_recvd_header'>Application Received</h1>
           
            <p className="app_rcvd_popup_txt">
            Thank you! Your application has been received, and the ISB Online Admissions team will respond within 72 Hours, via an email to your registered email. <br /> <b>{localStorage.getItem('application_email')}</b> </p>
            
            <p className="app_rcvd_popup_txt">If you do not receive an email in this time, please check your spam folder and then contact isbonline@isb.edu. </p>
             
            <p className="app_rcvd_popup_txt">
             You can now close this window or continue browsing other courses.
           </p>
           {localStorage.getItem('User_Accesstoken') && localStorage.getItem('isLoggedIn') == 'true'  ? <>
           <button type="button" onClick={()=>sendDatatoModule()} className="btn vie_othr_course_btn">Dashboard</button>
           </>:<>
           <button type="button" onClick={()=>gotoLogin()} className="btn vie_othr_course_btn">View Other Courses</button>
           </>}
           
           
          
        
            {/* <Link to='/EffectiveNego'>
            <button type="button" className="btn my_btn_cls vie_othr_course_btn">View Other Courses</button>

            </Link>  */}
            {/* <Link to='/AdminApproval'>
            <button type="button" className='btn my_btn_cls'>Admin Approval</button></Link> */}
            </div>
        </>
    );
};

export default withMaintenanceCheck(ApplicationRecvd,user_maintenance_type)