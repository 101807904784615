import { Container } from "react-bootstrap";
import React from 'react'
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { admin_maintenance_type } from "../../utilities";

const AdminDashboardRoleA = () => {
  return (
    <>
    <div className="vh-100">
    <Container className='bg-transparent  pb-0'>
                                <h6 className="dashboard-heading">Welcome to the admin!</h6>
                                <h5 className="card-title" >Explore the options on the left side of the menu navigator.</h5>
    </Container>
                          
    </div>
       
    </>
  )
}

export default withMaintenanceCheck(AdminDashboardRoleA,admin_maintenance_type)