import React from 'react'
import withScreenSizeCheck from '../HigherOrderComponents/withScreenSizeCheck'

const mobileViewMessage = () => {
  return (
    <div className="mobileview-container">
        <div className='mobileview-content'>
            <span className="d-block text-center mobileViewMessage">
            Please open on desktop for a better experience
            </span>
        </div>
  </div>
  )
}

export default withScreenSizeCheck(mobileViewMessage)