
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route,Link,useHistory, useLocation } from "react-router-dom";
import { base_url,admin_role,crypto_secret_key,program_management_role, random_number,enrollment_management_role, admin_maintenance_type} from "../../utilities";
import axios from "../../axios-interceptor";
import Nav from 'react-bootstrap/Nav';
import  AdminApprovalModules  from "../AdminApprovalModules";
import CryptoJS from 'crypto-js';
import AdminApproval from "../AdminApprovals/AdminApproval";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";


const EnrollmentFlow = (props) => {
    const history = useHistory()
    if (!localStorage.getItem('Admin_Acesstoken')) {
        history.push('/adminlogin')
    }
    let role =localStorage.getItem('role');
    if(role)
    {
        let bytes = CryptoJS.AES.decrypt(role, crypto_secret_key);
        let decrypted_role = bytes.toString(CryptoJS.enc.Utf8);
        if(decrypted_role!=enrollment_management_role){
            if(decrypted_role==admin_role){
                history.push('/admindashboard')
            }else if(decrypted_role == program_management_role){
                history.push('/programmanagementdashboard')
            }
            else{
                history.push('/adminlogin')
            }
           
        }
    }

    let [activeLink, setActiveLink] = useState('adminapprovalslist');
    let [activeIcon,setActiveIcon]=useState('approval_FILL_icon');

    const handleListSelect = (selectedKey,IconId) => {
        setActiveLink(selectedKey);
        localStorage.setItem('enrollmentSelectedTab',selectedKey)
        setActiveIcon(IconId)
                const listOfIds=[
                'approval_FILL_icon',
            ]
            listOfIds.map(item=>{
                if(item==IconId)
                {
                    const svg = document.getElementById(IconId);
                    svg.querySelector("path").setAttribute("fill", "#ffffff");
                }
                else{
                    const svg = document.getElementById(item);
                    svg.querySelector("path").setAttribute("fill", "#708FAB");
                }
            })
      };

    useEffect(() => {
        let selectedTab = localStorage.getItem("enrollmentSelectedTab");
        if (selectedTab && document.getElementById(selectedTab)) {
        document.getElementById(selectedTab).click();
        } else {
            let defaultTab = document.getElementById(activeLink);
            if (defaultTab) {
                defaultTab.click();
            } else {
                console.error(`The default tab with ID "${activeLink}" does not exist.`);
            }
        }
        localStorage.removeItem("admin_course")
    }, [])

    return (
        <>
            <div className=" row ">
                <div className="bg-body mt-3 col-lg-2 vh-100 custm_shadow_cls " id='AdminNav'>
                <Nav className="flex-column nav nav-tabs row m-3 my_viewport">
                <Nav.Link  as={Link} to="/enrollmentmanagementdashboard/adminapprovalslist" id="adminapprovalslist" className={`nav-item border  AdminNav-item-style mt-3 d-flex align-items-center ${activeLink === 'adminapprovalslist' ? 'adminNavItemSelected' : 'adminNavItem'}`} onClick={()=>handleListSelect('adminapprovalslist','approval_FILL_icon')}>
                        <svg className="Dashoard_icon mr-2" id="approval_FILL_icon" xmlns="http://www.w3.org/2000/svg" height="18" viewBox="0 96 960 960" width="18"><path d="M160 976V716q0-24 18-42t42-18h520q24 0 42 18t18 42v260H160Zm60-160h520V716H220v100Zm260-160L281 376q0-83 58.208-141.5Q397.415 176 480 176q82.585 0 140.792 58.5Q679 293 679 376L480 656Zm0-78 139-196v-6q0-58.333-40.559-99.167Q537.882 236 479.941 236T381.5 276.833Q341 317.667 341 376v6l139 196Zm0 0Z"/></svg>
                        Admin Approvals
                        </Nav.Link>
                    </Nav>
                </div>
                <div className="tab-content col-lg-9 ">
                <div>
                        <div className="custm_shadow_cls form_field_container_cls">
                            <Switch>
                                <Route path="/enrollmentmanagementdashboard/adminapprovalslist">
                                   <AdminApprovalModules />
                                </Route>
                                <Route path="/enrollmentmanagementdashboard/adminapprovals">
                                   <AdminApproval />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default withMaintenanceCheck(EnrollmentFlow,admin_maintenance_type) 

