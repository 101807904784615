import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { base_url, api_error_message, random_number } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory, useLocation } from "react-router-dom";
import { createTemplateValidations } from "../../formValidationsRules";

const CreateTemplate = () => {
  const [template_name, setTemplateName] = useState("");
  const [subject_name, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [loading_flag, SetLoading_flag] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const template_info = location.state?.template_info;

  const handleEditorChange = (content) => {
    setContent(content);
    setValue("body", content);
    checkingFormValues();
  };

  const checkingFormValues = () => {
    const isAnyValueEmpty = subject_name?.trim() === "" || template_name?.trim() === "" || content?.trim() === "";
    setIsSubmitted(isAnyValueEmpty);
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting, isValid },
    reset,
  } = useForm({ mode: "all" });

  useEffect(() => {
    if (template_info) {
      setContent(template_info.body);
      setValue("body", template_info.body)
      setTemplateName(template_info.name);
      setValue("name", template_info.name)
      setSubject(template_info.subject);
      setValue("subject", template_info.subject);
      setIsSubmitted(true);
    }

  }, [template_info]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name == "subject") {
      setSubject(value);
    } else {
      setTemplateName(value);
    }
    checkingFormValues();
  };

  const onSubmit = (formData) => {
    SetLoading_flag(true);
    setIsSubmitted(true);
    let { body, name, subject } = formData;
    let save_obj = {};
    if (template_info) {
      save_obj = {
        id: template_info._id,
        template_data: {
          name: name,
          body: body,
          subject: subject,
        },
      };
    } else {
      save_obj = {
        name: name,
        body: body,
        subject: subject
      };
    }
    axios
      .post(base_url + "email_template/save_template",
        save_obj).then((res) => {
        SetLoading_flag(false);
        toast.success(res.data.message, { toastId: random_number });
      })
      .catch((err) => {
        SetLoading_flag(false);
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized" ) {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { id: random_number });
        }
      });
  };

  return (
    <>
    {loading_flag ? (
      <div className="mt-5">
       <img data-testid="atrium_loader"
         className="atrium_loader_custom"
         src="/images/atrium_loader.gif"
         alt="Loader"
       />
      </div>
    ) : (
      <div className="bg-body container">
        <div>
          <span className="SLA_Main_heading" data-testid="main_heading">Create Email Template</span>
        </div>
        <form>
          <div className="row mt-5">
            <div className="col-lg-2">
              <label htmlFor="roletype" className="email_labels" data-testid="template_label">
                Template Name
              </label>
            </div>
            <div className="col-lg-7">
              <input
                id="name"
                name="name"
                data-testid="template_name"
                type="text"
                className="form-control"
                value={template_name}
                {...register("name", createTemplateValidations.name)}
                onChange={handleChange}
              />
               {errors.name?.message && (
                <span style={{ color: "red" }}>{errors.name?.message}</span>
              )}
            </div>
          </div>
          <br />
          <div className="row">
            <div className="col-lg-2">
              <label htmlFor="subject" className="email_labels" data-testid="subject_label">
                Subject
              </label>
            </div>
            <div className="col-lg-7">
              <input
                id="subject"
                name="subject"
                type="text"
                data-testid="subject_name"
                value={subject_name}
                className="form-control"
                {...register("subject", createTemplateValidations.subject)}
                onChange={handleChange}
              />
              {errors.subject?.message && (
                <span style={{ color: "red" }}>{errors.subject?.message}</span>
              )}
            </div>
            <br />
          </div>

          <div className="mt-4">
            <label htmlFor="" className="email_labels" data-testid="template_body_label">
              Body{" "}
            </label>
            <div className="d-none" data-testid="template_body">{content}</div>
            <Editor
              apiKey="1jl4n1cka72zftyk5y5sq27ioibmxki6jukqbch7ysalixae"
              cloudChannel="5-stable"
              disabled={false}
              initialValue={template_info ? template_info.body : "Text"}
              inline={false}
              onEditorChange={handleEditorChange}
              plugins=""
              tagName="div"
              textareaName="body"
              toolbar="undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat forecolor backcolor"
              value={content}
              name="content"
              outputFormat="html"
              init={{
                height: 500,
                language: "en",
                plugins: [
                  "advlist autolink lists link image charmap print preview anchor",
                  "searchreplace visualblocks code fullscreen",
                  "insertdatetime media table paste code help wordcount",
                ],
                convert_urls: false
              }}
              aria-required={true}
            />

            {content == "" && (
              <span style={{ color: "red" }}>{"Email Body is required"}</span>
            )}

            <div className="d-block text-center">
              <div>
                <button data-testid="save_button"
                  className="btn my_btn_cls mt-3 col-lg-1 col-md-2 float-end col-sm-3"
                  onClick={handleSubmit(onSubmit)}
                  disabled={
                    isSubmitting || content == "" || isSubmitted
                  }
                >
                  Save{ }
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
    }
    </>
  );
};

export default CreateTemplate;
