import React from "react";
import { useHistory } from "react-router-dom";
import { validEmailMessage } from "../utilities";

const InvalidFreelessonLink = () => {
  let history =useHistory();
  let invalidErrorMessage = history?.location?.state?.errorMessage
  return (
    <>
      <div className="container-fluid inactivebdlink_main">
        <div className="d-flex flex-column justify-content-center align-items-center vh-100 customgap_bdinvalid">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="130"
              height="130"
              viewBox="0 0 171 171"
              fill="none"
            >
              <circle cx="85.5" cy="85.5" r="85.5" fill="#D9D9D9" />
            </svg>
          </div>
          <div className="inactivebdlink_description">
            <span>
            {invalidErrorMessage === validEmailMessage ? "It looks like there might be a mistake with the email address you entered. Please check it and try again." : 
              "Hi, looks like you may have clicked on an invalid or inactive link. Contact Us for any further queries."}
            </span>
          </div>
          <div className="inactivebdlink_contactus">
            <span>
              <a href="mailto:execed@isb.edu">Contact Us</a>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvalidFreelessonLink;
