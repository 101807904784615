import React, { useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import LandNavbar from './MainPageComponents/LandingPage/LandNavbar';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {ToastContainer} from 'react-toastify';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { admin_maintenance, user_maintenance } from "./utilities";
import Maintenance from './MainPageComponents/Maintenance'
const Main = () => {
  const currentPath = window.location.pathname;
  return (
    <>
      {
      //  ( all_maintenance ? (<Maintenance />) :
      //  (user_maintenance&& currentPath === '/')?(<Maintenance />):
      //  (admin_maintenance&& currentPath === '/adminlogin')? (<Maintenance />) :
        (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <BrowserRouter >
              {/* <LandNavbar isLoggedIn={isLoggedIn} onLogout={handleLogout}/> */}
              <LandNavbar />
              {/* <App onLogin={handleLogin}/> */}
              <ToastContainer autoClose={currentPath=="/onlineapplication" || currentPath=="/freelessonlogin"? "":4000} closeButton={false} className="foo" position="top-center" />
              <App />
            </BrowserRouter>
          </MuiPickersUtilsProvider>
        )
      }
    </>
  );
};
ReactDOM.render(<Main />, document.getElementById("root"));