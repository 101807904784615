import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url, api_error_message, random_number, date_formats } from "../../utilities";
import { toast } from "react-toastify";
import axios from "../../axios-interceptor";
import Dropzone from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from 'react-select';

import { Modal } from "react-bootstrap";
const UpdateHeroImage = () => {
  const history = useHistory();
  const location = useLocation();
  let [programmes, setProgrammes] = useState([]);
  let [programme_id, setProgrammeId] = useState();
  let [course_id, setCourseId] = useState();
  let [module_id, setModuleId] = useState();
  let [loader, setLoader] = useState(false);
  let [showModal, setShowModal] = useState(false);
  let [loader_flag, setLoaderFlag] = useState(false);
  let [file_ext, setFileExt] = useState();
  let [file_size, setFileSize] = useState();
  let [progress, setProgress] = useState(0);
  let [file_name, setFileName] = useState();
  let [lesson_id, setLessonId] = useState();
  let [courses, setCourses] = useState([]);
  let [file, setFile] = useState();
  let [modules, setModules] = useState([]);
  let [lessons, setLessons] = useState([]);
  const [flag, setFlag] = useState();
  let [free_lesson, setFreeLesson] = useState(false);
  const [image, setImage] = useState(null)
  let items_data = location.state.item;
  let [content,setContent] =useState('')
  let [save_button,setSaveButton] =useState(true)
  useEffect(() => {
    axios
      .post(base_url + "get_programes_for_admin")
      .then((res) => {
        setProgrammes(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
    if (items_data != "") {
      getLearningTrack(items_data.program_id);
      getModules(items_data.course_id);
      setModuleId(items_data.module_id);
      setLessonId(items_data.lesson_id);
      if (
        items_data.program_id &&
        items_data.course_id &&
        items_data.module_id &&
        items_data.lesson_id
      ) {
        setSaveButton(false)
        setLoaderFlag(true);
      }
      axios
        .post(base_url + "lessons/get_lessons_by_moduleid", {
          module_id: items_data.module_id,
        })
        .then((res) => {
          setLessons(res.data.data);
          res.data.data.map((item) => {
            if (item.lesson_id == items_data.lesson_id) {
              item.is_free_lesson == 0
                ? setFreeLesson(false)
                : setFreeLesson(true);
            }
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText=='Unauthorized') {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    }
  }, []);
  const onDrop = (file) => {
    const imageFiles = file.filter((item) => item.type.startsWith("image/"));
    if (file && file[0]) {
      setContent("")
      setImage(URL.createObjectURL(file[0]));
    }
    if (imageFiles.length != 0) {
      setFile(file[0]);
      setFileName(file[0].name);
    } else {
      toast.error("Please select any images", { toastId: random_number });
    }
    // if((programme_id && course_id && module_id && lesson_id) && free_lesson){
    //   file ?setLoaderFlag(true):setLoaderFlag(false)
    // }else{
    //   setLoaderFlag(false)
    // }
  };
  const updateImage = () => {
    let size = file?.size / (1024 * 1024);
    let size_flag;
    if(free_lesson){
      if (file) {
        size_flag = size < 5 ? true : false;
      } else {
        size_flag = 'nofile'
      }
    }else{
      if (file) {
        size_flag = size < 5 ? true : false;
      } else {
        size_flag = true;
      }
    }
    // console.log('size_flag',size_flag)
    setFileSize(file?.size / (1024 * 1024)); // Convert file size to MB

    const name = file?.name;
    const ext = name?.split(".")[1];
    setFileExt(ext?.toLowerCase());
    const url = base_url + "lessons/update_free_lesson_hero_image_url";
    if (size_flag== true) {
      let formData = new FormData();
      formData.append("files", file ? file : "");
      formData.append("course_id", course_id);
      formData.append("module_id", module_id);
      formData.append("lesson_id", lesson_id);
      formData.append("is_free_lesson", free_lesson ? 1 : 0);
      setLoader(true);
      axios
        .post(url, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
            file
              ? setProgress(Math.round((100 * data.loaded) / data.total))
              : setProgress(0);
            if (Math.round((100 * data.loaded) / data.total) == 100) {
              setLoaderFlag(true);
            }
          },
        })
        .then((response) => {
          if (response.data.status == 200) {
            toast.success(response.data.message, { toastId: random_number });
            setLoaderFlag(false);
            setLoader(false);
            setProgress(0);
            setFile("");
            // setProgrammeId("");
            // setCourseId("");
            // setModuleId("");
            // setLessonId("");
            // setFreeLesson("");
          }
        })
        .catch((err) => {
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized" ) {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
            console.log(err);
          setLoaderFlag(false);
          setLoader(false);
          setProgress(0);
          setFile("");
          } else {
            console.log(err);
          setLoaderFlag(false);
          setLoader(false);
          setProgress(0);
          setFile("");
            toast.warn(api_error_message, { toastId: random_number });
          }
          
          // setProgrammeId("");
          // setCourseId("");
          // setModuleId("");
          // setLessonId("");
          // setFreeLesson("");
        });
    } else if(size_flag == 'nofile'){
      setContent("Please Upload Image")
    }
     else {
      setLoaderFlag(false);
      toast.error("File size is larger than 5MB", { toastId: random_number });
    }
  };
  const getLearningTrack = (program_id) => {
    axios
      .post(base_url + "get_program_data_id", {
        program_id: program_id,
      })
      .then((res) => {
        setCourses(res.data.data.courses);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized" ) {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
    setProgrammeId(program_id);
    setCourseId("");
    setModuleId("");
    setLessonId("");
  };
  const getModules = (course_id) => {
    setCourseId(course_id);
    setModuleId("");
    setLessonId("");
    axios
      .post(base_url + "get_course_modules", { course_id: course_id })
      .then((res) => {
        setModules(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText=='Unauthorized') {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  };
  const getLessons = (module_id) => {
    setModuleId(module_id);
    setLessonId("");
    axios
      .post(base_url + "lessons/get_lessons_by_moduleid", {
        module_id: module_id,
      })
      .then((res) => {
        setLessons(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText=='Unauthorized') {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  };
  const getLessonId = (val) => {
    setLessonId(val);
    setLoaderFlag(true);
    setSaveButton(false)
    let lessons_data = [...lessons];
    lessons_data.map((item) => {
      if (item.lesson_id == val) {
        item.is_free_lesson == 0 ? setFreeLesson(false) : setFreeLesson(true);
      }
    });
  };
  const changeCheckbox = (val) => {
    // if(programme_id && course_id && module_id && lesson_id){
    //   val == true ?setLoaderFlag(true):setLoaderFlag(false)
    // }
    // val == true ? setLoaderFlag(true) :  setLoaderFlag(false);
    setFreeLesson(val);
    if(!val){
      setFile('')
    }
  };
  const deleteFile = () => {
    setShowModal(true);
  };
  const clearData = () => {
    setShowModal(false);
    if(file){
      setLoader(false);
      setProgress(0);
      setFile("");
    }
   
    // setProgrammeId("");
    // setCourseId("");
    // setModuleId("");
    // setLessonId("");
    // setFreeLesson("");
    // setProgrammes([])
    // setCourses([])
    // setModules([])
    // setLessons([])
  };
  const selectedProgrammeLabel = programmes.find((app) => app.programme_id === programme_id);
const formattedSelectedProgrammeLabel = selectedProgrammeLabel
  ? `${selectedProgrammeLabel.learning_track_name} - ${date_formats.human_date_format(selectedProgrammeLabel.start_date)}`
  : ' Select Programme Name  ';
  const handleClose = () => setShowModal(false);
  
  const handleChange = (selectedOption) => {
    setCourseId(selectedOption.value);
    getModules(selectedOption.value);
  };
  const handleChangeModule = (selectedOption) => {
    setModuleId(selectedOption.value);
    getLessons(selectedOption.value);
  };
  const handleChangeLesson  = (selectedOption) => {
    setLessonId(selectedOption.value);
    getLessonId(selectedOption.value);
  };

  const selectOptionsCourse = courses.map((app) => ({
    value: app.course_id,
    label: app.course_title,
  }));
  
  const selectOptionsModule = modules.map((app) => ({
    value: app._id,
    label: app.module_title,
  }));
  const selectOptionsLesson = lessons.map((app) => ({
    value: app.lesson_id,
    label: app.title,
  }));
  return (
    <>
      <div style={{ paddingBottom: "45px" }}>
        <div className="d-flex justify-content-between">
        <div className="">
              <span data-testid="Main_Heading" className="heading-span">Free Lesson Image </span>
            </div>
          
          <div>
           
          </div>
        </div>
        <div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <lable data-testid="Programme_Label" className="lh-lg">Programme Name</lable>
              <Select
                id="inputState"
                placeholder="Select Programme Name"
                className="hero_image_input"
                value={{ value: programme_id, label: formattedSelectedProgrammeLabel }}
                options={programmes.map((app) => ({
                  value: app.programme_id,
                  label: `${app.learning_track_name} - ${date_formats.human_date_format(app.start_date)}`,
                }))}
                onChange={(selectedOption) => getLearningTrack(selectedOption.value)}
                classNamePrefix="react-select-Programme"
              />
               <select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Programme_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    getLearningTrack(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Course</option>
                  {programmes.map((item,index) => {
                     return <option  key={index} value={item.programme_id} >{`${item.learning_track_name} - ${date_formats.human_date_format(item.start_date)}`}</option>;
                  })}
                </select>
            </div>
            <div className="col-lg-6">
              <lable data-testid="Course_Label" className="lh-lg">Course</lable>
              <Select
                    id="inputState"
                    className="hero_image_input"
                    value={selectOptionsCourse.find((option) => option.value === course_id)}
                    options={selectOptionsCourse}
                    placeholder="Select Course"
                    onChange={handleChange}
                    isSearchable // Enable search functionality
                    classNamePrefix="react-select-Programme"
                  />
<select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Course_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    handleChange(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Course</option>
                  {selectOptionsCourse.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-lg-6">
              <lable data-testid="Module_Label" className="lh-lg">Modules</lable>
               <Select
            id="inputState"
            className="hero_image_input"
            options={selectOptionsModule}
            placeholder="Select Module"
            value={selectOptionsModule.find((option) => option.value === module_id)}
            onChange={handleChangeModule}
            isSearchable // Enable search functionality
            classNamePrefix="react-select-Programme"
          />
          <select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Module_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    handleChangeModule(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Course</option>
                  {selectOptionsModule.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
            </div>
            <div className="col-lg-6">
              <lable data-testid="Lesson_Label" className="lh-lg">Lessons</lable>
              {/* <select
                id="inputState"
                className="hero_image_input"
                value={lesson_id}
                onChange={(e) => {
                  getLessonId(e.target.value);
                }}
              >
                <option value="" disabled selected hidden>
                  Select Lesson
                </option>
                {lessons.map((app) => {
                  return (
                    <option key={app.course_id} value={app.lesson_id}>
                      {app.title}
                    </option>
                  );
                  {
                  }
                })}
              </select> */}
              {/* <Select
            id="inputState"
            className="hero_image_input"
            options={selectOptionsLesson}
            placeholder="Select Lesson"
            value={selectOptionsLesson.find((option) => option.value === lesson_id)}
            onChange={handleChangeLesson}
            isSearchable // Enable search functionality
          /> */}
           <Select
            id="inputState"
            className="hero_image_input"
            options={selectOptionsLesson}
            placeholder="Select Lesson"
            value={selectOptionsLesson.find((option) => option.value === lesson_id)}
            onChange={handleChangeLesson}
            isSearchable // Enable search functionality
            classNamePrefix="react-select-Programme"
          />
          <select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Lesson_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    handleChangeLesson(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Course</option>
                  {selectOptionsLesson.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
            </div>
          </div>
          <div className="mt-3">
            <input
              data-testid = "Free_Lesson_Checkbox"
              type="checkbox"
              checked={free_lesson}
              onChange={(e) => changeCheckbox(e.target.checked)}
            />{" "}
            &nbsp; Make Free Lesson
          </div>
         {free_lesson && <div data-testid= "Drop_Zone" className="row mt-4" >
            <Dropzone data-testid= "Drop_Zone" onDrop={onDrop} multiple={false} accept={'image/*'}>
              {({ getRootProps, getInputProps }) => (
                <section className={`${file?'disable-delete':''}`}>
                  <div
                    id="hero_image_dropzone"
                    // className={`${file?'disable-delete':''}k`}
                    {...getRootProps(
                      !flag ? { className: "dropzone " } : { className: "" }
                    )}
                  >
                    <div data-testid="Svg_Image">
                      <svg
                        width="62"
                        height="41"
                        viewBox="0 0 62 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M46.8598 11.5387C46.0201 11.5387 45.1987 11.6148 44.3986 11.7456C42.4028 5.21986 36.2208 0.467773 28.898 0.467773C19.9567 0.467773 12.7129 7.55027 12.7129 16.2847C12.7129 17.0636 12.7738 17.8302 12.8863 18.5847C12.4533 18.5311 12.0175 18.5037 11.5812 18.5026C5.50265 18.5026 0.577148 23.3155 0.577148 29.2572C0.577148 35.1988 5.50265 40.0178 11.5812 40.0178H24.9156V27.8486H17.3099L31.0002 12.637L44.6906 27.8486H37.0849V40.0178H46.8598C54.9006 40.0178 61.4233 33.6411 61.4233 25.7798C61.4233 17.9124 54.9006 11.5387 46.8598 11.5387Z"
                          fill={`${file?'#b4d4de':"#057092"}`}
                        />
                      </svg>
                      <br></br>
                      <span data-testid='Upload_Image'>Upload Image</span>
                      <input data-testid="File_Input" {...getInputProps()} />
                    </div>
                  </div>
                </section>
              )}
            </Dropzone>
           {file && !loader && <div lassName="row mt-4">
            <img className="w-100" data-testid="Preview_Image" alt="preview image" src={image}/>
            </div>}
            <div data-testid= "Progress_bar" className="row" style={{ padding: "0px 200px" }}>
              {loader && (
                <div className="row progress_div">
                  <div className="col-1">
                    <img src="/images/sla_file_upload.svg"></img>
                  </div>
                  <div className="col-11 text-left">
                    <span>
                      {file_name} {progress == 100 ? " Uploaded" : " Uploading"}
                    </span>

                    {progress < 100 ? (
                      <span className="float-end">{progress}% </span>
                    ) : (
                      <img className="float-end" src="/images/tick.svg"></img>
                    )}
                    {progress < 100 ? (
                      <ProgressBar now={progress} />
                    ) : (
                      <>
                        <br />
                        <span>{Math.round(file_size * 1000)}KB</span>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>}
          <div>
            <span className="red">{content}</span>
          </div>
          <div className="float-start">
            <button
              data-testid = "Save_Button"
              className="btn my_btn_cls submit_button btn-block"
              disabled={save_button}
              onClick={updateImage}
            >
              Save
            </button>
          </div>
        </div>
       {file && <div className={`float-end mt-3 ${file?'blue_text cursor-pointer':'disable-delete'}`} data-testid="Delete_Button" onClick={deleteFile}>
            Delete
            </div>}
      </div>
      {showModal && (
        <Modal 
          data-testid="Delete_Modal"
          show={showModal}
          onHide={handleClose}
          centered
          id="hero_image_modal"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <Modal.Body>
            <div data-testid="Modal_Text" className="text-center">
              <p className="modal_text fw-semibold">
                Are you sure, you want to Delete the uploaded file?
              </p>
            </div>
            <div data-testid="Cancel_Button" className="text-center pb-2 redo_btn_container">
              <button
                className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4"
                onClick={handleClose}
                data-testid="Cancel_Button2"
              >
                Cancel
              </button>
              &nbsp;&nbsp;
              <button data-testid="Delete_Modal_Button"
                className="btn my_btn_cls mt-0 my_share_btn m-0 px-4" style={{padding:"11px"}}
                onClick={clearData}
              >
                Delete
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default UpdateHeroImage;
