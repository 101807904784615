
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { admin_maintenance, admin_maintenance_type, user_maintenance, user_maintenance_type } from '../utilities';
import Maintenance from '../MainPageComponents/Maintenance';

const withMaintenanceCheck = (WrappedComponent, userType) => {
  const WithMaintenanceCheck = () => {
    const history = useHistory();
    const [isMaintenance, setIsMaintenance] = useState(false);

    useEffect(() => {
      // Replace this with your logic to check the maintenance status
      let maintenanceFlag = false;

      if (userType === user_maintenance_type && user_maintenance) {
        maintenanceFlag = true;
      } else if (userType === admin_maintenance_type && admin_maintenance) {
        maintenanceFlag = true;
      } 
      if(maintenanceFlag)
      {
        localStorage.clear();
        document.getElementById("top-nav-bar").style.display="none"
      }  

      setIsMaintenance(maintenanceFlag);
    }, [userType]);

    return isMaintenance ? <Maintenance /> : <WrappedComponent />;
  };

  return WithMaintenanceCheck;
};

export default withMaintenanceCheck;
