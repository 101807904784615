import axios from "../../axios-interceptor";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';

import {
  base_url,
  random_number,
  api_error_message,
  downloadPdf,
  date_formats,
  unassigned_role
} from "../../utilities";
import { useLocation, useHistory } from "react-router-dom";
import { KeyboardDateTimePicker } from "@material-ui/pickers";
import { ModalFooter, Table } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import moment from "moment";
export const ProgramSlaNotification = (props) => {
  const history = useHistory();
  const location = useLocation();
  let [selected, setSelected] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  let [selected_array, setSelectedArray] = useState([]);
  let [assigned_roles, setAssignedRoles] = useState([]);
  let [group_data, setGroupsData] = useState([]);
  let [loading_flag, SetLoading_flag] = useState(false);
  let [show_groups, setShowGroups] = useState(false);
  let [show_roles, setShowRoles] = useState(false);
  let [show_all, setShowAll] = useState(false);
  const [content, setContent] = useState("hello");
  let [subject, setSubject] = useState("");
  let [template_id, setTemplateId] = useState('');
  let [program_id, setProgramId] = useState();
  let [handout_url, setHandoutUrl] = useState(null);
  let [file, setFile] = useState();
  let [url, setUrl] = useState(null);
  let [schedule_send, setScheduleSend] = useState(false);
  let [schedule_message,setScheduleMessage] =useState(false)
  let [notification_by, setNotificationBy] = useState();
  const [selectedDate, setSelectedDate] = useState(new Date());
  let [schedule_date, setScheduleDate] = useState(null);
  let [showModal, setShowModal] = useState(false);
  let [email, setEmail] = useState();
  const [success_emails, SetSuccessEmails] = useState([]);
  const [templates, SetAllTemplates] = useState([]);
  let [role_checked,setRoleChecked] = useState(false)
  let [group_checked,setGroupChecked] = useState(false)
  let [data,setData] = useState()
  let [onloading_flag, SetOnLoading_flag] = useState(true);
  let names
  const schedule_info = location.state?.schedule_info
    ? location.state?.schedule_info
    : null;
    const fileInputRef = useRef(null);

    const handleFileSelect = () => {
      fileInputRef?.current?.click();
    };
  
  
  useEffect(() => {
    axios
      .post(base_url + "sla/get_role_members", {
        sla_id: localStorage.getItem("sla_id"),
      })
      .then((res) => {
        setData(res.data);
        res.data.data.map((item) => {
          if(item.role_id == null && item.role_name == unassigned_role){
            item.role_id = unassigned_role
          }
        })
        if (schedule_info) {
          setSubject(schedule_info.subject);
          setEmail(schedule_info.email_body);
          setTemplateId(schedule_info.email_template_id)
          setRoleChecked(schedule_info?.sla_notification_by == "Role"?true:false)
          setGroupChecked(schedule_info?.sla_notification_by == "Role"?false:true)
          setNotificationBy(schedule_info.sla_notification_by)
          setScheduleSend(schedule_info.schedule_send_flag == 1 ? true : false);
          setScheduleMessage(schedule_info.schedule_send_flag == 1 ? true : false)
          setScheduleDate(
            schedule_info.schedule_send_flag == 1 ? date_formats.human_date_time_format(schedule_info.schedule_time)
          :null);
          setHandoutUrl(
            schedule_info?.sla_attachment_url? schedule_info?.sla_attachment_url?.split("/")
            .pop()
            .replace(/[^a-zA-Z._]/g, ""):null
          );
          setContent(schedule_info.email_body)
          // setSelectedArray(schedule_info.emails)
          setSelected(schedule_info.sla_notification_by_ids? schedule_info.sla_notification_by_ids :[])
          res.data.data.map((item) => {
            let roleMatch = false;
            schedule_info.sla_notification_by_ids.map((val) => {
              if (val == item.role_id) {
                setShowRoles(true);
                roleMatch = true;
              }
            });
            if (roleMatch) {
              item.color = "#D7E0E8";
              item.checked = true;
            } else {
              item.color = "#F9FAFA";
              item.checked = false;
            }
          });
        } else {
          res.data.data.map((item) => {
            return (item["color"] = "#F9FAFA"), (item["checked"] = false);
          });
        }
        const sortedRoles = res.data.data.sort((a, b) => {
          if (a.role_name < b.role_name) {
            return -1;
          }
          if (a.role_name > b.role_name) {
            return 1;
          }
          return 0;
        });
        setAssignedRoles(sortedRoles);
        setProgramId(res.data.program_id);
      })
      .catch((err) => {
        console.log(err);
        SetOnLoading_flag(false)
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
    axios
      .post(base_url + "sla/get_group_members", {
        sla_id: localStorage.getItem("sla_id"),
      })
      .then((res) => {
        if (schedule_info) {
          setSubject(schedule_info.subject);
          setEmail(schedule_info.email_body);
          setNotificationBy(schedule_info.sla_notification_by)
          setSelected(schedule_info.sla_notification_by_ids? schedule_info.sla_notification_by_ids :[])
          setScheduleSend(schedule_info.schedule_send_flag == 1 ? true : false);
          setScheduleMessage(schedule_info.schedule_send_flag == 1 ? true : false)
          setScheduleDate(
            schedule_info.schedule_send_flag == 1 ? date_formats.human_date_time_format(schedule_info.schedule_time)
          :null);
          setHandoutUrl(
            schedule_info?.sla_attachment_url? schedule_info?.sla_attachment_url?.split("/")
            .pop()
            .replace(/[^a-zA-Z._]/g, ""):null
          );
          setContent(schedule_info.email_body)
          res.data.data.forEach((item) => {
            let selectedGroup = false; // Flag to track if any group ID is selected for this item
            
            schedule_info.sla_notification_by_ids.forEach((val) => {
              if (val == item.group_id) {
                selectedGroup = true; // At least one matching group ID found
                item.group_members?.forEach((groupMember) => {
                  let data = {
                    name: groupMember.first_name,
                    email: groupMember.email,
                    id: groupMember.user_id,
                  };
                  setSelectedArray((current) => [...current, data]);
                  setShowGroups(true);
                });
              }
            });
            if (selectedGroup) {
              item.color = "#D7E0E8"; 
              item.checked = true;// Update color if at least one group ID matched
            } else {
              item.color = "#F9FAFA";
              item.checked = false; // Update color if no group ID matched
            }
          });
        } else {
          res.data.data.map((item) => {
            return (item["color"] = "#F9FAFA"), (item["checked"] = false);
          });
        }
        const sortedGroups = res.data.data.sort((a, b) => {
          const numberA = parseInt(a.group_name.split('-')[1]);
          const numberB = parseInt(b.group_name.split('-')[1]);
      
          return numberA - numberB;
        });
        setGroupsData(sortedGroups);
      })
      .catch((err) => {
        console.log(err);
        SetOnLoading_flag(false)
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
      axios.post(base_url + 'email_template/get_templates', {
      }).then(res => {
        SetAllTemplates(res.data.data);
        if(schedule_info && schedule_info.email_template_id){
          const schedule_details = res.data.data.filter(obj => obj._id == schedule_info?.email_template_id);
          setSelectedOption({"value":schedule_details[0]._id,"label":schedule_details[0].name})
        }
        SetOnLoading_flag(false)
      }).catch(err => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
  }, []);
  
  const hideModal = () => setShowModal(false);
  const handleClose = () => {
    setShowModal(false);
    if (schedule_date == null) {
      setScheduleDate(null);
      setScheduleSend(false);
    }
  };
  const saveSubject = (e) => {
    setSubject(e.target.value);
  };
  const handleEditorChange = (content) => {
    
      setContent(content);
  
   
    // setValue("body", content);
  };
  const getSelectedRole = (member, idx) => {
    // if(schedule_info?.email_body){
    //   setContent(schedule_info.email_body)
    // }
    let roles = [...assigned_roles];
    setSelectedArray([]);
    setSelected([
      roles[idx].role_id == unassigned_role ? unassigned_role : roles[idx].role_id,
    ]);
    if(schedule_info){
      setHandoutUrl(
        schedule_info?.sla_attachment_url? schedule_info?.sla_attachment_url?.split("/")
        .pop()
        .replace(/[^a-zA-Z._]/g, ""):null
      );
    }else{
      setHandoutUrl(
        roles[idx].role_id == unassigned_role ? null :  (roles[idx].members[0]?.url && roles[idx].members[0]?.url !='') ?roles[idx].role_name + "_handout.pdf":null
      );
    }
    

   
    setUrl(roles[idx].role_id == unassigned_role ? null : roles[idx].members[0].url);
    roles.map((ele, index) => {
      if (idx == index) {
        ele.checked = true;
        ele.color = "#D7E0E8";
        roles[idx].members?.map((item) => {
          let data = { name: item.first_name, email: item.email };
          setSelectedArray((current) => [...current, data]);
        });
      } else {
       
        ele.checked = false;
        ele.color = "#F9FAFA";
      }
    });
    setAssignedRoles(roles);
    setNotificationBy("Role");
  };
  
  const getSelectedGroup = (member, e, idx) => {
    let groups = [...group_data];
   
    if (e.target.checked) {
      setSelected((current) => [...current, groups[idx].group_id]);
      groups[idx].group_members?.map((item) => {
        let data = {
          name: item.first_name,
          email: item.email,
          id: item.user_id,
        };
        setSelectedArray((current) => [...current, data]);
      });
    } else {
      setSelected((current) =>
        current.filter(function (item) {
          return item !== groups[idx].group_id;
        })
      );
          groups[idx].group_members?.map((item) => {
            setSelectedArray((current) =>
              current.filter(function (val) {
                return val.id !== item.user_id;
              })
            );
      })
    }
    if(schedule_info){
      setHandoutUrl(
        schedule_info?.sla_attachment_url? schedule_info?.sla_attachment_url?.split("/")
        .pop()
        .replace(/[^a-zA-Z._]/g, ""):null
      );
    }else{
      setHandoutUrl(null);
      setUrl(null)
    }
   
    groups.map((ele, index) => {
      if (idx == index) {
        if (e.target.checked) {
          ele.checked = true;
          ele.color = "#D7E0E8";
        } else {
          ele.checked = false;
          ele.color = "#F9FAFA";
        }
      }
    });
    setGroupsData(groups);
    setNotificationBy("Group");
  };
  const sendMail = () => {
    if (selected.length != 0 || notification_by == "All") {
      SetLoading_flag(true)
      let formData = new FormData();
      if (file) {
        formData.append("files", file);
      }else{
        formData.append("sla_attachment_url",  "");
      }
      var form_emails = "";
      if (selected_array.length != 0) {
        for (var i = 0; i < selected_array.length; i++) {
          form_emails += `{"name":"${selected_array[i].name}","email":"${selected_array[i].email}"};`;
        }
        formData.append(
          "emails",
          form_emails ? form_emails.replace(/;(?=[^;]*$)/, "") : {}
        );
      }
      formData.append("sla_id", localStorage.getItem("sla_id"));
      formData.append("program_id", program_id);
      formData.append("email_template_id", template_id);
      formData.append("subject", subject);
      formData.append("email_body", content);
      formData.append("sla_notification_by", notification_by);
      if (notification_by != "All") {
        formData.append("sla_notification_by_ids", selected);
      }
      formData.append("schedule_send_flag", schedule_send ? 1 : 0);
      if (schedule_send) {
        setScheduleMessage(true)
        formData.append("schedule_time", moment(schedule_date).format("x"));
      }else{
        setScheduleMessage(false)
      }
      if (url && !url.includes("blob")) {
        formData.append("sla_attachment_url", url.includes("blob") ? "" : url);
      }
      if(schedule_info){
        formData.append("scheduled_email_id",schedule_info._id)
      }
    //   for (var pair of formData.entries()) {
    //     console.log(pair[0]+ ', ' + pair[1]);
    // }
    // return false
      axios
        .post(base_url + "scheduled_emails/send_program_sla_emails", formData)
        .then((res) => {
          if (res.data.status == 200) {
            SetSuccessEmails(res.data.result.emails);
            SetLoading_flag(false)
            toast.success(res.data.message, { toastId: random_number });
          }
        })
        .catch((err) => {
          console.log(err);
          SetLoading_flag(false)
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    } else {
      toast.error("Please selected any of the roles/groups", {
        toastId: random_number,
      });
    }
  };
  const handleDetails = (id, name) => {
    setTemplateId(id)
  if (name == "template") {
      axios
        .post(base_url + "email_template/get_templates", {
          id: id,
        })
        .then((res) => {
           setContent(res.data.data[0].body);
          setSubject(res.data.data[0].subject);
          
        })
        .catch((err) => {
          SetLoading_flag(false);
          if (err.response?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { id: random_number });
          }
        });
    }
  }
  const openSchedule = (e) => {
    if (e.target.checked) {
      setShowModal(true);
      setScheduleSend(true);
    } else {
      setSelectedDate(new Date());
      setScheduleDate(null);
      setScheduleSend(false);
    }
  };
  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setHandoutUrl(file.name);
    setUrl(URL.createObjectURL(file));
  };
  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (
      date_formats.human_date_time_format(date) !=
      date_formats.human_date_time_format(selectedDate)
    ) {
      setScheduleDate(date_formats.human_date_time_format(date));
      setShowModal(false);
    } else {
      setScheduleDate(null);
      setShowModal(false);
    }
  };
const onChangeRole =() =>{
  setRoleChecked(!role_checked)
  setGroupChecked(role_checked)
  let groups = [...group_data]
  groups.map((item) => {
    return (item["color"] = "#F9FAFA"), (item["checked"] = false);
  });
  setGroupsData(groups)
  setHandoutUrl(null)
    setShowRoles(true);
    setShowGroups(false);
    setShowAll(false);
    setSelected([])
    setSelectedArray([])
   
}
const onChangeGroup = () =>{
  setGroupChecked(!group_checked)
  setRoleChecked(group_checked)
  let roles = [...assigned_roles]
  roles.map((item) => {
    return (item["color"] = "#F9FAFA"), (item["checked"] = false);
  });
  setHandoutUrl(null)
  setAssignedRoles(roles)
   setShowRoles(false);
    setShowGroups(true);
    setShowAll(false);
    setSelected([])
    setSelectedArray([])
}

  const options = templates.map((app) => ({
    value: app._id,
    label: app.name,
  }));

  const customFilterOption = (option, searchText) => {
    const optionLabel = option.label.toLowerCase();
    const searchLower = searchText.toLowerCase();
    return optionLabel.includes(searchLower);
  };
  return (
    <>
      {onloading_flag ? (
          <div>
            <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
          </div>
        ) : 
      <>
        <div className="bg-body container gap-4">
          <div className=" notification_heading">
            {data?.program_name} &gt; {data?.course_name} &gt;{" "}
            {data?.module_name}
          </div>

          <div className="row p-2 pt-1">
            <div className="col-4 pr-4">
              <div>
                <div className="mail-heading">Send Email by: </div>
                <label className="label_style">
                  <div className="option_choise">
                    <div className={`option_label mt-0`}>
                      <input
                        type="radio"
                        name="notification"
                        style={{ width: "30px", accentColor: "black" }}
                        onChange={onChangeRole}
                        checked={role_checked}
                      ></input>
                      &nbsp;
                    </div>
                    <span className="mail-heading">Role</span>
                  </div>
                </label>
                <br />

                <label className="label_style">
                  <div className="option_choise">
                    <div className={`option_label mt-0`}>
                      <input
                        type="radio"
                        name="notification"
                        style={{ width: "30px", accentColor: "black" }}
                        onChange={onChangeGroup}
                        checked={group_checked}
                      ></input>
                      &nbsp;
                    </div>
                    <span className="mail-heading">Groups</span>
                  </div>
                </label>
                <br />
                {/* <label className="label_style">
                  <div className="option_choise">
                    <div className={`option_label mt-0`}>
                      <input
                        type="radio"
                        name="notification"
                        style={{ width: "30px", accentColor: "black" }}
                        onChange={() => {
                          setShowRoles(false);
                          setShowGroups(false);
                          setShowAll(true);
                          setNotificationBy("All");
                        }}
                      ></input>
                      &nbsp;
                    </div>
                    <span className="mail-heading">All</span>
                  </div>
                </label> */}
                <br />
              </div>
              {show_roles &&
                assigned_roles.map((item, index) => {
                  return (
                    <>
                      <div
                        id={`role_${index}`}
                        style={{ backgroundColor: item.color }}
                        className="assign_role_text roles_count_class d-flex justify-content-between"
                      >
                        <label className="label_style">
                          <div className="option_choise">
                            <div className={`option_label`}>
                              <input
                                type="radio"
                                name="roles"
                                checked={item.checked}
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  accentColor: "black",
                                }}
                                onChange={() =>
                                  getSelectedRole(item.members, index)
                                }
                              ></input>
                              &nbsp;
                            </div>
                            {item.role_name}
                          </div>
                        </label>
                      </div>
                      <br></br>
                    </>
                  );
                })}
              {show_groups &&
                group_data.map((item, index) => {
                  { names = item.group_members.map(user => user.first_name).join(', ')}

                  return (
                    <>
                      <div
                        id={`role_${index}`}
                        style={{ backgroundColor: item.color }}
                        className="assign_role_text roles_count_class d-flex justify-content-between"
                      >
                        <div className="form-group_checkbox m-0">
                          <input
                            type="checkbox"
                            id={index}
                            onChange={(e) =>
                              getSelectedGroup(item.members, e, index)
                            }
                            checked={item.checked}
                          />
                          <label className="row" htmlFor={index}>
                            <span className="col" style={{ marginTop: "8px" }}>
                              {item.group_name}
                            </span>
                          </label>
                          <span className="text-break group_member_names">
                            {names}
                          </span>
                        </div>

                        {/* <label className="label_style">
                          <div className="option_choise">
                            <div className={`option_label`}>
                              <input
                                type="radio"
                                name="groups"
                                style={{
                                  height: "30px",
                                  width: "30px",
                                  accentColor: "black",
                                }}
                                onChange={() =>
                                  getSelectedGroup(item.members, index)
                                }
                              ></input>
                              &nbsp;
                            </div>
                            {item.group_name}
                          </div>
                          <p className="text-break">
                            {item.group_members.map((ele) => {
                              return <>{ele.first_name},</>;
                            })}
                          </p>
                        </label> */}
                      </div>
                      <br></br>
                    </>
                  );
                })}
            </div>
            <div className="col-8 pl-4">
              {(show_groups || show_roles || show_all) && (
                <div className="">
                  <div className="row">
                    <div className="col-2">
                      <label htmlFor="subject">Subject :</label>
                    </div>
                    <div className="col-8 pr-5">
                      <input
                        id="subject"
                        name="subject"
                        type="text"
                        value={subject}
                        className="form-control"
                        onChange={saveSubject}
                      />
                      {subject == "" && (
                        <span style={{ color: "red" }}>
                          {"Email Subject is required"}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="row mt-2">
                  <div className="col-2">
                      <label htmlFor="subject">Template :</label>
                    </div>
                    <div className="col-8 pr-5">
                    <Select
                      value={selectedOption}
                      onChange={(selectedOption) => {
                        setSelectedOption(selectedOption);
                        handleDetails(selectedOption.value, 'template');
                      }}
                      filterOption={customFilterOption} // Custom filtering logic

                      options={options}
                      placeholder="Select the Template"
                    />
                  </div>
                  </div>
                  <div>
                    <label htmlFor="">Body :</label>
                    <Editor
                      apiKey="1jl4n1cka72zftyk5y5sq27ioibmxki6jukqbch7ysalixae"
                      cloudChannel="5-stable"
                      disabled={false}
                      initialValue={email ? email : "Text"}
                      inline={false}
                      onEditorChange={handleEditorChange}
                      plugins=""
                      tagName="div"
                      textareaName="body"
                      toolbar="undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat forecolor backcolor"
                      value={content}
                      outputFormat="html"
                      init={{
                        height: 250,
                        language: "en",
                        //menubar: false,
                        plugins: [
                          "advlist autolink lists link image charmap print preview anchor",
                          "searchreplace visualblocks code fullscreen",
                          "insertdatetime media table paste code help wordcount",
                        ],
                        convert_urls: false
                      }}
                      // Add aria-required attribute
                      aria-required={true}
                    />
                  </div>
                </div>
              )}
              {content == "" && (
                <span style={{ color: "red" }}>{"Email Body is required"}</span>
              )}
              <br />
              {(show_groups || show_roles || show_all) && (
                <>
                  <div className="col-8 pr-5 pl-0">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="additionalread"
                        className="form-check-input additionalread"
                        id="additionalread"
                        checked={schedule_date != null ? true : false}
                        onChange={(e) => openSchedule(e)}
                        // disabled={schedule_send}
                      />{" "}
                      Schedule send{" "}
                      {schedule_date != null && <span>| {schedule_date}</span>}
                    </div>
                  </div>
                  <br />
                  {handout_url == null ? (
                    <>
                      <div className="handout_input_class col-6 pl-0 ">
                      <div className="mb-0 p-1 pl-0 pr-0 cursor-pointer" onClick={handleFileSelect}>
                        Add Attachment
                      </div>
                        <input
                        ref={fileInputRef}
                          type="file"
                          id="browse"
                          name="browse"
                          className="d-none"
                          onChange={(e) => {handleFileInputChange(e);}}
                        />
                      </div>
                    
                      
                    </>
                  ) : (
                    <div className="col-8 pr-5 pl-0">
                      <div className=" handout_url_class">
                        <img src="/images/sla_file_upload.svg"></img> &nbsp;
                        <span
                          className="cursor-pointer text-underline"
                          onClick={() => downloadPdf(url)}
                        >
                          {handout_url}
                        </span>
                        <img
                          src="/images/close-icon.svg"
                          className="cursor-pointer float-end mt-2 mr-2"
                          onClick={() => {setHandoutUrl(null);setUrl(null)}}
                        ></img>
                      </div>
                    </div>
                  )}
                  <br />
                  {loading_flag ? (
        <div>
            <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
        </div>
    ) : 
                  <div className="col-8 pl-0">
                    <button
                      type="submit"
                      onClick={sendMail}
                      disabled={content == "" || subject == "" || loading_flag}
                      className="btn  my_btn_cls submit_button fw-bold my_certificate_btn ml-0 pl-3 pr-3"
                    >
                      {!schedule_send ? "Send Email" : "Schedule Email"}
                      {schedule_send}
                    </button>
                  </div>
  }
                </>
              )}
            </div>
          </div>
        </div>
        {
            <div className="col bg-body custm_shadow_cls p-4 m-1 row">
              <h4 className="fw-bold mb-3">Mail Status</h4>
              <div className="">
                <div className="row pm_course_element bg-isbBlue text-white">
                <div className="col-lg-4 fw-bold  pt-2 pb-2">
                  Name
                  </div>
                  <div className="col-lg-4 fw-bold  pt-2 pb-2">
                    Email
                  </div>
                  {/* <div className="col-lg-4 fw-bold  pt-2 pb-2"> */}
                    {/* Date & Time
                  </div> */}
                  <div className="col-lg-2 fw-bold pt-2 pb-2">
                    Status
                  </div>
                </div>
                {success_emails?.map((item) => {
                  return (
                    <>
                      <div className="row pt-3">
                      <div className="col-lg-4"> {item.name}</div>
                        <div className="col-lg-4"> {item.email}</div>
                        {/* <div className="col-lg-4 fw-bold ">{date_formats.human_date_time_format(item.schedule_time)}</div> */}
                        <div className="col-lg-2 fw-bold text-success">
                        {!schedule_message ? "Success" : "Scheduled"}
                        </div>
                      </div>
                    </>
                  );
                })}
              </div>
            </div>
          }
        {showModal && (
          <Modal
            show={showModal}
            onHide={handleClose}
            id="schedule-modal"
            centered
            ClassName="role_modal"
            style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
          >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className="pt-0">
              <div className="text-center mr-3">
                <h4 className="text-left schedule-send">SLA Schedule Send</h4>
                <p className="text-black text-left">India Standard Time</p>
              </div>
              <div className="pb-3">
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => {
                    setScheduleDate(
                      date_formats.human_date_time_format(
                        new Date(
                          new Date().getTime() + 24 * 60 * 60 * 1000
                        ).setHours(18, 0, 0, 0)
                      )
                    );
                    hideModal();
                  }}
                >
                  <div className="float-start">D-1 before lunch</div>
                  <div className="float-end">
                    {date_formats.human_date_time_format(
                      new Date(
                        new Date().getTime() + 24 * 60 * 60 * 1000
                      ).setHours(18, 0, 0, 0)
                    )}
                  </div>
                </div>
                <br />
                <div
                  className="p-2 cursor-pointer"
                  onClick={() => {
                    setScheduleDate(
                      date_formats.human_date_time_format(
                        new Date(
                          new Date().getTime() + 48 * 60 * 60 * 1000
                        ).setHours(18, 0, 0, 0)
                      )
                    );
                    hideModal();
                  }}
                >
                  <div className="float-start">D-2 before lunch</div>
                  <div className="float-end">
                    {date_formats.human_date_time_format(
                      new Date(
                        new Date().getTime() + 48 * 60 * 60 * 1000
                      ).setHours(18, 0, 0, 0)
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className="pb-3">
                <KeyboardDateTimePicker
                inputProps={{
                  readOnly: true,
                }}
                  // onClose={handleClose}
                  disablePast={true}
                  format="MMM d, yyyy hh:mm a"
                  label="Pick date & time"
                  value={selectedDate}
                  onChange={(date) => handleDateChange(date)}
                />
              </div>
            </Modal.Footer>
          </Modal>
        )}
      </>
      }
    </>
  );
};
