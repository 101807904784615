import { useRef,useEffect,useLayoutEffect ,useState,screen} from "react";
export const ObjectiveComponent = (props) => {
  let data = props.data;
  let purpose = props.purpose;
  const objective_Component=useRef(null)
 useLayoutEffect(() => {
   props.i==0?
  setTimeout(() => {
      window.scrollTo({
        top: window.scrollY,
        left: 0,
        behavior: "smooth",
      });
    }, 300):
    setTimeout(() => {
        window.scrollTo({
          top: window.scrollY + objective_Component.current?.clientHeight,
          left: 0,
          behavior: "smooth",
        });
      }, 300)
 }, []);
  var mystyle = {
    backgroundImage: `url("${data.img}")`
  };
  useEffect(() => {
      const container = document.createElement('div');
      container.innerHTML = data.str_data; 
      const text = container.querySelectorAll('.summary_header_cls');
      const liElements = document.querySelectorAll('#objectives .summary_list_cls');
      const headerTexts = Array.from(text).map(element => element.textContent.trim())
      const liTexts = Array.from(liElements).map(li => li.textContent.trim());
      let combinedText = [...headerTexts,...liTexts].join('. ')
      localStorage.setItem('prev_title',data?.title_data)
      localStorage.setItem('prev_text',combinedText)
  }, []);
  return (
    <>
      <div ref={objective_Component} data-testid="ObjectiveComponent_content">
        <div className="container__head" >
          <figure className="container__head-image" style={mystyle}></figure>
        </div>
        <div className="image-content myContainerCls custm_shadow_cls bg-body">
          <h2>{data?.title_data}</h2>
          <div id="objectives" dangerouslySetInnerHTML={{ __html: data.str_data }} />
        </div>
      </div>
    </>
  )
}