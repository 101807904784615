import React, { useEffect, useState } from 'react';
import { api_error_message, base_url, date_formats, emi_payment, emi_status, full_payment, offline_payment, random_number } from '../../utilities';
import { toast } from 'react-toastify';
import { useParams, useHistory } from "react-router-dom";
import axios from 'axios';
import moment from 'moment';

const PaymentDashboard = () => {
    const history = useHistory();
  let [loading_flag, setLoading_flag]=useState(true)
    
const [paymentDetails, setPaymentDetails]= useState([])
useEffect(() => {
    if(window.location.pathname=="/paymentdashboard" && localStorage.user_id){
          axios.post(base_url+'program/emi/payment/details',{
            "user_id":localStorage.user_id
          })
          .then(res=>{
              setPaymentDetails(res.data.result)
              setLoading_flag(false)
          })
          .catch(err=>{
            if(err.response?.statusText=='Unauthorized'||err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
            //   history.push('/')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          })
    
    }else if(window.location.pathname=="/paymentdashboard" && !localStorage.user_id){
        localStorage.setItem("paymentdashboard", "true")
        history.push("/")
    }else{
        localStorage.setItem("paymentdashboard", "false")
    }

}, [ ])


for(let k=0; k<paymentDetails.length; k++){
    let eachpaymentDetails=paymentDetails[k];
    for (let i = 0; i < eachpaymentDetails.numberOfInstallmentsPaidList?.length; i++) { 
        const payment = eachpaymentDetails.numberOfInstallmentsPaidList[i]; 
        // Find the corresponding installment in installment_details
         const installmentIndex = eachpaymentDetails?.installment_details?.findIndex(installment => installment.payment_name === payment.payment_name); 
        // If found, add additional fields from payment to installment if (installmentIndex !== -1) { 
            eachpaymentDetails.installment_details[installmentIndex].paid_date = payment.paid_date; 
            eachpaymentDetails.installment_details[installmentIndex].paid_amount = payment.paid_amount; 
            eachpaymentDetails.installment_details[installmentIndex].razorpay_order_id = payment.razorpay_order_id; 
            eachpaymentDetails.installment_details[installmentIndex].razorpay_transaction_id = payment.razorpay_transaction_id;
            eachpaymentDetails.installment_details[installmentIndex].payment_status =emi_status;
         }
}

 
    
    
   

const handleClickToPay=(programId, payment_type)=>{
    localStorage.setItem('emi_payment_type',payment_type)
    localStorage.setItem('emi_program_id', programId)
      history.push('/emipaymentgateway')
}

const userName = paymentDetails[0]?.first_name

    return (
        <div className=' '>
          {loading_flag?
      <>
      <div>
        <img  className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
      </div>
      </>:<> 

            <div className='container  pb-5'>
          
                <div className="row p-5">
                    <h1 className='text-center'>Payment Dashboard</h1>
                </div>
                <div className='payment_table'>
                    <h3>Welcome, {userName}</h3>
                </div>
                {paymentDetails.map(i => {
                    if (i.payment_type === emi_payment) {
                        return (
                            
                                <table key={i.program_id} className='payment_table'>
                                    <thead>
                                        <tr>
                                            <th>{i.learning_track_name}</th>
                                            <th className='text-end ps-0'>Payment Type: Partial payment</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {i.installment_details?.map((pay, index) => {
                                            const isPrevInstallmentPaid = index > 0 && i.installment_details[index - 1].payment_status === emi_status;
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div className="col justify-content-center">
                                                            <div className='d-flex flex-column p-1 payment_dtls_block'>
                                                                <span><b>Type:</b> <p>{pay.payment_name}</p></span>
                                                                <span><b>Amount:</b> <p>{pay.payment_amount}</p></span>
                                                                <span><b>Pay by date:</b> <p>{date_formats.human_week_date_format(pay.pay_by_date)}</p></span>
                                                                {pay.payment_status === emi_status ? <span><b> Paid on:</b> <p className='text-success fw-bold'>{date_formats.human_week_date_format(moment.unix(pay.paid_date).valueOf())}</p></span> : ""}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="col d-flex align-items-center justify-content-end">
                                                            <div>
                                                                <button
                                                                    style={{ minWidth: "120px", }}
                                                                    disabled={pay.payment_status === emi_status || !isPrevInstallmentPaid}
                                                                    className={`btn btn-${i.payment_status === emi_status ? "secondary" : "primary"} payment_btns my_btn_cls_dsbld`}
                                                                    onClick={() => handleClickToPay(i.program_id, i.payment_type)}
                                                                >
                                                                    {pay.payment_status === emi_status ? "Paid" : "Click to pay"}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            
                        );
                    } else if (i.payment_type === full_payment) {
                        return (
                            
                                <table className='payment_table'>
                                    <thead>
                                        <tr>
                                            <th>{i.learning_track_name}</th>
                                            <th className='text-end ps-0'>Payment Type: Full Payment</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <div className="col justify-content-center">
                                                <div className='d-flex flex-column p-1 payment_dtls_block'>
                                                    <span><b>Paid Amount:</b> <p>{i.full_payment_data?.amount_paid}</p></span>
                                                                                                       
                                                    {<span><b> Paid on:</b> <p className='text-success fw-bold'>{date_formats.human_week_date_format(moment.unix(i.full_payment_data.payment_date).valueOf())}</p></span> }
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div className="col d-flex align-items-center justify-content-end">
                                                <div>
                                                    <button
                                                        style={{ minWidth: "100px",height:"30px" }}
                                                       disabled
                                                        className={`p-0 btn btn-${i.payment_status === emi_status ? "secondary" : "primary"} payment_btns my_btn_cls_dsbld`}
                                                        onClick={() =>handleClickToPay(i.program_id, i.payment_type)}
                                                    >
                                                        Paid
                                                    </button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                                </table>
                            
                        );
                    } else if(i.payment_type === offline_payment){
                        return (
                            <table className='payment_table'>
                                    <thead>
                                        <tr>
                                            <th>{i.learning_track_name}</th>
                                            <th className='text-end ps-0'>Payment Type: Offline</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} >
                                            <div className="offlinetext">
                                              <span>This is an offline payment</span>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                                </table>
                            
                        );
                    }else{
                       return (<table className='payment_table'>
                                    <thead>
                                        <tr>
                                            <th>{i.learning_track_name}</th>
                                            <th className='text-end ps-0'>Payment Type: None</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                    <tr>
                                        <td colSpan={2} >
                                            <div className='offlinetext'>
                                              <span>Please check your email for payment</span>
                                            </div>
                                        </td>
                                    </tr>

                                </tbody>
                              </table>)
                    }
                })}

            </div>
            </>}

        </div>
    );
};

export default PaymentDashboard;