import { useRef,useLayoutEffect ,useEffect,useState} from "react";

export const ImageDataComponent = (props) => {
    const image_Component=useRef(null)
useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({
          top:  image_Component.current?.offsetTop-30,
          left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
    let data = props.data;
    let purpose = props.purpose;
    var mystyle = {
        backgroundImage: `url("${data.img}")`
    };
    useEffect(()=>{
        const container = document.createElement('div');
        container.innerHTML = data.str_data; 
        const text = container.querySelectorAll('.summary_header_cls');
        const liElements = document.querySelectorAll('#image-component .summary_list_cls');
        const headerTexts = Array.from(text).map(element => element.textContent.trim())
        const liTexts = Array.from(liElements).map(li => li.textContent.trim());
        let combinedText = [...headerTexts,...liTexts,data.img].join('. ')
        localStorage.setItem('prev_title',data?.title_data)
        localStorage.setItem('prev_text',combinedText)
       
    },[])
    return (
        <>
            <div data-testid="ImageDataComponent_content">
                <div className="col-12 image-component" ref={image_Component}>
                    <div className="container__head">
                        <figure data-testid="ImageDataComponent_content_image" className="container__head-image" style={mystyle}></figure>
                    </div>
                    <div className="image-content custm_shadow_cls">
                        <h2>{data?.title_data}</h2>
                        <div id="image-component" dangerouslySetInnerHTML={{ __html: data.str_data }} />
                    </div>
                </div>
            </div>
        </>
    )
}