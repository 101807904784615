import axios from "../../axios-interceptor";
import { useState, useEffect, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  base_url,
  date_formats,
  random_number,
  api_error_message,
} from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Table } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import moment from "moment";
import Select from "react-select";

export const ModuleTakeaways = (props) => {
  const history = useHistory();
  const location = useLocation();
  let [activeProgammeList, setActiveProgammeList] = useState([]);
  let [loading_flag, Setloading_flag] = useState(true);
  let [moduleloading_flag, setModuleloading_flag] = useState(false);
  let [message, setMessage] = useState(false);
  const [programmes, SetAllProgrammes] = useState([]);
  let [course, setCourses] = useState([]);
  let [programme_id, setProgrammeId] = useState("");
  let [course_id, setCourseId] = useState("");
  let [data, setData] = useState([]);
  let programs_list = [];
  let active_program_list = [];
  let data_flag = false;
  let [modulesList_summary,setModulesList_summary]=useState([])
  let [fileUploadData,setFileUploadData]=useState({})
  let [progress, setProgress] = useState(0)
  let [showModal,setShowModal]=useState(false)
  let [fileUploadSuccessModal,setFileUploadSuccessModal]=useState(false)
  let [deleteFileModal,setDeleteFileModal]=useState(false)
  useEffect(() => {
    localStorage.removeItem("sla_id");
    axios
      .post(base_url + "admin/get_program_applications_for_review", {})
      .then((response) => {
        Setloading_flag(false);
        SetAllProgrammes(response.data.result);
        response.data.result.map((item) => {
          programs_list.push(item);
        });
        if(localStorage.getItem('sla_program_id') && localStorage.getItem('sla_course_id')){
          data_flag = true
         handleProgrammeChange(localStorage.getItem('sla_program_id'))
     }
      
       
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
  }, []);

  const handleProgrammeChange = (id) => {
    localStorage.setItem("sla_program_id", id);
    setModulesList_summary([])
    setProgrammeId(id);
    setCourseId("");
    let programs = [...programmes];
    programs.map((item) => {
      if (item._id == id) {
        setCourses(item.courses);
      }
    });
    if (data_flag) {
      setCourseId(localStorage.getItem("sla_course_id"));
      programs_list.map((item) => {
        if (item._id == id) {
          setCourses(item.courses);
        }
      });
      getModulesbycourse(localStorage.getItem("sla_course_id"))
    }
  };
  const handleCourseChange = (id) => {
    localStorage.setItem("sla_course_id", id);
    setData([]);
    setCourseId(id);
    // getData(id, programme_id);
    getModulesbycourse(id)
  };

  const getModulesbycourse=(id)=>{
    setModuleloading_flag(true)
    axios.post(base_url+'get_course_modules_with_module_summary_lessons',{
      "course_id": id
    })
    .then(res=>{
      if(res.data.data.length == 0){
        setMessage(true);
      }else{
      res.data?.data?.map(item=>{
        item['can_Upload']=false
        item['file_url_available']=false
        if(item.summary_lessons)
        {
          if(item.summary_lessons.length>0)
          {
            item['lesson_id']=item.summary_lessons[0].lesson_id
            if(item.summary_lessons[0].module_summary_link==undefined||item.summary_lessons[0].module_summary_link=="")
            {
              item['file_url_available']=false
              item['can_Upload']=true
            }
            else{
              item['can_Upload']=true
              item['file_url_available']=true
            }
          }
          else{
          }
        }
        else{
            item['can_Upload']=false
        }
      })
      setModulesList_summary(res.data?.data)
      setModuleloading_flag(false)
    }
    })
    .catch(err=>{
      console.log(err)
    })
  }

  const handleFileInputChange = (event,lessonData) => {
    const file = event.target.files[0];
    setFileUploadData({File: file, FileName: file.name})
    //file upload
    const fd = new FormData();
    fd.append('lesson_id', lessonData[0].lesson_id);
    fd.append("files", file);
    setShowModal(true)
    axios.post(base_url+"lessons/update_module_summary_link",fd,{
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: data => {
        setProgress(Math.round((100 * data.loaded) / data.total))
        if(Math.round((100 * data.loaded) / data.total) == 100){
          setShowModal(false)
          setProgress(0)
          toast.success('Module Takeaway uploaded successfully',{ toastId: random_number })
          setTimeout(() => {
            setFileUploadSuccessModal(false)
          }, 3000);
        }
      },
    })
    .then(res=>{
      getModulesbycourse(localStorage.getItem("sla_course_id"))
      setFileUploadData({})
      // toast.success(res.data?.message,{ toastId: random_number })
    })
    .catch(err=>{
      console.log(err);
    })
  }
 const selectFile=(index)=>{
  
    const fileUpload = document.getElementById(`module_summary-${index}`);
    fileUpload.click();
 }

let [deleteItem,setDeleteItem]=useState('')
 const handleDeleteFile = (item) => {
  setDeleteItem(item.lesson_id)
  setDeleteFileModal(true);
};
 const handleCloseDeleteModal = () => {
  setDeleteFileModal(false);
};
 const handleDeleteModuleSummaryLink = () => {
    axios.post(base_url+'lessons/delete_summary_link',{"lesson_id":deleteItem})
    .then(res=>{
      setDeleteFileModal(false);
      toast.success('Module Takeaway deleted successfully',{ toastId: random_number })
      setDeleteItem('')
      getModulesbycourse(localStorage.getItem("sla_course_id"))
    })
    .catch(err=>{
      console.log(err);
    })
  };
  const customFilterOption = (option, searchText) => {
    const optionLabel = option.label?.toLowerCase();
    const searchLower = searchText?.toLowerCase();
    return optionLabel?.includes(searchLower);
  };
  
  const optionsProgramm = programmes.map((app) => ({
    value: app._id,
    label: app.program_display_name ? app.program_display_name : " ",
  }));

  const optionsCourse = course.map((app) => ({
    value: app.course_id,
    label: app.course_title,
  }));
  const dropdownStyles = {
    control: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', // Prevent text from wrapping to the next line
      overflow: 'hidden', // Hide any overflow
    }),
    menu: (provided) => ({
      ...provided,
      minWidth: '500px', // Set the desired width for the menu
    }),
    };
    
  return (
    <>
      <div className="bg-body container ">
        {loading_flag ? (
          <>
            <div className="mt-5">
              <img
                className="atrium_loader_custom"
                src="/images/atrium_loader.gif"
                alt="Loader"
              />
            </div>
          </>
        ) : (
          <>
            {message ? (
              <h3 data-testid = "No_Records">No Records Found</h3>
            ) : (
              <>
                <div>
                  <span data-testid="Main_Heading" className="Moduletakaway_Main_heading">
                    Module Takeaways
                  </span>
                </div>
                <>
                  <div className="row mt-3">
                    <div className="col-lg-4">
                      <label className="program_sla_lable" data-testid="Programme_Label">Programme</label>
                      <Select
                  id="programms"
                  aria-label=".form-select example"
                  name="programme_name"
                  options={optionsProgramm}
                  placeholder="Select Programme"
                  onChange={(selectedOption) => {
                    handleProgrammeChange(selectedOption.value);
                  }}
                  backspaceRemovesValue
                  filterOption={customFilterOption}
                  value={optionsProgramm.find(option => option.value === programme_id)} // Set the selected option
                  menuPortalTarget={document.body} // Prevent scrollable behavior
                  styles={dropdownStyles}
                  classNamePrefix="react-select-Programme"
                />
                 <select
                   id="programms"
                   aria-label=".form-select example"
                   name="programme_name"
                  data-testid="Programme_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                //   value={pr}
                  // required
                  onChange={(selectedOption) => {
                    handleProgrammeChange(selectedOption.target.value);
                  }}>
                  <option value=''>Select the Programme</option>
                  {optionsProgramm.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                    </div>
                    <div className="col-lg-4">
                      <label className="program_sla_lable" data-testid="Course_Label">Course</label>
                      <Select
                  id="course"
                  aria-label=".form-select example"
                  name="course_name"
                  options={optionsCourse}
                  placeholder="Select the Course"
                  onChange={(selectedOption) => {
                    handleCourseChange(selectedOption.value);
                  }}
                  value={optionsCourse.find(option => option.value === course_id)}
                  classNamePrefix="react-select-Programme"
                />
                <select
                    id="course"
                    aria-label=".form-select example"
                    name="course_name"
                  data-testid="Course_Dropdown"
                  className="form-select d-none"
                  role="presentation" autoComplete="off"
                  // value={selectedProgram}
                  // required
                  onChange={(selectedOption) => {
                    handleCourseChange(selectedOption.target.value);
                    customFilterOption(selectedOption.target, "searchText");
                  }}>
                  <option value=''>Select the Course</option>
                  {optionsCourse.map((item,index) => {
                     return <option  key={index} value={item.value} >{item.label}</option>;
                  })}
                </select>
                    </div>
                  </div>
                  <div className="mt-4">

                  {moduleloading_flag ? (
                      <>
                        <div className="mt-5">
                          <img
                            className="atrium_loader_custom"
                            src="/images/atrium_loader.gif"
                            alt="Loader"
                          />
                        </div>
                      </>
                      ):(<>
                      {modulesList_summary.length>0&&
                      <>
                            <Table data-testid="Module_Takeaway_Table"
                      className="moduleTakeway-table email-status"
                      bordered={false}
                      striped={false}
                    >
                      <thead className="fs-20">
                        <tr>
                          <th className="text-left" data-testid = "S_No">S.No</th>
                          <th className="text-left" data-testid="Module_Name">Module Name</th>
                          <th className="text-center" data-testid="Upload">Upload Takeaways</th>
                          <th className="text-center" data-testid="Status">Status</th>
                          <th className="text-center" data-testid="Action">Action</th>
                        </tr>
                      </thead>
                      <tbody className="moduletakeaway_body">
                        {modulesList_summary.map((item, index) => {
                          return (
                            <Fragment key={index}>
                            <tr  className="moduletkway_row">
                              <td className="text-start pl-3">{index + 1}</td>
                              <td data-testid = {`Module_Title_${index}`} className="fs-18">{item.module_title}</td>
                              <td>
                                  <div className="Actsla_table_icons">
                                      <span>
                                        { item.can_Upload&&
                                        <>
                                        <input data-testid={`File_Input_${index}`}  type="file" name={`module_summary-${index}`}  id={`module_summary-${index}`} accept=".pdf" className="d-none"   onChange={(e)=>handleFileInputChange(e,item.summary_lessons)}  />

                                         {item.file_url_available?
                                         <>
                                         <img data-testid={`Faded_Upload_Image_${index}`} width="28" className="opacity-25" src="/images/Cloud_upload.png"></img>
                                         </>:
                                         <>
                                         <img data-testid={`Upload_Image_${index}`} width="28" onClick={(e)=>selectFile(index)} className="cursor-pointer" src="/images/Cloud_upload.png"></img>
                                         </>}
                                        </>
                                         }
                                      </span>
                                          
                                    </div>
                                </td>
                                <td>
                                    <div className="Actsla_table_icons">
                                      <div className="d-flex gap-2 align-items-center">
                                        {item.file_url_available&&
                                        <>
                                        <div data-testid={`Upload_Status_${index}`}> 
                                        <img width="28" className="" src="/images/document_status.png"></img>
                                        <span className="Upload_summary_text">Uploaded</span>
                                        </div>
                                        </>
                                        }
                                      </div>     
                                    </div>
                                </td>
                              <td>
                                <div className="d-block text-center">
                                  {item.file_url_available&&<>
                                   <i data-testid={`Delete_Icon_${index}`} onClick={()=>handleDeleteFile(item)} class="fa-solid fa-trash cursor-pointer align-middle"></i>
                                  </>}
                                </div>
                              </td>
                            </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                    <div data-testid="Table_Rows_Count" className="d-none">{modulesList_summary.length}</div>
                      </>}
                      </>)}
                  </div>
                  <div>
                  {showModal && (
                        <Modal data-testid="File_Progress_Modal"  show={showModal} centered ClassName="role_modal module_summary_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                          <Modal.Body>
                            <div className="d-flex align-items-end progressbar_layout gap-2">
                              <div>
                                 <img width="24" className="" src="/images/document_status.png"></img>
                              </div>
                              <div>
                              <div>
                                  <span className="upload_font">{fileUploadData?.FileName} {progress == 100? ' Uploaded':' Uploading'}</span>
                                  {<span className="float-end module_summary_progress_count">{ progress}% </span>}
                                {<ProgressBar className="module_summary_progress" now={progress} />}
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                  {fileUploadSuccessModal && (
                        <Modal show={fileUploadSuccessModal}  backdrop="static" keyboard={false} centered className="role_modal module_summary_modal_success" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                          <Modal.Body className="pt-2">
                            <div className="d-block text-center">
                              <div>
                                  <span className="upload_font">Successfully uploaded</span>
                              </div>
                              <div className="mt-2">
                                 <img src="/images/circle_right_tick_blue.png" alt="image not found" />
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                  {deleteFileModal && (
                        <Modal data-testid="Delete_Modal"  show={deleteFileModal} size="sm" centered className="role_modal overflow-hidden" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
                          <Modal.Body className="del_upload_body">
                            <div className="d-block text-center">
                              <div>
                                  <span data-testid="Modal_Heading" className="del_upload_font">Are you sure, you want to delete this file?</span>
                              </div>
                              <div className="mt-3 d-flex gap-3 align-items-center justify-content-center">
                                  <div>
                                    <button data-testid="Close_Button" className="btn my_outline_button_cls submit_button fw-bold cancel_module_smry_btn" onClick={handleCloseDeleteModal} >Cancel</button>
                                  </div>
                                  <div>
                                    <button data-testid="Delete_Button" className="btn my_btn_cls mt-0 delete_module_smry_btn" onClick={handleDeleteModuleSummaryLink}>Delete</button>
                                  </div>
                              </div>
                            </div>
                          </Modal.Body>
                        </Modal>
                      )}
                  </div>
                </>
              </>
            )}
          </>
        )}
      </div>
    </>
  );
};
