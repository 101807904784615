import React, { useEffect, useState } from "react";
import axios from "../../axios-interceptor";
import { base_url,api_error_message,random_number, admin_maintenance_type } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
const LoadLearningTrack = () => {
  let [learning_tracks, setLearningTracks] = useState([]);
  const history = useHistory();
  let [userData, setUserData] = useState({
    id: "",
    available_date: "",
    start_date: "",
    capacity: "",
    internal_notes: "",
    option: "",
  });

  useEffect(() => {
    axios
      .post(base_url + "create/get_learning_track", {})
      .then((res) => {
        setLearningTracks(res.data.result);
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
  }, []);


  var myDate = userData.available_date;
  myDate = myDate.split("-");
  var avDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);


  var myDate = userData.start_date;
  myDate = myDate.split("-");
  var strtDate = new Date(myDate[0], myDate[1] - 1, myDate[2]);

  const createProgram = () => {
    axios
      .post(base_url + "create_program", {
        learning_track_id: ltID,
        start_date: strtDate.getTime(),
        available_date: avDate.getTime(),
        capacity: userData.capacity,
        internal_notes: userData.internal_notes,
      })
      .then((res) => {
        localStorage.setItem("programId", res.data.data._id);
      });
  };
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name == "available_date") {
      setUserData({ ...userData, [name]: value });
    }
    if (name == "start_date") {
      setUserData({ ...userData, [name]: value });
    }
    if (name == "capacity") {
      setUserData({ ...userData, [name]: value });
    }
    if (name == "internal_notes") {
      setUserData({ ...userData, [name]: value });
    }
    if (name == "option") {
      setUserData({ ...userData, [name]: value });
    }
  };

  const [ltID, setLtID] = useState();

  const handleDetails = (e) => {
    setLtID(e.target.value)
  };

  return (
    <>
    <div>
      <div className="bg-body container p-5 mt-5 mb-2 gap-4">
        <div className="col-lg-4 ps-0">
          <form>
            <label htmlFor="datevalue">Select Learning Track</label>
            <select
              id="inputState"
              className="form-select form-select mb-2"
              data-testid="handle_select_details"
              aria-label=".form-select example"
              onChange={handleDetails}
            >
              <option selected>Select</option>
              {learning_tracks.map((app) => {
                
                return (
                  <option key={app._id} value={app._id}>
                    {app.learning_track_name}
                  </option>
                );
                {
                  /* } */
                }
              })}
            </select>
          </form>
          
        </div>
        <div className="col-lg-5 ps-0">
          <label htmlFor="exampleInput">
            Product Code (?) (example: 20230101_LTME){" "}
          </label>
          <input
            type="text"
            className="form-control"
            id=""
            name=""
            placeholder=" "
            autoFocus
            role="presentation"
            autoComplete="off"
          />
        </div>
        <div className="d-flex flex-row gap-3">
          <div>
            <label htmlFor="exampleInput"> Available Date</label>
            <input
              type="date"
              name="available_date"
              className="form-control"
              data-testid="available_date"
              id="available_date"
              placeholder=" "
              autoFocus
              role="presentation"
              autoComplete="off"
              onChange={handleChange}
            />
          </div>

          <div>
            <label htmlFor="exampleInput"> Start Date</label>
            <input
              type="date"
              className="form-control"
              id="start_date"
              name="start_date"
              data-testid="start_date"
              placeholder=" "
              autoFocus
              role="presentation"
              autoComplete="off"
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="exampleInput">Derived End Date (?) </label>
            <input
              type="text"
              className="form-control"
              id=""
              name=""
              data-testid="derived_enddate"
              placeholder=" "
              autoFocus
              role="presentation"
              autoComplete="off"
            />
          </div>
          <div>
            <label htmlFor="exampleInput">Capacity </label>
            <input
              type="number"
              className="form-control"
              id="capacity"
              name="capacity"
              data-testid="capacity"
              placeholder=" "
              autoFocus
              role="presentation"
              autoComplete="off"
              onChange={handleChange}
            />
          </div>
        </div>
        <div>
          <label htmlFor="story">Internal Notes</label>
          <textarea
            name="internal_notes"
            id="internal_notes"
            className="mt-0"
            data-testid="internal_notes"
            onChange={handleChange}
            rows="5"
            cols="25"
          ></textarea>
          <button className="my_btn_cls p-2 " data-testid="create_program" onClick={createProgram}>
            Create Program
          </button>
        </div>
      </div>
    </div>
    </>
  );
};

export default withMaintenanceCheck(LoadLearningTrack,admin_maintenance_type) 
