import { useHistory, useLocation } from "react-router-dom";
import { base_url,date_formats, random_number,api_error_message } from "../../utilities";
import axios from "../../axios-interceptor";
import { useState, useEffect,useRef } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import {toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ProgramList = (props) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  let [loading_flag,Setloading_flag]=useState(true)
  let [programsList, setProgramsList] = useState([]);
  if (!localStorage.getItem("Admin_Acesstoken")) {
    history.push("/adminlogin");
  }
  useEffect(() => {
    localStorage.removeItem("learning_track_selected");
    localStorage.removeItem("learning_track_name");
    localStorage.removeItem("save_obj");
    axios
      .post(base_url + "get_programes_for_admin")
      .then((res) => {
        res.data.result.reverse();
        setProgramsList(res.data.result);
        Setloading_flag(false)
      })
      .catch((err) => {
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/adminlogin')
          window.location.reload();
        }else{
          // alert("catch")
          toast.warn(api_error_message,{toastId:random_number});
        }
      });
  }, []);
const goToLoadLearningTrack=()=>{
  localStorage.setItem('adminSelectedTab',"createprogram");
 history.push('/admindashboard/createprogram')
}
const onEdit = (id,end_date) =>{
  // alert("test")
  if( date_formats.convert_current_date_utc_format() < end_date){
    history.push('/courseslist',{programme_id:id,learning_track_id:undefined,is_editing:true})
  }else{
    toast.error("Program could not be updated as it’s already started",{toastId:random_number}) 
   
  }

}
  return (
    <>
      <div className="bg-body " style={{ height: "550px" }}>
        <Container className="  pb-0 d-flex flex-row justify-content-between">
          <div className="mt-4 ml-3">
            <h3 data-testid="Main_Heading" className="programs-heading">Programmes</h3>
          </div>
          <div>
            <button data-testid="Learning_track_Button"
              type="button"
              className="btn btn-secondary admin_button mr-3 float-end d-flex w-auto mt-4"
              onClick ={goToLoadLearningTrack}
            >
              <span className="text-white">LOAD LEARNING TRACK</span>
            </button>
          </div>
        </Container>
        {loading_flag?
        <>
          <div>
           <img data-testid="Loader" className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
          </div>
        </>:
        <>
            <div className=" mt-3 m-auto col-lg-12  align-center progm_table">
              <div class="text-nowrap">
              <Table data-testid="Programmes_Table" className="programs-table table_custom">
            <thead className="programs-head">
                    <tr>
                      <th>Status</th>
                      <th>Product Code</th>
                      <th>Programme Name</th>
                      <th>Programme Type</th>
                      <th>Available From</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Learners</th>
                      <th>Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {programsList.map((item, index) => {
                      return (
                        <tr key={index} data-testid={`Table_row_${index}`}>
                          {item.status == 1 && (
                            <td>
                              {
                                item.end_date >= date_formats.convert_current_date_utc_format() ?
                                  <span className="program_status_live">

                                    LIVE <span>&bull;</span>
                                  </span> : <span className="program_status_closed"> CLOSED</span>
                              }


                            </td>
                          )}
                          {item.status == 2 && (
                            <td>
                              <span className="program_status_closed"> CLOSED</span>
                            </td>
                          )}
                          {item.status == 3 && (
                            <td>
                              <span className="program_status_draft"> DRAFT</span>
                            </td>
                          )}
                          <td>{item.product_code}</td>
                          <td title={item.program_display_name}>{item.program_display_name ? item.program_display_name.substring(0, 30) : ""}</td>
                          <td>{item.program_type == 1 ? 'Cohort' : 'Standalone'}</td>
                          <td>{date_formats.human_date_format(item.available_date)}</td>
                          <td>{date_formats.human_date_format(item.start_date)}</td>
                          <td>{date_formats.human_date_format(item.end_date)}</td>
                          <td>
                            {item.no_of_learners}/{item.learners_capacity}
                          </td>
                          <td style={{ cursor: "pointer" }} >
                            <span data-testid={`Programme_Edit_${index}`} onClick={() => onEdit(item.programme_id, item.end_date)}>Edit</span>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div className="d-none" data-testid="Table_Length">{programsList.length}</div>
              </div>
            </div>
        </>}
      </div>
    </>
  );
};
export default ProgramList;
