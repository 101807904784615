import { useRef,useLayoutEffect, useEffect,useState,screen} from "react";
export const ImageComponent = (props) => {
    let data = props.data;
    let purpose = props.purpose;
    const image_Component=useRef(null)
useLayoutEffect(() => {
    props.i==0?
   setTimeout(() => {
       window.scrollTo({
         top: window.scrollY ,
         left: 0,
         behavior: "smooth",
       });
     }, 350):
     setTimeout(() => {
         window.scrollTo({

          top:  image_Component.current.offsetTop-20,
          left: 0,
           behavior: "smooth",
         });
       }, 350)
  }, []);
    var mystyle = {
        backgroundImage: `url("${data.img}")`
    };
    useEffect(()=>{
        localStorage.setItem('prev_title',data?.title_data)
        localStorage.setItem('prev_text',data.img)
       
    },[])
    return (
        <>
            <div data-testid="ImageComponent_content" className="row" ref={image_Component}>
                <div className="col-12 text-center">
                    <img className="img-fluid" src={data.img} />
                </div>
            </div>
        </>
    )
}