import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
const CustomDataTables = (props) => {
    const [filterData, setFilterData] = useState([]); 
    useEffect(() => {
        let data=JSON.parse(JSON.stringify(props.data))||[]
        const lastIndex = data.length - 1;
        if(lastIndex!==-1)
        {
            data.splice(lastIndex,1)
        }
        const filteredData= data.map(obj=>{
           let tableObj={}
              Object.keys(obj).map(singleItemkey => {
                tableObj['lesson_name']=singleItemkey
                const combinedObject = obj[singleItemkey].reduce((result, obj) => {
                  const [key] = Object.keys(obj);
                  result[key] = obj[key];
                  return result;
                }, {});
                  tableObj['earned_score']=combinedObject['earned score']?combinedObject['earned score']:0
                   tableObj['total_score']=combinedObject['total score']?combinedObject['total score']:0
                   tableObj['scored_percentage']=combinedObject['scored_percentage']?combinedObject['scored_percentage']+"":0
                   if(tableObj['total_score']==0)
                   {
                    tableObj['earned_score']="-"
                    tableObj['total_score']="-"
                    tableObj['scored_percentage']="-"
                   }
              })
           return tableObj;
        })
        setFilterData(filteredData);
    }, [props.data])
    
    const columns = [
        { 
          name: 'Lesson Name',
          selector: row => row['lesson_name'],
          sortable: true },
        { 
          name: 'Earned Score',
          selector: row => row['earned_score'],
          sortable: true },
        { name: 'Total Score', 
          selector: row => row['total_score'],
          sortable: true },
        { 
            name: 'Scored Percentage',
            selector: row => row['scored_percentage'],
            sortable: true,
            cell: row => (
              // <div style={{display:row.scored_percentage==0&&'none'}}>
                <div style={{display:'flex',gap:(row.scored_percentage.length==3)?'10px':(row.scored_percentage.length==2)?'15px':"23px"}}>
                  {<div>
                    {row.scored_percentage!=="-"&&<><i class="fa-solid fa-diamond" style={{color: parseInt(row.scored_percentage)<50 ?'#D00000'
                  :parseInt(row.scored_percentage)>75?'#034A0E':parseInt(row.scored_percentage)>50?'#D1A13D':''}}></i></>}</div>}
                  {<div style={{color: parseInt(row.scored_percentage)<50 ?'#D00000'
                  :parseInt(row.scored_percentage)>75?'#034A0E':parseInt(row.scored_percentage)>50?'#D1A13D':''}}>{row.scored_percentage} {row.scored_percentage!=="-"&&'%'}</div>}
                </div>
              // </div>
            )
         }
      ];
    
      return (
        <DataTable className='CustomData_table'
              columns={columns}
              data={filterData}
            />
      );
}

export default  CustomDataTables