import { useEffect, useState } from "react";
import { useLayoutEffect, useRef } from "react";
import axios from "../../axios-interceptor";
import { useHistory, useLocation } from "react-router-dom";
import { base_url, random_number,downloadPdf, quiz_name } from "../../utilities";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
export const ActivityFileUpload = (props) => {
  const [activityInitiatedTime, setActivityInitiatedTime] = useState(moment());
  let history=useHistory()
  let text= ''
  const file_upload_Component = useRef(null);
  useLayoutEffect(() => {
    props.i == 0
      ? setTimeout(() => {
          window.scrollTo({
            top: window.scrollY,
            left: 0,
            behavior: "smooth",
          });
        }, 350)
      : setTimeout(() => {
          window.scrollTo({
            top: file_upload_Component.current?.offsetTop - 40,
            left: 0,
            behavior: "smooth",
          });
        }, 350);
  }, []);

  let data = props.data;
  let purpose = props.purpose;
  const goToFileUploadPage = () =>{
   localStorage.setItem('file_flag',true);
   history.push('/fileupload',{Fileupload_ProgressTracking:JSON.stringify(props)})
  }
  function exitFullscreen() {
    if (document.webkitExitFullscreen) {
       document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    }
  }

  for (const value of props.data.content.stem.content) {
    if (value?.children) {
       if(value?.type=='ol')
      {
        recursive_ol_data(value.children);
      }
      else if(value?.type=='ul')
      {
        recursive_ul_data(value.children);
      }
      for (const value1 of value.children) {
        if (value1.type === 'a') {
          text += `<a target= 'blank' href='${value1.href}'>${value1.children[0].text}</a>`
        }
        else if (value.type === 'h2') {
          text += `<h2 class="question-title">${value1.text}</h2>`
        }
        else {
          if (value1?.strong) {
            text += '<strong class="summary_header_cls">';
            text += value1?.text ? value1.text : ''
            text += '</strong><br/>';
          } else {
            if(value1?.text ){
              text += `<span class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
              if(value1.em){
                text += `<em class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</em>';
              }else if (value1?.strong) {
                text += `<strong class="summary_header_cls ${(value.text !== "" ? "d-block pb-4" : "")}">`;
                text += value1?.text ? value1.text : ''
                text += '</strong><br/>';
              }
              else{
                text += value1?.text ? value1.text : ''
                text += '</span>';
              }
            }
          
          }
        
        }
      }
    }
  }
  function recursive_ol_data(data){
    let str_li = '<ol class="summary_uList_cls">';
    for(const value of data){         
        for(const value1 of value.children){
          if (value1?.strong) {
            str_li += '<li class="summary_list_cls"><strong class="summary_header_cls">';
            str_li += value1.text;
            str_li += '</strong>';
          }else{
            str_li += '<li class="summary_list_cls">'+value1.text
          }
        }
        str_li += '</li>' 
      }
     text+=str_li+ '</ol>';
  }
  function recursive_ul_data(data){
    for(const value of data){ 
      if(value?.type !== 'li'){
        recursive_ul_data(value.children)
      }else{          
        let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
        for(const value1 of value.children){
          if (value1?.strong) {
            str_li += '<strong class="summary_header_cls">';
            str_li += value1.text;
            str_li += '</strong>';
          }else{
            str_li += value1.text
          }
          }
        str_li += '</li>' 
        text +=str_li+ '</ul>';
      }
    }
  }
  text=text?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
  props.data.final_text = text;
  return (
    <>
    
        <div
          data-testid="ActivityFileUpload_content"
          className="col-12 data-component bg-body custm_shadow_cls"
          ref={file_upload_Component}
        >
          <h2 data-testid="activity_title">{data?.title_data}</h2>
          <div data-testid="activity_content" dangerouslySetInnerHTML={{ __html: props.data.final_text }} />
          {localStorage.getItem('sla_file_url') != 'null' && <><img data-testid="file_image" src="/images/document.svg"></img>&nbsp;&nbsp;<span data-testid="file_upload_name" onClick={() => downloadPdf(localStorage.getItem('sla_file_url'))}  className="text-decoration-underline cursor-pointer">{localStorage.getItem('sla_file_url')?.split('/')?.pop()?.replace(/[^a-zA-Z._]/g,'')}</span></>}
          <br></br>
           <button data-testid="file_upload_button" className="btn review_button submit_button" onClick = {goToFileUploadPage} >
          <b>{props.data.is_answered? 'REUPLOAD FILE':'UPLOAD FILE' }</b>
          </button>
        </div>
        
      
    </>
  );
};
