import React, { useEffect } from "react";
import "./App.css";
import { Switch } from "react-router-dom";
import { Route, BrowserRouter } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { withRouter as Router } from "react-router-dom";
import "./pageTransitions/slideTransition.css";
import { Component } from 'react';
import CardMain from './MainPageComponents/LandingPage/CardMain';
import ModulePage from './MainPageComponents/LearnerFlowComponents/modulepage';
import OnlineApplication from './MainPageComponents/OnlineApplication';
import ApplicationRecvd from './MainPageComponents/LandingPage/ApplicationRecvd';
import AdminApproval from './MainPageComponents/AdminApprovals/AdminApproval';

import ScrollToTop from './MainPageComponents/ScrollToTop';
import lessonpage from "./MainPageComponents/LearnerFlowComponents/lessonpage";
import AdminUpload from './MainPageComponents/AdminApprovals/AdminUpload';
import ModuleHomePage from './MainPageComponents/AdminApprovals/ModuleHomePage';
import DashBoard from "./MainPageComponents/DashBoard";
import { base_url } from "./utilities";
import axios from "axios";
import  AdminApprovalModules  from './MainPageComponents/AdminApprovalModules';
import  FreeLessonPage  from "./MainPageComponents/LearnerFlowComponents/FreeLessonpage";
import AdminLogin from "./MainPageComponents/AdminApprovals/AdminLogin";
import SocialLoginScreen from './MainPageComponents/SocialLoginScreen';
import AdminDashboard from "./MainPageComponents/AdminFlow/AdminDashboard";
import courseView from "./MainPageComponents/AdminFlow/courseView";
import CourseLearningTrackList from "./MainPageComponents/AdminFlow/coursesList";
import CourseView from "./MainPageComponents/AdminFlow/courseView";
import CohortsAdd from "./MainPageComponents/AdminFlow/cohortsAdd";
import LearningTrackAdd from "./MainPageComponents/AdminFlow/learningTrackAdd";
import  PastCourse  from "./MainPageComponents/pastcourse";
import MyBatchmates from "./MainPageComponents/AdminApprovals/MyBatchmates";
import  IsbUser  from "./MainPageComponents/AdminFlow/isbuserpage";
import IsbUserLogin from "./MainPageComponents/IsbUserLoginPage";
import ProgramManagementFlow from "./MainPageComponents/ProgramManagement/ProgramManagementDashboard";
import EnrollmentManagementFlow from "./MainPageComponents/EnrollmentManagement/EnrollmentManagementDashboard";
import ProjectManagement from './MainPageComponents/ProgramManagement/ProjectManagement';
import ViewFullProfile from './MainPageComponents/ProgramManagement/viewFullProfile';
import LoadLearningTrack from './MainPageComponents/ProgramManagement/LoadLearningTrack';
import  CourseList  from "./MainPageComponents/ProgramManagement/coursesList";
import  CreateProgram  from "./MainPageComponents/ProgramManagement/createProgram";
import  FreeLessonLogin  from "./MainPageComponents/LearnerFlowComponents/freeLessonLogin";
import IsbLogin from "./MainPageComponents/LearnerFlowComponents/isbLogin";
import SetPassword from "./MainPageComponents/setPassword";
import AddressApplicationForm from "./MainPageComponents/AddressApplicationForm";
import PaymentGateway from "./MainPageComponents/PaymentGateway";
import ForgotPasswordReset from "./MainPageComponents/ForgotPasswordReset";
import  EditSLAs  from "./MainPageComponents/ConfigureSLAs/EditSLAs";
import  CourseProgram  from "./MainPageComponents/ProgramManagement/courseProgram";
import  ProgrammeSla  from "./MainPageComponents/ProgramManagement/programmeSla";
import UserProfileMain from "./MainPageComponents/UserProfile/UserProfileMain";
import  FileUpload  from "./MainPageComponents/LearnerFlowComponents/fileUpload";
import NotFoundPage from "./MainPageComponents/NotFoundPage";
import AdminDashboardRoleA from "./MainPageComponents/AdminFlow/AdminDashboardRoleA";
import CourseUpload from "./MainPageComponents/AdminFlow/courseUpload";
import mobileViewMessage from "./MainPageComponents/mobileViewMessage";
// import SharableBadgeInfo from "./MainPageComponents/SharableBadgeInfo";
import  PreviewPage   from "./MainPageComponents/LearnerFlowComponents/previewComponent";
import GradeBookIndividual from "./MainPageComponents/ProgramManagement/GradeBookIndividual";
import  ScoreCard   from "./MainPageComponents/LearnerFlowComponents/scoreCardComponent";
import  ProgrammePolicy  from "./MainPageComponents/ProgramManagement/Programmepolicy";
import Maintenance from "./MainPageComponents/Maintenance";
import InvalidFreelessonLink from "./MainPageComponents/InvalidFreelessonLink";
import FreeLessonPageBD from "./MainPageComponents/LearnerFlowComponents/FreeLessonPageBD";
import {TermsConditions} from "./MainPageComponents/TermsCondition.jsx";
import PaymentDashboard from "./MainPageComponents/LearnerFlowComponents/PaymentDashboard.jsx";
import EmiPaymentGateWay from "./MainPageComponents/LearnerFlowComponents/EmiPaymentGateWay.jsx";



const App = (props) => {
  let value=props
  return (
    <div>
    
      <ScrollToTop />
        {/* <Route path="/" exact component={(props) => <IsbLogin {...props} sessionProp={value} />} /> */}
        {/* USER Routes */}
        <Route path="/" exact component={IsbLogin} />
        <Route path="/cardmain" component={CardMain} />
        <Route path="/onlineapplication/:leadform_id?" component={OnlineApplication} />
        <Route path="/applynow_cta/:learningtrack_id?/:program_id?" component={OnlineApplication} />
        <Route path="/socialloginscreen/:learningTrackId/:programmeId/:utmtags?" component={SocialLoginScreen} />
        <Route path="/applicationrecvd" component={ApplicationRecvd} />
        <Route path="/modulepage/:id?" component={ModulePage} />
        <Route path="/lessonpage" component={lessonpage} />
        <Route path="/dashboard" component={DashBoard} />
        <Route path="/freelessonlogin" component={FreeLessonLogin} />
        <Route path="/freeLessonpage/:program_id/:learningtrack_id/:course_id/:learningtrack/:course?/:utmtags?" component={FreeLessonPage} />
        <Route path="/viewfullprofile" component={ViewFullProfile} />
        <Route path="/pastcourse" component={PastCourse} />
        <Route path="/setpassword/:user_id/:refresh_token?/:program_id?" component={SetPassword} />
        <Route path="/pay/:user_id/:refresh_token?/:program_id?" component={SetPassword} />
        <Route path="/paymentgateway" component={PaymentGateway} />
        <Route path="/userprofile" component={UserProfileMain}/>
        <Route path="/passwordreset" component={ForgotPasswordReset} />
        <Route path="/userlogin" component={IsbUserLogin} />
        <Route path="/mobileviewmsg" component={mobileViewMessage}/>
        <Route path="/addressapplicationform" component={AddressApplicationForm} />
        
        <Route path="/paymentdashboard" component={PaymentDashboard} />
        <Route path="/emipaymentgateway" component={EmiPaymentGateWay} />



        {/* Admin Routes */}
        <Route path="/isbuser" component={IsbUser} />
        <Route path="/courseview" component={courseView} />
        <Route path="/courselearningtrackList" component={CourseLearningTrackList} />
        <Route path="/AdminApproval" component={AdminApproval} />
        <Route path="/mybatchmates" component={MyBatchmates} />
        <Route path="/fileupload" component={FileUpload} />
        <Route path="/notfoundpage" component={NotFoundPage} />
        {/* <Route path="/modulepage/:id?" component={ModulePage}/> */}
        <Route path="/AdminApprovalModules" component={AdminApprovalModules} />

        <Route path="/AdminUpload" component={AdminUpload} />
        <Route path="/ModuleHomePage" component={ModuleHomePage} />
        <Route path="/addcohorts" component={CohortsAdd} />
        <Route path="/learningtrackadd" component={LearningTrackAdd} />
        <Route path="/projectmanagement" component={ProjectManagement} />
        <Route path="/programmesla" component={ProgrammeSla} />
        {/* admin flow navigation */}
        <Route path="/admindashboard" component={AdminDashboard} />
        <Route path="/programmanagementdashboard" component={ProgramManagementFlow} />
        <Route path="/enrollmentmanagementdashboard" component={EnrollmentManagementFlow} />
      {/* </Switch> */}
        {/* <Route path="/quoteelement" component={QuoteElement} /> */}
   
        <Route path="/adminlogin" component={(props) => <AdminLogin {...props} sessionProp={value} />}/>
        <Route path="/loadlearningtrack" component={LoadLearningTrack} />
        <Route path="/courseslist" component={CourseList} />
        <Route path="/courseprogram" component={CourseProgram} />
        <Route path="/createprogram" component={CreateProgram} />
        <Route path="/editsla" component={EditSLAs} />
        <Route path="/admindashboardrolea" component={AdminDashboardRoleA}/>
        <Route path="/courseupload" component={CourseUpload}/>
        {/* <Route path="/digital-credentials/:id" component={SharableBadgeInfo}/> */}
        <Route path="/preview/:preview_id" component={PreviewPage}/>
        <Route path="/gradebookindividual" component={GradeBookIndividual}/>
        <Route path="/scorecard" component={ScoreCard}/>
        <Route path="/programmpolicy" component={ProgrammePolicy}/>
        <Route path="/freelessonbdlink/:course_id" component={FreeLessonPageBD} />
        <Route path="/invalidfreelessonlink" component={InvalidFreelessonLink} />
        <Route path="/termsconditions" component={TermsConditions} />
        
    </div>
  );
}

export default Router(App);
