import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Container } from "react-bootstrap";
import ProgressBar from 'react-bootstrap/ProgressBar';
import axios from "../../axios-interceptor";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { admin_maintenance_type } from "../../utilities";

const AdminUpload = () => {
  const [uploadPercent, setUploadPercent] = useState([0])
  const navigate = useHistory();
  const [course, setCourse] = useState([])
  const [flag, setFlag] = useState(false)
  const options = {

    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let percent = Math.floor(loaded * 100 / total)
      if (percent <= 100) {
        setUploadPercent(percent)
      }
    }
  }

  const onChangeFile = (event) => {
   
    var file = event.target.files[0]
    var url;
    if (window.location.hostname === 'localhost') {
      url = 'http://localhost:5643'
    } else {
      url = "https://dev.isbonline.ai/backend/csvtojson"
    }
    
    let formData = new FormData();
    formData.append("file", file);
    try {
      axios.post(url + "/files/save_file_jsons", formData, options
      ).then(res => {
        event.target.value = null;
        event.target.files = null;
        setFlag(true);
        setUploadPercent({ file: res.result, uploadPercent: 100 }, () => {
          setTimeout(() => {
            setUploadPercent(0);
            // navigate.push('/ModuleHomePage');
          }, 1000);
        })
      }).catch((error)=>{
        console.log(error)
        alert('file already exist');
        event.target.value = null;
        event.target.files = null;
        navigate.push('/ModuleHomePage');
      })
    } catch (err) {
      alert('file already exist1');
    }
  }


  //   var JSZip = require("jszip");
  //     var zip = new JSZip();
  //     let files = event.target.files[0];  
  //     zip.loadAsync(files)
  //       .then(function (zip) {
  //         Object.keys(zip.files).forEach(function (filename) {
  //           zip.files[filename].async('string').then(function (fileData) {
  //             if (filename == "_project.json") {
  //               setCourse([JSON.parse(fileData, options)])
  //               setFlag(true)
  //             }
  //             localStorage.setItem(filename, fileData)
  //           });
  //         })
  //       })
  // }






  const sendDatatoModule = () => {
    navigate.push('/modulepage', { state: { data: JSON.parse(localStorage.getItem("_hierarchy.json")).children[0].children } })

  }


  return (
    <div>
      <Container className="container shadow-lg border mt-3 bg-light">
        <div className="upload_content m-3 p-3">
          <h1 className='fs-1 fw-bold text-center' style={{ color: "#057092" }}>Welcome to Admin Upload</h1>

          <div className=" d-flex justify-content-center align-items-center input_div">

            {!flag && <input className='btn btn-warning m-5 p-3 rounded border shadow ' multiple type="file" name="file" onChange={onChangeFile}
            ></input>}
          </div>

          {uploadPercent == 100 &&
            <div className="alert alert-info alert-dismissible fade show text-center" role="alert">
              <strong>File Uploaded Successfully!</strong> You can now view courses.
              <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
            </div>



          }



          {uploadPercent > 0 && <ProgressBar striped variant="primary" now={uploadPercent} active="true" label={`${uploadPercent}%`} />}



          {flag && course.map(item => {
            return (
              <div className="text-white fw-bold fs-1 rounded shadow">
                <ul className='Module0 m-1 text-white p-4 rounded shadow' key={item.title} onClick={sendDatatoModule}>{item.title}</ul>
              </div>
            )
          })
          }

        </div>
      </Container>



    </div>
  );
};

export default withMaintenanceCheck(AdminUpload,admin_maintenance_type) 