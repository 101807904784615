import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const withScreenSizeCheck = (WrappedComponent) => {
  const WithScreenSizeCheck = () => {
    const history = useHistory();

    useEffect(() => {
      const handleResize = () => {
        if (window.innerWidth < 768) {
          // history.push('/mobileviewmsg');
        }
      };
      handleResize(); // call handleResize once when component mounts
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [history]);

    return <WrappedComponent />;
  };

  return WithScreenSizeCheck;
};

export default withScreenSizeCheck;
