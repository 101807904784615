import { useHistory, useLocation } from "react-router-dom";
import { base_url, random_number,api_error_message, admin_maintenance_type } from "../../utilities";
import axios from "../../axios-interceptor";
import Select from "react-select";
import { useState, useEffect } from "react";
import { List, arrayMove } from 'react-movable';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
 const CourseProgram = () =>{
    const [selectedOption, setSelectedOption] = useState([]);
  let [values, setValues] = useState(null);
  let [options, setOptions] = useState([]);
  let [final_course, setFinalCourse] = useState([]);
  const [list, setList] = useState([]);
  const history = useHistory();
    useEffect(() => {
      localStorage.removeItem('save_obj');
        axios
          .post(base_url + "courses/get_all_courses")
          .then((res) => {
            let courses = res.data.courses
            let final_array = [];
            setOptions(
              courses.map(({ id, title }) => ({ value: id, label: title }))
            );
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
      }, []);
   
    const handleSelectChange = (newValue) => {
        setSelectedOption(newValue)
        let data = []
        let course_ids = []
        if (Array.isArray(newValue)) {
        newValue?.map(item=>{
            data.push(item.label)
            course_ids.push(item.value)
        })
       setList(data)
       setFinalCourse(course_ids) 
    }
    };

    const createProgram = () =>{
      if(list.length != 0){
        let final_list = []
        list.map(item=>{
         [...final_course].map(id=>{
            for(let obj of options){
              if(obj.label == item && obj.value == id){
                 final_list.push(obj.value)
              }
            }
          })
           
          })
          history.push('/courseslist',{programme_id:undefined,learning_track_id:undefined,courses:final_list})
      }else{
        toast.error("Please select any course...",{toastId:random_number});
      }
        
    }
   
    const backtoAdmin=()=>{
        history.push('admindashboard/createprogram')
      }
     
    return(
        <>
       <div className={`bg-body container mt-5  p-5  mb-2 gap-4 ${list.length<=5?'vh-100':''}`} >
       <button className="btn my_outline_button_cls align-self-end mt-0 p-2 program_btn_back" data-testid="back_to_admin" onClick={backtoAdmin}>
            Back
            </button>
            <div className="mt-2">
           
            <div className="row">
                <div className="col-6">
                   <p className="program_option_heading mb-4">Select the courses to be part of program</p>
                <Select
                    onChange={handleSelectChange}
                    classNamePrefix="react-select-Student"
                    value={selectedOption}
                    // name={name}
                    options={options}
                    isMulti
                    />
                    <select
                          name="Courses"
                          data-testid="course_select"
                          className="form-select d-none"
                          onChange={handleSelectChange}>
                          {options.map((item,index) => {
                            return <option  key={index} value={item.value} >{item.label}</option>;
                          })}
                      </select>
                </div>
                <div className="col-6">
                  <span className="ml-4 program_option_heading">Selected Courses</span>
                  <div className="mt-4">
                  <List
                  values={list}
                  onChange={({ oldIndex, newIndex }) =>
                    setList(arrayMove(list, oldIndex, newIndex))
                  }
                  renderList={({ children, props }) => <ul  {...props}>{children}</ul>}
                  renderItem={({ value, props }) => <li className="list-items" {...props} ><span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="curre#D7E0E8ntColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>&nbsp;&nbsp;{value}</span></li>}
                />
                  </div>
               
                </div>


            </div>
            
            </div>
            {/* <div className="container"> */}
            <button className=" my_btn_cls p-2 mt-0 align-self-end " data-testid="create_program" onClick={createProgram}>
            Create Program
            </button>
            {/* </div> */}
       </div>
        </>
    )
}
export default withMaintenanceCheck(CourseProgram,admin_maintenance_type) 