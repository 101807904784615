import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { admin_maintenance_type, api_error_message, base_url,date_formats,random_number } from '../../utilities';
import { Container } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import Select from "react-select";
import { useController } from 'react-hook-form';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {learningtrackaddValidations } from '../../formValidationsRules';
import withMaintenanceCheck from '../../HigherOrderComponents/maintenancePageChecker';

const LearningTrackAdd=(props)=> {
    var [timer_flag, setTimeFlag] = useState(true);
    let [final_course, setFinalCourse] = useState([]);
    let [values, setValues] = useState(null);
    const { register, handleSubmit, formState: { errors },setValue,control } = useForm({ "mode": "all" },{defaultValues: {learning_track_name:'',image:'',description:'',cost:'', courses: [] } });
    const history=useHistory()
    let [selectedOption, setSelectedOption] = useState([]);
    let [courseCost, setCourseCost] = useState('');
    let [options, setOptions] = useState([]);
    if(!localStorage.getItem('Admin_Acesstoken'))
    {
      history.push('/adminlogin')
    }


    useEffect(() => {
        axios
          .post(base_url + "courses/get_all_courses")
          .then((res) => {
            let courses = res.data.courses
            let final_array = [];
            setOptions(
              courses.map(({ id, title }) => ({ value: id, label: title }))
            );
            courses.map((item1) => {
              if (item1.cohort_schedules.length !== 0) {
                let samp_array = item1.cohort_schedules.sort((a, b) => {
                  return b.start_date - a.start_date;
                });
                for (var item2 of samp_array) {
                  if (item2.start_date < date_formats.convert_current_date_utc_format()) {
                    final_array.push(
                      Object.assign(
                        {},
                        {
                          // ["cohort_schedule_id"]: item2._id,
                          ["course_id"]: item2.course_id,
                        }
                      )
                    );
                    break;
                  }
                }
              }
            });
            setFinalCourse(final_array);
          })
          .catch((err) => {
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
          });
      }, []);


    
    const addTrack=(data) =>{
        data.courses.map(course => {
            course.course_id = course.value;
            course.course_title = course.label;
            delete course.value;
            delete course.label;
            return course;
        });
        const {cost,courses,description,image,learning_track_name} =data
        setTimeFlag(false)
        let svobj={
            "learning_track_name":learning_track_name.trim(),
            "image":image.trim(),
            "cost":cost?parseInt(cost):null,
            'courses':courses,
            'description':description
        }

        const url = base_url + 'create/learning_track';
        axios.post(url, svobj)
        .then((response) => {
            setTimeFlag(true)
             alert(response?.data?.mesasge)
            // history.push('/courselearningtrackList')
        })
        .catch(err=>{
            setTimeFlag(true)
            console.log(err);
            if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
              localStorage.clear();
              history.push('/adminlogin')
              window.location.reload();
            }else{
              toast.warn(api_error_message,{toastId:random_number});
            }
        })
    }
    const handleSelectChange = (newValue) => {
        setSelectedOption(newValue)
        setValue("courses", newValue ? newValue: []);
    };
    const handleChangeInput = (e) => {
        const target = e.target;
        const value = target.value;
        const name = target.name;
        if(name=='cost'){
            const pattern = /^[0-9]*$/;
            if (value === "" || pattern.test(value)) {
                setCourseCost(value)
            }
         }    
    }
    return ( <>
    
    <div className="row mt-3">
    <Container className='  pb-0 d-flex flex-row justify-content-between'>
            <div className="mt-4" style={{marginLeft:"190px"}} ><h3 className='admin-tabs-heading'>Learning Tracks</h3></div>
     </Container>                   
              <div class="tab-content col-lg-2">
                <span className='visible-hidden'>dsd</span>
              </div>
                <div class="tab-content col-lg-8">
                    <div class="tab-pane fade show active " id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="mt-2">
                            <form >
                                <div className="row align-items-center flex-column">
                                    <div className="col-lg-6">
                                        <label htmlFor="exampleInput">Learning Track Name <span className='form-label-color'>*</span></label>
                                        <input {...register("learning_track_name",learningtrackaddValidations.learning_track_name )}
                                            id="learning_track_name"
                                            name="learning_track_name"
                                            type='text'
                                            className="form-control"
                                            role="presentation" autocomplete="off"
                                            data-testid="learning_track"
                                            >
                                        </input>
                                        {errors.learning_track_name?.message && <span className='form-label-color'>{errors.learning_track_name?.message}</span>}
                                    </div>
                                    <div className="col-lg-6 mt-2">
                                        <label htmlFor="exampleInput">Image Url <span className='form-label-color'>*</span></label>
                                        <input {...register("image",learningtrackaddValidations.image)}
                                            type="text"
                                            className="form-control"
                                            id="image"
                                            name="image"
                                            placeholder=" "
                                            required
                                            role="presentation" autocomplete="off"
                                            data-testid="img_url"
                                        />
                                          {errors.image?.message && <span className="form-label-color">{errors.image?.message}</span>}
                                    </div>
                                    <div className="col-lg-6 mt-2" >
                                    <label htmlFor="exampleInput" data-testid='course'>
                                        Course Name <span className="form-label-color">*</span>
                                    </label>
                                    <Controller
                                        as={Select}
                                        name="courses"
                                        placeholder="Course Name"
                                        options={options}
                                        isMulti
                                        control={control}
                                        rules={{ required: true }}
                                        render={({ onChange, value, name }) => (
                                            <Select
                                            classNamePrefix="react-select-Student"
                                            onChange={handleSelectChange}
                                            value={selectedOption}
                                            name={name}
                                            options={options}
                                            placeholder="Select an Option"
                                            isMulti
                                            />
                                        )}
                                        />
                                        <select
                                        id="programms"
                                        aria-label=".form-select example"
                                        name="programme_name"
                                        data-testid="Type_Select"
                                        className="form-select d-none"
                                        role="presentation" autoComplete="off"
                                        // value={selectedOption}
                                        // required
                                        onChange={(e) => {
                                          const selectedValue = e.target.value;
                                          const selectedOption = options.find(option => option.value === selectedValue);
                                          const transformedOption = { value: selectedOption.value, label: selectedOption.label };
                                          handleSelectChange([transformedOption]); // Transform the selected option into an array and pass it to the handler
                                        }}
                                        >
                                        <option value=''></option>
                                        {options.map((item,index) => {
                                          return <option  key={index} value={item.value} >{item.label}</option>;
                                        })}
                                      </select>
                                   {errors.courses && errors.courses.type === 'required' && selectedOption.length==0 && <span className="form-label-color">Course Name is  required</span>}
                                    </div>

                                    <div className="col-lg-6 mt-3">
                                            <label htmlFor="exampleInput">Cost</label>
                                        <input {...register("cost")}
                                            type="text"
                                            className="form-control"
                                            id="cost"
                                            name="cost"
                                            placeholder=" "
                                            value={courseCost}
                                            role="presentation" autocomplete="off"
                                            data-testid="handleInput"
                                            onChange={handleChangeInput}
                                           
                                        />
                                         {errors.cost?.message && <span className="form-label-color">{errors.cost?.message}</span>}
                                            </div>

                                    <div className=" col-lg-6 mt-1 text-area-font">
                                        <label htmlFor="exampleInput" for="course_title">Description <span className='form-label-color'>*</span></label>
                                        <textarea {...register("description",learningtrackaddValidations.Description)}
                                            type="text"
                                            id="description"
                                            name="description"
                                            placeholder=" "
                                            required    
                                            role="presentation" autocomplete="off"
                                           cols="30" rows="1"
                                           data-testid="description"
                                        />
                                        {errors.description?.message && <span className="form-label-color">{errors.description?.message}</span>}
                                    </div>
                                </div>
                            </form>

                            <div className="mt-3 mb-3 d-flex justify-content-center">
                                {!timer_flag ? (<>
                                  <div>
                                        <img className="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
                                    </div>

                                </>
                                ) :
                                    <button
                                        type="button"
                                        className="btn my_btn_cls ms-2"
                                        value="Add Cohort"
                                        onClick={handleSubmit(addTrack)}
                                        data-testid="submit_button"
                                    >

                                        Submit
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div class="tab-content col-lg-2">
                <span className='visible-hidden'>dsd</span>
              </div>
            </div>
    </> );
}

export default withMaintenanceCheck(LearningTrackAdd,admin_maintenance_type) 