import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
export const TermsConditions = () => {
    useEffect(() => {
        document.getElementById("top-nav-bar").style.display = "none";
   })
    return (
        <>
            <div className="container container-onlineApp my-5">
                <div className="container terms_conditions_content_div p-5 mt-2">
                    <h4 className="blue_text fw-bold">ISB Online Programmes - Terms & Conditions</h4>
                    <p className="my-3 fw-bold">
                        Language Proficiency
                    </p>
                    <p className="mb-0">
                        Proficiency in spoken and written English is essential for participation in Indian School of Business Executive Education programs. If English is your second language, or if you have less than one year's experience working in an English-speaking environment, please provide a brief statement documenting your proficiency. The Programme Advising Team may request an interview before proceeding with your application
                    </p>
                    <p className="my-3 fw-bold">
                        Payment Terms
                    </p>
                    <p className="mb-0">
                        Blocking fee confirms the participation of the applicant and once received is non-refundable.
                        Complete payment for the programmes is expected as per the schedule. Should we be unable to accept your application for any extraneous reason, your payment will be refunded to you.
                    </p>
                    <p className="my-3 fw-bold">
                        Cancellation/Refund Policy for ISB Online Programmes
                    </p>
                    <p className="mb-0">
                        All online programmes at ISB Executive Education & Digital Learning follow the cancellation/refund policy below. This policy applies for individual applicants as well as for company-sponsored applicants.
                    </p>
                    <p className="mt-3 mb-0 fw-bold">
                        1) Cancellation by ISB
                    </p>
                    <p className="mb-0">
                        If any online programme is cancelled by ISB, refund will be processed in full for the undelivered part of the programme within 30 days of such cancellation, provided that participant share their bank account and other necessary details within 5 days. Any delays in providing bank details from the participant’s side could result in delayed payment.
                    </p>
                    <p className="mt-3 mb-0 fw-bold">
                        2) Cancellation by Clients/ Participants
                    </p>
                    <p className="mb-0">
                        If a nomination is withdrawn by a Client/ Participant from any of the programmes, the refund policy is as below.
                    </p>
                    <p className="mt-3 mb-0 fw-bold">
                        Referral/Deferral Policy
                    </p>
                    <div>
                        <table className="mt-3 table table-bordered">
                            <thead>
                                <th>Request</th>
                                <th>Referral/Deferral Policy</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Cancelation request sent in writing more than 30 days prior to the programme start date</td>
                                    <td>100%</td>
                                </tr>
                                <tr>
                                    <td>Cancelation request sent in writing between 15-30 days prior to the programme start date</td>
                                    <td>50%</td>
                                </tr>
                                <tr>
                                    <td>Cancelation request sent in writing 14 days or less prior to the programme start date</td>
                                    <td>0%</td>
                                </tr>
                                <tr>
                                    <td>Deferral requests sent in writing before the programme start date</td>
                                    <td>Considered on a case-to-case basis</td>
                                </tr>
                            </tbody>
                        </table>
                        <ol type="a">
                            <li className="mt-4">Refund requests from participant(s)/ company may be moved to be deferral/substitution scenarios on mutual consent between the participant and ISB</li>
                            <li>Up to a maximum of two deferral requests per participant are allowed. If the participant fails to enrol themselves in a programme which starts within 12 calendar months of the start date of the original programme, the amount paid will be forfeited.</li>
                            <li>In case of substitutions, the participant must qualify for the admissions criteria of the alternative programme(s)</li>
                            <li>In case of substitutions where the combined price(s) of the new programme(s) is higher than the enrolled programme price, the difference amount + applicable taxes is payable by the candidate</li>
                            <li>In case of substitutions/deferrals where the combined price(s) of the new programme(s) is lower than the enrolled programme price, the differential amount is forfeited</li>
                            <li>All programme fees must be paid in full before commencing deferral or substitution programmes.</li>
                        </ol>
                        <p className="my-3 fw-bold">
                            ISB Community Values Statement
                        </p>
                        <p className="mb-0">
                            Indian School of Business (ISB) is governed by a set of community values that foster passion for excellence, openness, caring for others, collaboration, initiative, and innovation. ISB considers these values essential for a secure and productive learning environment for all. ISB Executive Education & Digital Learning reserves the right to withdraw an offer of admission if any part of your application contains misrepresentations, or if you engage in, or have engaged in, behaviour that violates ISB Values.
                        </p>
                        <p className="my-3 fw-bold">
                            Discrimination Policy
                        </p>
                        <p className="mb-0">
                            The Indian School of Business does not discriminate against any person based on age, race, caste, colour, gender, gender identity, religion, age, nationality or ethnicity, disability or political views in admission, access, or treatment in its programmes and activities therein.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};
