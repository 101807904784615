import React, { useEffect, useState,Fragment} from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useForm } from "react-hook-form";
import axios from "../../axios-interceptor";
import { base_url, api_error_message, random_number, date_formats } from "../../utilities";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom";
import { Table } from "react-bootstrap";
const EmailTemplateList = () => {
  const history = useHistory();
  let [emailList, setEmailList] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const [loading_flag, setLoading_flag] = useState(true)
  useEffect(() => {
    axios.post(base_url + 'email_template/get_templates', {
    }).then(res => {
      const sortedRecords = res.data.data.sort((a, b) => {
        return b.created_date - a.created_date;
      });
      setEmailList(sortedRecords)
    }).catch(err => {
      console.log(err);
      if (err.response?.statusText == 'Unauthorized' || err.response?.data?.statusText == 'Unauthorized') {
        localStorage.clear();
        history.push('/')
        window.location.reload();
      } else {
        toast.warn(api_error_message, { toastId: random_number });
      }
    })
    setLoading_flag(false)
  }, [])
  const createTemplate = () => {
    history.push("/programmanagementdashboard/createtemplate");
  };

  const Update_template = (item) => {
    history.push('/programmanagementdashboard/createtemplate', { template_info: item })
  }
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filter the emailList based on the search query
 
  const filteredEmailList = emailList.filter((item) => {
    const formattedDate = date_formats.human_date_format(item?.created_date).toLowerCase();
    return (
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.subject.toLowerCase().includes(searchQuery.toLowerCase()) ||
      formattedDate.includes(searchQuery.toLowerCase())
    );
  });
  return (
    <>
      {loading_flag ? (
        <div>
          <img className="atrium_loader"  data-testid="atrium_loader" src="/images/atrium_loader.gif" alt="Loader" />
        </div>
      ) : <>
        <div className="bg-body container ">
          <div>
          <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-7">
                  <span className="SLA_Main_heading" data-testid="main_heading">Email Templates</span>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-5">
                <div className="row">
                  <div className="col-12 col-lg-6 col-sm-6 col-md-6">
                        <input
                          type="text"
                          data-testid="template_list_search"
                          className="form-control custm_input_search "
                          style={{ marginTop: "10px" }}
                          placeholder="Type to Search"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={handleSearchChange}
                        />
                    </div>
                    <div className="col-12 col-lg-6 col-sm-6 col-md-6">
                          <button data-testid="create_template_button"
                            type="button"
                            className="btn my_btn_cls px-4 email-btn"
                            onClick={createTemplate}
                          >
                            Create Template
                          </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <Table className="createSLA-table" bordered={false} data-testid="templatelist_table">
              <thead className="fs-20">
                <tr>
                  <th style={{ width: '20%' }}>Created Date</th>
                  <th style={{ width: '30%' }}>Template Name</th>
                  <th style={{ width: '50%' }}>Subject</th>
                  <th style={{ width: '10%' }}>Action</th>
                </tr>
              </thead>
              <tbody className="fs-5">
                {
                filteredEmailList.length > 0 ?
                filteredEmailList.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <tr>
                        <td>{date_formats.human_date_format(item?.created_date)}</td>
                        <td>{item?.name}</td>
                        <td>{item?.subject}</td>
                        <td className="blue_text text-decoration-underline cursor-pointer"  data-testid={`update_the_template_${index}`} onClick={() => Update_template(item)}>View/Edit</td>
                      </tr>
                    </Fragment>
                  );
                })
                :
                    <td colSpan={4} className="no-template">No Records Found</td>
                }
              </tbody>
              <div className="d-none" data-testid="Table_Length">{filteredEmailList.length}</div>
            </Table>
          </div>
        </div>
      </>
      }
    </>
  );
};
export default EmailTemplateList;
