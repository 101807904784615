export default function Maintenance() {
  return (
    <div className="container-maintenance">
      <div className="create">
        <div className="image_text">
          <span className="font_for_system">System</span>
          <span className="font_for_upgrade">Upgrade In Progress</span>
          <p className="margin_for_p">We are upgrading the system to make your experience better. Please check back in some time.</p>
        </div>
      </div>
    </div>
  );
}
