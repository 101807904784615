import axios from 'axios';

axios.interceptors.request.use(
(request) => {
      // Add authorization header
      const admin_token = localStorage.getItem('Admin_Acesstoken');
      const user_token = localStorage.getItem('User_Accesstoken');
      if (admin_token) {
          request.headers['Authorization'] = `Bearer ${admin_token}`;
          // Add content type header
      }
      else if(user_token){
        request.headers['Authorization'] = `Bearer ${user_token}`;
      }
      // Check if request body is FormData
      if (request.data instanceof FormData) {
        request.headers['Content-Type'] = 'multipart/form-data';
      } else {
        request.headers['Content-Type'] = 'application/json';
      }
      return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;