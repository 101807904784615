import axios from "../../axios-interceptor";
import { useState,useEffect} from "react";
import { useHistory, useLocation } from "react-router-dom";
import { base_url,date_formats, random_number,api_error_message} from "../../utilities";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Modal } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

export const ReEvaluation = () =>{
    const history = useHistory();
  const location = useLocation();
  let [loading_flag,Setloading_flag]=useState(true)
  let [message,setMessage] = useState(false)
  const [programmes, SetAllProgrammes] = useState([]);
  let [course,setCourses] = useState([])
  let [modules_list,setModulesList] =useState([])
  let [ programme_id,setProgrammeId] =useState('')
  let [ course_id,setCourseId] =useState('')
  let [module_id,setModuleId] =useState('')
  let [lesson_id,setLessonId] =useState('')
  let [data,setData] = useState([])
  let [modules, setModules] = useState([]);
  let [lessons, setLessons] = useState([]);
  let [reject_status,setRejectStatus] = useState(false)
  let [approve_status,setApproveStatus] = useState(false)
  let [showModal,setShowModal] =  useState(false)
  let [showMessageModal,setShowMessageModal] = useState(false)
  let [showViewModal,setShowViewModal] =useState(false)
  let [faculty_word_count,setFacultyWordCount]= useState(0)
  let [student_word_count,setStudentWordCount]= useState(0)
  let [program_options, setprogramOptions] = useState([]);
  let [course_options,setCourseOptions]= useState([]);
  let [module_options,setModuleOptions] = useState([]);
  let [lesson_options,setLessonOptions] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [searchOptionsChanged, setSearchOptionsChanged] = useState(false);
  let programs_list = []
  let [item,setItem] =useState([])
  let [item_list,setItemList]=useState([])
  const {
    register,
    handleSubmit,
    setValue,
    reset,control,
    formState: { errors },
  } = useForm({ mode: "all"});
  const columns = [
    {
        name: "S.No",
        selector: (_, index) => index + 1,
        sortable: true,
        width:"5rem",
      },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width:'7rem',
        wrap:true,
        
    },
    {
      name: '',
      selector: row => row.course_name,
      style: {
        display: 'none',
      },
      
  },
  {
    name: '',
    selector: row => row.activity_id,
    style: {
      display: 'none',
    },
    
},
  {
    name: '',
    selector: row => row.module_name,
    style: {
      display: 'none',
    },
    
},
    {
      name: '',
      selector: row => row.user_id,
      style: {
        display: 'none',
      },
      
  },
  {
    name: '',
    selector: row => row.cohort_schedule_id,
    style: {
      display: 'none',
    },
    
},  {
  name: '',
  selector: row => row.reevaluation_id,
  style: {
    display: 'none',
  },
  
},
    {
      name: '',
      selector: row => row.module_id,
      style: {
        display: 'none',
      },
      
  },
  {
    name: '',
    selector: row => row.lesson_id,
    style: {
      display: 'none',
    },
    
},
{
  name: '',
  selector: row => row.course_id,
  style: {
    display: 'none',
  },
  
},
    {
        name: 'Email',
        selector: 'email' ,
        sortable: true,
        cell: row => <div className="hoverable-cell">{row.email}</div>, 
        width:'14rem',
        wrap:true,
    },
    {
        name: 'Questions',
        selector: 'questions',
        sortable: true,
        width:"15rem",
        cell: row => <div className="hoverable-cell" onClick={()=>onClickView(row)}>{row.questions}</div>,
        wrap:true,
    },
    {
        name: 'Faculty Response',
        selector: 'faculty_response',
        sortable: true,
        width:"15rem",
        cell: row => <div className="hoverable-cell" onClick={()=>onClickView(row)}>{row.faculty_response}</div>,
        wrap:true,
    },
    {
        name: 'Student Response',
        selector: row => 'student_response',
        sortable: true,
        width:"15rem",
        cell: row => <div className="hoverable-cell" onClick={()=>onClickView(row)}>{row.student_response}</div>,
        wrap:true,
       
    },
    {
        name: 'Status',
        selector: row =>row.status==0 ?'Pending':row.status==1?'Rejected':row.status==2?'Approved':'',
        sortable: true,
        width:"10rem",
        wrap:true,
        conditionalCellStyles: [
          {
            when: row => row.status==2 ,
            style: {
              color: 'green',
              justifyContent: 'center',
              fontSize:19
            }
          },
          {
            when: row => row.status==0,
            style: {
              color: '#057092',
              justifyContent: 'center',
              fontSize:19
            }
          },
          {
            when: row => row.status==1,
            style: {
              color: 'red',
              justifyContent: 'center',
              fontSize:19
            }
          }
        ]
    },
    {
        name: 'Actions',
        selector: row =><>{row.status==0 && <><button className="btn my_btn_cls mb-2 reevaluation_view_btn rounded" onClick={()=>setReject(row)} style={{backgroundColor:"#A84A52"}} >Reject</button> &nbsp;
        <button className="btn my_btn_cls mb-2 reevaluation_view_btn rounded" style={{backgroundColor:"#2DAF8C"}} onClick={()=>setApprove(row)}>Approve</button> &nbsp;</> }<button className="btn my_btn_cls mb-2 reevaluation_view_btn rounded" style={{backgroundColor:"#057092"}} onClick={()=>onClickView(row)}>View</button></>,
        sortable: true,
        width:"17rem",
      
    },
];

    useEffect(() => {
      
        axios.post(base_url + 'admin/get_program_applications_for_review', {
        }).then(response => {
            SetAllProgrammes(response.data.result)
            response.data.result.map(item=>{
                programs_list.push(item)
            })
            setprogramOptions(
              programs_list.map(({ _id, program_display_name }) => ({ value: _id, label: program_display_name }))
            );
            Setloading_flag(false)
          
        }).catch(err => {
          console.log(err);
          if (err.response?.statusText == 'Unauthorized') {
            localStorage.clear();
            history.push('/')
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        })
       
    }, [])
    const getData = (page) =>{ 
      setHasMore(true)
      axios.post(base_url + 'user/get_list_of_reevaluations', {
        "page" : page
      }).then(response => {
        Setloading_flag(false)
        if (response.data.current_page < page) {
          setHasMore(false);
        }else{
          let result = response.data.result.filter((item,index)=>{
            if(item.status != 3){
             return item
            }
         })
         result.map((item,index)=>{
         let table_data = {
           name:item.user_name ,
           email:item.email,
           questions:item.question,
           faculty_response:item.faculty_response,
           student_response:item.user_answer,
           status:item.status,
           user_id:item.user_id,
           cohort_schedule_id: item.cohort_schedule_id,
           course_id : item.course_id,
           module_id : item.module_id,
           lesson_id :item.lesson_id,
           reevaluation_id : item._id,
           course_name: item.course_name,
           module_name: item.module_name,
           activity_id: item.activity_id
         }
         setData(current=>[...current,table_data])
        })
        }
      }).catch(err => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
    }
    const handleClose = () => {
      setShowViewModal(false)
      setShowModal(false)};
   const handleShowModalClose = () => {
    if(showViewModal){
      setShowViewModal(!showViewModal)
    }
    setData([])
    setPage(1);
    if(!hasMore){
      setSearchOptionsChanged(true)
    }
    setShowMessageModal(false)
   }
  
   const handleCloseViewModal = () => setShowViewModal(false)

    const handleProgrammeChange = (val) => {
      setSearchOptionsChanged(true)
      setData([])
      setModulesList([])
      setProgrammeId(val)
      setCourseId('')
      setModuleId('')
      setLessonId('')
      setCourseOptions([])
      setModuleOptions([])
      setLessonOptions([])
      let programs =[...programmes]
      programs.map(item=>{
          if(item._id == val.value){
              setCourses(item.courses)
              setCourseOptions(
                item.courses.map(({ course_id, course_title }) => ({ value: course_id, label: course_title }))
              );
          }
       })
    }
  
    const handleCourseChange = (val) => {
      setData([])
      setModuleId('')
      setLessonId('')
      setModuleOptions([])
      setLessonOptions([])
      setSearchOptionsChanged(true)
        axios.post(base_url + "get_course_modules", { "course_id": val.value }).then((res) => {
            setModules(res.data.data);
            setModuleOptions(
              res.data.data.map(({ _id, module_title }) => ({ value: _id, label: module_title }))
            );
        }).catch(err => {
            console.log(err);
            if (err.response?.statusText == 'Unauthorized') {
                localStorage.clear();
                history.push('/adminlogin')
                window.location.reload();
            } else {
                toast.warn(api_error_message, { toastId: random_number });
            }
        })
        // setData([])
        setModulesList([])
        setCourseId(val)
      
    }
    const handleModuleChange = (val) =>{
      setData([])
      setLessonId('')
      setLessonOptions([])
      setSearchOptionsChanged(true)
        setModuleId(val)
        axios
      .post(base_url + "lessons/get_lessons_by_moduleid", {
        module_id: val.value,
      })
      .then((res) => {
        setLessons(res.data.data);
        setLessonOptions(
          res.data.data.map(({ lesson_id, title }) => ({ value: lesson_id, label: title }))
        );
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      });
    }
    const handleLessonChange = (val) =>{
        setData([])
        setLessonId(val)
        getSearchedData(val)
        setSearchOptionsChanged(true)
    }
    const getSearchedData = (id)=>{
        setHasMore(true)
       
      if(programme_id.value && course_id.value && module_id.value && id.value){
        axios.post(base_url + 'user/get_list_of_reevaluations', {
          "lesson_id": id.value,
          "module_id": module_id.value,
          "course_id": course_id.value,
          "program_id": programme_id.value,
          "page" : page
        }).then(response => {
          if (response.data.current_page < page) {
            setHasMore(false);
            setSearchOptionsChanged(false)
          }else{
            let result = response.data.result.filter((item,index)=>{
              if(item.status != 3){
               return item
              }
           })
            result.map((item,index)=>{
            let table_data = {
              name:item.user_name ,
              email:item.email,
              questions:item.question,
              faculty_response:item.faculty_response,
              student_response:item.user_answer,
              status:item.status,
              user_id:item.user_id,
          cohort_schedule_id: item.cohort_schedule_id,
          course_id : item.course_id,
          module_id : item.module_id,
          lesson_id :item.lesson_id,
          reevaluation_id : item._id,
          course_name: item.course_name,
           module_name: item.module_name,
          activity_id: item.activity_id
            }
            setData(current=>[...current,table_data])
          })
          }
        }).catch(err => {
          console.log(err);
          if (err.response?.statusText == 'Unauthorized') {
            localStorage.clear();
            history.push('/')
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        })
      }
    }
    const setReject = (item) =>{
      setItem(item)
      setShowModal(true)
      setRejectStatus(true)
      setApproveStatus(false)
      if(showViewModal){
        setShowViewModal(!showViewModal)
      }
    }
    const setApprove = (item) =>{
      setItem(item)
      setShowModal(true)
      setRejectStatus(false)
      setApproveStatus(true)
      if(showViewModal){
        setShowViewModal(!showViewModal)
      }
    }
  
    const onClickView = (item) =>{
      setItemList(item)
      const result = item.faculty_response
        .split(/\s+/)
        .map(element => element.trim())
        .filter(element => element !== '')
     
      setFacultyWordCount(result.length)
      const res = item.student_response
      .split(/\s+/)
      .map(element => element.trim())
      .filter(element => element !== '')
      setStudentWordCount(res.length)
      setItem(item)
      console.log(item)
      // setShowModal(false)
      // setShowMessageModal(false)
      setShowViewModal(true)
    }
    const onClickYes = (approve_status,reject_status) =>{
      axios.post(base_url + 'user/update_reevaluation_status', {
"reevaluation_id":item.reevaluation_id??item_list.reevaluation_id,
"status":reject_status?1:approve_status?2:'',
"user_id": item.user_id??item_list.user_id,
"course_id": item.course_id??item_list.course_id,
"module_id": item.module_id??item_list.module_id,
"lesson_id": item.lesson_id??item_list.lesson_id,
"cohort_schedule_id": item.cohort_schedule_id??item_list.cohort_schedule_id,
"activity_id":item.activity_id??item_list.activity_id
      }).then(res => {
        if(res.status == 200){
          setShowModal(false)
          setShowMessageModal(true)
        }
      }).catch(err => {
        console.log(err);
        if (err.response?.statusText == 'Unauthorized') {
          localStorage.clear();
          history.push('/')
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      })
    
    }
    const fetchMoreData = () => {
      setPage(prevPage => prevPage + 1);
  };
  
    useEffect(() => {
      if(!searchOptionsChanged){
        getData(page)
      }else{
        getSearchedData(lesson_id)
      }
    },[page]);
    useEffect(() => {
      if(searchOptionsChanged)
      {
        setPage(1);
      }
    }, [searchOptionsChanged]);
return (
    <>
        <div className="bg-body container active-reevaluation">
        {loading_flag?<>
                <div className="mt-5">
                   <img className="atrium_loader_custom" src="/images/atrium_loader.gif" alt="Loader" />
                </div>
            </>:<>
           {message? <h3>No Records Found</h3>:<>
            <div>
               <span className="SLA_Main_heading">Active Programs & SLA's</span>
            </div>
           <>
           <div className="row mt-2">
           <div className="col-lg-6">
            <label className="program_sla_lable">Select Programme</label>
            <Controller
                          as={Select}
                          name="programme_name"
                          placeholder="Programe Name"
                          options={program_options}
                          className="form-select form-select mb-2"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                          onChange={handleProgrammeChange}
                            //  onChange={(event) => handleProgrammeChange(event.target.value)}
                             value={programme_id}
                              name={name}
                              options={program_options}
                              isSearchable={true}
                              placeholder="Select Programme"
                              // classNamePrefix="custom-select-gradebook"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                  {/* <select
                  
                    id="programms"
                    className="form-select form-select mb-2"
                    aria-label=".form-select example"
                    name="programme_name"
                    value={programme_id}
                    onChange={(event) => handleProgrammeChange(event.target.value)}
                  >
                    <option selected value=""disabled>Select Programme</option>
                    {programmes.map((app) => (
                      <option key={app._id} value={app._id}>
                        {app.program_display_name}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="col-lg-6">
                <label className="program_sla_lable">Select Course</label>
                <Controller
                          as={Select}
                          name="course_name"
                          options={course_options}
                          className="form-select form-select mb-2"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                          onChange={handleCourseChange}
                             value={course_id}
                              name={name}
                              options={course_options}
                              isSearchable={true}
                              placeholder="Select Course"
                              // classNamePrefix="custom-select-gradebook"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                  {/* <select
                 
                    id="course"
                    className="form-select form-select mb-2"
                    aria-label=".form-select example"
                    name="course_name"
                    value={course_id}
                    onChange={(event) => handleCourseChange(event.target.value)}
                  >
                    <option selected value=""disabled>Select the Course </option>
                    {course.map((app) => (
                      <option key={app._id} value={app.course_id}>
                        {app.course_title}
                      </option>
                    ))}
                  </select> */}
                 
                </div>
            </div>  
            <div className="row mt-2"> 
                <div className="col-lg-6">
            <label className="program_sla_lable">Select Module</label>
            <Controller
                          as={Select}
                          name="module_name"
                          options={module_options}
                          className="form-select form-select mb-2"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                             onChange={handleModuleChange}
                             value={module_id}
                              name={name}
                              options={module_options}
                              isSearchable={true}
                              placeholder="Select Module"
                              // classNamePrefix="custom-select-gradebook"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                  {/* <select
                    
                    id="module"
                    className="form-select form-select mb-2"
                    aria-label=".form-select example"
                    name="module_name"
                    value={module_id}
                    onChange={(event) => handleModuleChange(event.target.value)}
                  >
                    <option selected value=""disabled>Select Module</option>
                    {modules.map((app) => (
                      <option key={app._id} value={app._id}>
                        {app.module_title}
                      </option>
                    ))}
                  </select> */}
                </div>
                <div className="col-lg-6">
            <label className="program_sla_lable">Select Lesson</label>
            <Controller
                          as={Select}
                          name="lesson_name"
                          options={lesson_options}
                          className="form-select form-select mb-2"
                          control={control}
                          rules={{ required: true }}
                          render={({ onChange, value, name }) => (
                          <Select
                             onChange={handleLessonChange}
                             value={lesson_id}
                              name={name}
                              options={lesson_options}
                              isSearchable={true}
                              placeholder="Select Lesson"
                              // classNamePrefix="custom-select-gradebook"
                              // Add a dummy option if program_options is empty
                              noOptionsMessage={() => "No options available"}
                            />
                          )}
                        />
                  {/* <select
                    id="lesson"
                    className="form-select form-select mb-2"
                    aria-label=".form-select example"
                    name="lesson_name"
                    value={lesson_id}
                    onChange={(event) => handleLessonChange(event.target.value)}
                  >
                    <option selected value=""disabled>Select Lesson</option>
                    {lessons.map((app) => (
                      <option key={app.lesson_id} value={app.lesson_id}>
                        {app.title}
                      </option>
                    ))}
                  </select> */}
                </div>
           </div>
             <div className="mt-2">
             <div
                            id="scrollableDiv"
                            style={{
                              height: 300,
                              overflow: 'auto',
                              display: 'flex',
                              flexDirection: 'column'

                            }}
                        >
                           <InfiniteScroll
                            dataLength={data.length}
                            next={fetchMoreData}
                            hasMore={hasMore}
                            // loader={<img src="/images/loading_dots.gif" className="dots_loader" alt="Loader" ></img>}
                            //style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
                            //inverse={true} //
                            scrollableTarget="scrollableDiv"
                            scrollThreshold="98%"
                            
                            // endMessage={<p>No more records to load</p>}
                            
                               >
                <DataTable  responsive={true} className="active_program-table" columns={columns} data={data}  />

                           
                          
                        </InfiniteScroll>
                      </div>   
             </div>
           
            </>
            </>}
            </>}
        </div>
        {showModal && (
        <Modal
          show={showModal}
          onHide={handleClose}
          centered
          id="hero_image_modal"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
           <Modal.Header closeButton>
        </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              {reject_status && <p className="modal_text fw-semibold">
              Are you sure, you want to reject the Re-evaluation request?
              </p>}
              {approve_status && <>
                <p className="modal_text fw-semibold">
              Are you sure, you want to approve the Re-evaluation request?
              </p>
              <p className="approve_note text-center">Note: on approving points will be updated from 1 to 2</p>
              </>}
            </div>
            <div className="text-center pb-2 redo_btn_container">
              <button
                className="btn my_gray_btn_cls  fw-bold  m-0 px-4"
                onClick={handleClose}
              >
                
                No
              </button>
              &nbsp;&nbsp;
              <button
                className="btn my_btn_cls mt-0 my_share_btn m-0 px-4" 
                onClick={()=>onClickYes(approve_status,reject_status)}
              >
                Yes
              </button>
            </div>
          </Modal.Body>
        </Modal>
      )}
      {showMessageModal && (
        <Modal
          show={showMessageModal}
          onHide={handleShowModalClose}
          centered
          id="hero_image_modal"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
           <Modal.Header closeButton>
        </Modal.Header>
          <Modal.Body>
            <div className="text-center">
            <h3 className="blue_text text-center ">{reject_status?'Rejected!':'Approved!'}</h3>
              {reject_status && <p className="modal_text fw-semibold">
              Re-evaluation Rejected
              </p>}
              {approve_status && <>
                <p className="modal_text fw-semibold">
                Re-evaluation Successfully Submitted.
              </p>
              </>}
            </div>
            <div className="text-center pb-2 redo_btn_container">
              <button
                className="btn my_gray_btn_cls  fw-bold  m-0 px-4"
                onClick={handleShowModalClose}
              >
                Close
              </button>
              &nbsp;&nbsp;
            
            </div>
          </Modal.Body>
        </Modal>
      )}
       {showViewModal && (
        <Modal
          show={showViewModal}
          onHide={handleCloseViewModal}
          centered
          id="view_modal"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
           <Modal.Header closeButton>
            <p className="view_heading"> {item.name??item_list.name} &gt;{item.course_name??item_list.course_name} &gt; {item.module_name??item_list.module_name} </p>
        </Modal.Header>
          <Modal.Body>
          <p>Question</p>
              <div className="gray_background">{item.questions}</div>
              <br></br>
            <div className=" row">
           
           <div className="col-6">
           <p>Faculty Response</p>
           <div> <p className="reevaluation_word_count">Word Count:{faculty_word_count}</p>
            {item.faculty_response}</div>
          
           </div>
           <div className="col-6">
           <p>Student Response</p>
           <div className="gray_background">  <p className="reevaluation_word_count">Word Count: {student_word_count} </p>
            {item.student_response}
           </div></div>
          
            </div>
            
          </Modal.Body>
          <Modal.Footer>
            {/* <div className="row">
            <div className="col-7">Current Points <b>1</b></div>
            <div className="col-5">On Approval <b>2</b></div>
            </div> */}
            <div >
              <span className="mr-3"> Current Points <b>1</b></span>
           {/* &nbsp;&nbsp; */}
           <span className="mr-2">On Approval <b>2</b></span>
            
            </div>
            {item.status==0 && <>
          <div className="pb-2">
              <button
                className="btn my_gray_btn_cls  fw-bold  m-0 px-4"
                onClick={setReject}
                // disabled={item.status==0?false:true}
              >
                Reject Request
              </button>
              &nbsp;&nbsp;
              <button
                className="btn my_btn_cls mt-0 my_share_btn m-0 px-4"
                onClick={setApprove}
                // disabled={item.status==0?false:true}
              >
                Approve
              </button>
             
            </div></>}
        </Modal.Footer>
        </Modal>
      )}
        </>
)
}