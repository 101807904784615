import { useState,Fragment } from "react";
import { Chart as ChartJS, registerables } from 'chart.js';
 import { Bar,Doughnut } from "react-chartjs-2";
import { Tab, Tabs } from "react-bootstrap";
import { useEffect } from "react";
import React from 'react';
import {
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
ChartJS.register(...registerables);
const graph_image = <><img src="/images/pie-chart.svg"></img></>
const list_image= <><img src="/images/list.svg"></img></>
export const BarChart = (props) => {
 let [table_data,setTableData] =useState([])
  const [key, setKey] = useState("GraphView");
    const [chartData, setChartData] = useState({
      labels: props.labels,
      datasets: [
        {
          // label: '# of Votes',
          data: props.dataLables,
          backgroundColor: [
           "#4EA9AC",
           "#287796",
           "#D4A74A",
           "#3F95D0"
          ],
          hoverbackgroundColor : [
            "rgba(78,169,172,0.2)",
            "rgba(40,119,150,0.2)",
            "rgba(212,167,74,0.2)",
            "rgba(63,149,208,0.2)",
          ]
          
          // borderWidth: 1,
        },
      ],
    })
   
      useEffect(()=>{
        setChartData({...chartData, labels: props.labels});
        let sample_data_array = []
        if(props.labels.length == props.data.length){
          props.labels.filter((item,index)=>{
           sample_data_array.push({
              "label":  item.toString().length > 30 ? item.slice(0, 30)+ '...' : item ,
              "percentage": props.data[index]
            })
          })
        }
      
        setTableData(sample_data_array)
      
      },[props])
      
  return (
    <> 
    <h4 data-testid="peer_responses" className="blue_text text-center">Peer Responses</h4>

          <Tabs
    id="controlled-tab-example"
    activeKey={key}
    onSelect={(k) => setKey(k)}
  > 
  <Tab eventKey="GraphView"
                         title={<span>{graph_image} </span>}
                         className="Active">
                           <div className="chart-container pt-2">
                         
      <br></br>
      <Doughnut data={chartData} options={{
        responsive: true,
        plugins: {
          tooltip: {
						callbacks: {
							label: function(tooltipItems) {
								if (tooltipItems) {
									return tooltipItems.formattedValue + "%"
								}
							}
						}
					},
          legend: {
            position:"bottom",
              // display: true,
              labels: {
                usePointStyle:true,
                padding:window.innerWidth==1024?15:20,
                pointStyle:"circle",
              
              }
          }
      }
      }}/>
 {/* <Bar
        data={chartData}
        options={{
            responsive: true,
          
            scales: {
                x: {
                  grid: {
                    display: false,
                    drawTicks: false,
                  },
                
                },
          
                y: {
                    min:0,
                  grid: {
                    display: false,
                    drawTicks: false,
                    
                  },
                },
              },
            
          plugins: {
            legend: {
              display: false
            },
           
          }
        }}
      /> */}
       </div>
                         </Tab>
                         <Tab eventKey="TableView"  title={<span>{list_image} </span>}>
                         <div className="pt-2">
                        
                          <table>
                          <thead>
                          <tr>
    <th>Options</th>
    <th>%</th>
  </tr>
  </thead>
  <tbody>
  {table_data.length != 0 && table_data.map((item,index)=>{
    return(
      <Fragment key={index}>
      <tr>
    
        <td>{item.label}</td>
        <td>{item.percentage}</td>
      </tr>
      </Fragment>
    )
  })}
  </tbody>
                          </table>
                         </div>
                         </Tab>
  </Tabs>
    
     
  </>
  );
};