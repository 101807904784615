const YearCompany = [
  {
    value:0,
    text:"0-0 years"
  },
  {
    value:1,
    text:"0-1 years"
},
{
    value:2,
    text:"2-3 years"
},
{
    value:4,
    text:"4-5 years"
},
{
    value:6,
    text:"6-7 years"
},
{
    value:8,
    text:"8-10 years"
},
{
    value:13,
    text:"11-15 years"
},{
    value:18,
    text:"16-20 years"
},
{
    value:20,
    text:"20+ years"
}
];
let Years = [];
const currentYear = new Date().getFullYear();
for (let year = 2001; year <= currentYear; year++) {
  Years.push(year);
}
const CurrentYears = Years;
const LearnProgramList = [
  "Online advertisement - Google",
  "Search engine (e.g., Google, Bing)",
  "Social media (e.g., Facebook, LinkedIn, Twitter)",
  "Word of mouth (e.g., from a friend, family member, colleague)",
  "ISB website",
  "Email from ISB",
  "Referred by a past / current student",
  "Referred by your employer",
  "Referred by someone from ISB",
];
const InfluencerList = [
  "Curriculum and courses offered",
  "Recommended by a colleague / friend",
  "Networking opportunities",
  "Availability of flexible learning",
  "Alumni Network",
  "Other",
];

const Industries = [
  "Accounting/Auditing",
  "Advertisement/Media/Communication/PR/EntertainmentBanking ,Financial Services an",
  "Advertising/Media/Comm/PR/Entertainment - Others",
  "Aerospace",
  "Agribusiness",
  "Agriculture",
  "AI/ML",
  "Airlines",
  "Architecture & Planning",
  "Armed Forces",
  "Arts/Culture",
  "Asset Management & Custody Banks",
  "Automobile and Manufacturing",
  "Automobiles",
  "Automobiles & Components",
  "Automotive & Ancillary",
  "Aviation/Aerospace",
  "Banking",
  "Banking and finance",
  "Banking, Financial Services and Insurance",
  "Banks/Financial Institutions/ Financial Services/Insurance - Others",
  "Brand Consulting",
  "Brand Management & Communication",
  "Broadcasting",
  "Building Products/Construction Materials",
  "Business Analytics Services",
  "Business Consulting",
  "Business to Business",
  "Capital Markets",
  "Cement",
  "Chemical/Fertlizers/Pesticides",
  "Chemicals",
  "Commercial Printing",
  "Communication Equipment",
  "Compensation",
  "Computer Games",
  "Conglomerate",
  "Construction",
  "Construction & Engineering",
  "Consulting & Professional/Advisory Services",
  "Containers & Packaging",
  "Corporate Gifting",
  "Cyber security",
  "Defence & Space",
  "Digital & Social Media Marketing",
  "Diversified Financial Services",
  "Diversified Telecommunication Services",
  "Education",
  "Education/Teaching & Training",
  "Edu-Tech",
  "Electrical",
  "Electrical Components & Equipment",
  "Equipment Manufacturing",
  "Equipment Suppliers",
  "E-commerce",
  "Energy",
  "Energy - Others",
  "Entertainment",
  "Environmental & Facilities Services/Waste Management",
  "FMCG (fast-moving consumer goods)",
  "FMCG/Retail/Consumer Durables/Ecommerce/Agri",
  "FMCG, Conglomerate, and Retail",
  "Fin-Tech",
  "Financial Exchanges & Data (derivatives, rating agencies etc.)",
  "Financial Services/Financial Institutions",
  "Food/Beverage/Tobacco",
  "GCC - BFSI",
  "Gems/Jewellery",
  "Gaming",
  "Geology",
  "GCC - BFSI",
  "Healthcare",
  "Healthcare Distributors",
  "Healthcare Equipment & Services",
  "Healthcare Facilities",
  "Healthcare Services",
  "Healthcare-Tech",
  "Healthcare and Pharma",
  "Hospitality",
  "Hospitality and tourism",
  "Hospitals/Healthcare Providers",
  "Household Durables",
  "Human Resource/Employment Services",
  "Industrial Machinery",
  "Information technology",
  "Information technology (IT) and software",
  "Infrastructure/Construction",
  "Infrastructure/Construction/Real Estate",
  "Insurance",
  "International Affairs",
  "Internet Software & Services",
  "Investment Banking",
  "Investment Banking & Brokerage",
  "Investment Management, Wealth Management & Brokerage",
  "IT Consulting",
  "IT Services",
  "ITES, Professional Services and R&D",
  "Leadership development",
  "Legal Services",
  "Leisure",
  "Logistics/Supply Chain",
  "Logistics and transportation",
  "Machinery",
  "Management Consulting",
  "Manufacturing - Others",
  "Maritime",
  "Marketing & Advertising",
  "Mechanical Or Industrial Engineering",
  "Media",
  "Media & Entertainment",
  "Media and entertainment",
  "Media, Telecom, and E-commerce",
  "Medical Devices",
  "Mining & Metals",
  "Mining & Minerals",
  "Mobile gaming app",
  "Mobility Technology/Services",
  "Mortgage/Consumer Finance",
  "Mutual Funds",
  "NBFC",
  "NGO/Not for Profit",
  "Non Profit",
  "Office/Home Services & Supplies",
  "Oil & Gas",
  "Oil, Gas & Consumable Fuels",
  "Oil, Gas, Power and Energy",
  "Operations",
  "Operations Consulting",
  "Paper",
  "Paper & Forest Products",
  "Pension Funds",
  "Petroleum and natural gas",
  "Pharma/Biotech/Healthcare/Hospitals",
  "Pharmaceuticals/Biotechnology/Life Sciences",
  "Political Organisation",
  "Power & Energy",
  "Printing",
  "Private Equity, Venture Capital & Hedge Funds",
  "Processing/Outsourced Services",
  "PSUs",
  "Public Relations",
  "Public Relations & Communications",
  "Publishing",
  "Real Estate",
  "Recruitment/Staffing",
  "Research Services",
  "Retail",
  "Retailing/Department Stores/Merchandising/Distributors",
  "Rubber",
  "Security & Alarm Services",
  "Semiconductors",
  "Shipbuilding",
  "Social Enterprise",
  "Software",
  "Software Development",
  "Sports Management",
  "Steel",
  "Strategy Consulting",
  "Technology",
  "Technology Distributors",
  "Technology Hardware & Equipment/Peripherals",
  "Telecommunications",
  "Television & Film Industry",
  "Textile",
  "Textiles",
  "Textiles/Apparel&Fashion/Luxury Goods",
  "Training/Skilling",
  "Transportation",
  "Transportation/Logistics",
  "Travel & Tourism",
  "Travel and Hospitality",
  "Urban Mobility",
  "Utilities",
  "Venture Capital & Private Equity",
  "Water supplier",
  "Wholesaling/Trading/Export/Import",
  "Wireless",
  "Others",
];
export  {YearCompany,Industries,InfluencerList,LearnProgramList,CurrentYears} ;
