import React from 'react'
import { Container } from "react-bootstrap";
const AdminDashboardRoleB = () => {
    return (
        <>
     
             
                        <div>
                            <Container className='bg-transparent  pb-0'>
                                <h6 className="dashboard-heading">Welcome to the Program Manager! </h6>
                                <h5 className="card-title" >Explore the options on the left side of the menu navigator.</h5>
                            </Container>
                           
                        </div>
             
        </>
    );
}

export default AdminDashboardRoleB