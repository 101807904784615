import React, { useState } from 'react'
import { VideoComponent } from './videoComponent';
import { StreamingVideoComponent } from './StreamingVideoComponent';
import { useEffect } from 'react';

export const VideoCheck = ({data,i}) => {

    const [videoCheck,setVideoCheck]=useState("")
    useEffect(()=>{
        if(data && data.video && data.video.includes("panopto")){
          setVideoCheck("panopto")
        }
        else{
          setVideoCheck("aws")
        }
    },[data])

  return (
    <>
    {videoCheck=="panopto"?(
       <VideoComponent data={data} i={i} />
    ):
    (videoCheck=="aws" && <StreamingVideoComponent data={data} i={i} />)
    }
    </>
  )
}
