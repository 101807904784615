import React from "react";
import { Controller,useForm } from "react-hook-form";
import List from "../listofStates";
import Countries from "../ListofCountries";
import { Link, useLocation, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import { formatSentence,base_url, date_formats,Program_type, setUrl,default_country_code,default_dial_code,api_error_message,random_number, standalone_program_type, user_maintenance_type,full_payment,emi_payment, payment_paid } from "../utilities";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Select from "react-select";
import axios from '../axios-interceptor';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Modal } from "react-bootstrap";
import { addressAppplicationFormValidations } from '.././formValidationsRules'
import withMaintenanceCheck from "../HigherOrderComponents/maintenancePageChecker";
const AddressApplicationForm = (props) => {
  let history=useHistory()
  let [billingChecked, setBillingChecked] = useState(false);
  let [loading_flag, setLoading_flag] = useState(true);
  let [showModal, setShowModal] = useState(false);
  let [showTermsConditionsModal, setTermsConditionsShowModal] = useState(false);
  const colCls = "col-lg-6 col-md-6 col-sm-12 my_custom_col";
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    clearErrors,
    formState: { errors },
  } = useForm({ mode: "all" });
  let [countryOptions, setCountryOptions] = useState([]);
  let [stateOptions, setStateOptions] = useState([]);
  let [cityOptions, setCityOptions] = useState([]);

  const [selectedPostalCountryOption, setSelectedPostalCountryOption] = useState({});
  const [selectedPostalStateOption, setSelectedPostalStateOption] = useState({});
  const [selectedPostalCityOption, setSelectedPostalCityOption] = useState({});

  const [selectedBillingCountryOption, setSelectedBillingCountryOption] = useState({});
  const [selectedBillingStateOption, setSelectedBillingStateOption] = useState({});
  const [selectedBillingCityOption, setSelectedBillingCityOption] = useState({});

  let [billingStateOption, billingSetStateOption] = useState([]);
  let [billingSelectedStateOption, billingSetSelectedStateOption] = useState(null);
  let [stateOption, setStateOption] = useState([]);
  let [program_name,setProgramName] = useState('')
  let [start_date,setStartDate] =useState('')
  let [program_type,setProgramType] = useState('')
  let [selectedStateOption, setSelectedStateOption] = useState(null);
  let [user_Details,setUser_Details]=useState(null);
  let [disable_flag,setDisableFlag]= useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState(default_country_code);
 const [dialCode, setDialCode] = useState(default_dial_code);
 const [billingCountryCode, setBillingCountryCode] = useState(default_country_code);
 const [billingDialCode, setBillingDialCode] = useState(default_dial_code);
  const [mobileErr, setMobileErr] = useState(false);
  const [billingPhone, setBillingPhone] = useState("");
  const [billingMobileErr, setBillingMobileErr] = useState(false);
  let [program_cost,setProgramCost] = useState('')
  let [fullpayment_flag,setFullPaymentFlag] = useState(true)
  let [installment_flag,setInstallmentFlag] =useState(false)
  let [checkBox,setCheckBox]=useState(false)
  let [program_split_payments,setProgramSplitPayments] =useState([])
  let [InvoiceDetails,setInvoiceDetails] = useState([{id:1,value:"Self"},
    {id:2,value:"Company"},
    {id:3,value:"Others"}])
  let [InvoicePaidBy, setInvoicePaidBy] = useState(null)
  let [userData, setUserData] = useState({
    id: localStorage.getItem("setPassword_UserId"),
    street_address_1:'',
    street_address_2:'',
    city: "Hyderabad",
    postcode: "",
    postalcountry: "India",
    BillingCountry:'India',
    state: "Telangana",
    BillingState:'Telangana',
    company: "",
    company_gst_number:"",
    billing_telephone:"",
    paid_by:""
  });

   const setStateList=()=>{
    if(JSON.parse(localStorage.getItem('app_user_details'))){
    Countries.map((cntry) => {
      let app_user_details = JSON.parse(localStorage.getItem('app_user_details'))
      app_user_details.country = app_user_details.country? app_user_details.country:'India';
      app_user_details.state = app_user_details.state? app_user_details.state:'Telangana';
      if (cntry.country == app_user_details.country) {
        setStateOption(cntry.states);
        setSelectedStateOption(app_user_details.state)
        setValue('state',app_user_details.state, { shouldDirty: true })
      }
      app_user_details.billing_country = app_user_details.billing_country? app_user_details.billing_country:'India';
      app_user_details.billing_state = app_user_details.billing_state? app_user_details.billing_state:'Telangana';
      if(cntry.country == app_user_details.billing_country) {
        billingSetStateOption(cntry.states);
        billingSetSelectedStateOption(app_user_details.billing_state)
        setValue('BillingState',app_user_details.billing_state, { shouldDirty: true })
      }
    })
  }
}
  
useEffect(() => {
  setCountryOptions(
    Countries.map((item,index) => ({ value: index, label: item.country }))
  );
  
  if(localStorage.getItem('setPassword_UserId'))
  {  
    axios.post(base_url+'isb/user/get_user_details',{"user_id": localStorage.getItem('setPassword_UserId')})
    .then(res=>{
      axios
      .post(base_url + "get_program_data_by_id", {
        program_id: localStorage.getItem('setPassword_programId'),
        user_id:localStorage.getItem('user_id') || localStorage.getItem('setPassword_UserId')
      })
      .then((res) => { 
        if(res.data.data.is_paid ===payment_paid){
          if(localStorage.isLoggedIn =="true"){
            history.push("/dashboard")
          }else{
            history.push("/")
          }          
        }else{
          setProgramName(res.data.data.learning_track_name)
          setStartDate(res.data.data.start_date)
          setProgramType(res.data.data.program_type)
          setProgramCost(res.data.data.program_cost)
          setProgramSplitPayments(res.data.data.installment_details)
          setLoading_flag(false);
          if(res.data.data.payment_date < date_formats.convert_current_date_without_time()){
          setShowModal(true)
          }
        }
        

      })
      .catch(err=>{
        console.log(err);
        if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
          localStorage.clear();
          history.push('/')
          window.location.reload();
        }else{
          toast.warn(api_error_message,{toastId:random_number});
        }
      })
      localStorage.setItem('app_user_details',JSON.stringify(res.data?.result[0]))
      setUser_Details(res.data?.result[0])
      let userDetails=res.data?.result
      if(userDetails.length>0)
      {   

        let payee_country_code = userDetails[0].payee_mobile_code ? userDetails[0].payee_mobile_code.startsWith("+") ? userDetails[0].payee_mobile_code.replace('+', '').trim():userDetails[0].payee_mobile_code : dialCode;
        let billing_country_code = userDetails[0].billing_telephone_code? userDetails[0].billing_telephone_code.startsWith("+") ? userDetails[0].billing_telephone_code.replace('+', '').trim():userDetails[0].billing_telephone_code:dialCode;


        setPhone(userDetails[0]?.payee_mobile? (payee_country_code) + userDetails[0]?.payee_mobile: (payee_country_code) +userDetails[0]?.mobile, { shouldDirty: true });

        setBillingPhone(userDetails[0]?.billing_telephone ? (billing_country_code) + userDetails[0]?.billing_telephone: "");

        setDialCode(payee_country_code);
        setBillingDialCode(billing_country_code)

        setMobileErr(false);
        setBillingMobileErr(false)


        setValue('payeesFirstName',userDetails[0]?.payee_first_name? userDetails[0]?.payee_first_name:userDetails[0]?.first_name, { shouldDirty: true })
        setValue('payeesLastName',userDetails[0]?.payee_last_name? userDetails[0]?.payee_last_name:userDetails[0]?.last_name, { shouldDirty: true })
        setValue('payeesEmail',userDetails[0]?.payee_email? userDetails[0]?.payee_email :userDetails[0]?.email, { shouldDirty: true })
        setValue('postalAddress1',userDetails[0]?.street_address_1, { shouldDirty: true })
        setValue('postalAddress2',userDetails[0]?.street_address_2, { shouldDirty: true })
        setValue('postalAddrPostCode',userDetails[0]?.postcode, { shouldDirty: true })

        handlePostalPopulate(userDetails[0])
        setBillingChecked(userDetails[0]?.is_billing_address)
        setDisableFlag(userDetails[0]?.is_billing_address)
        setValue('BillingStreetAddress1',userDetails[0]?.billing_street_address_1, { shouldDirty: true })
        setValue('BillingStreetAddress2',userDetails[0]?.billing_street_address_2, { shouldDirty: true })
        setValue('BillingPostalCode',userDetails[0]?.billing_postcode, { shouldDirty: true })

        handleBillingPopulate(userDetails[0])
        setValue('Billingcompany',userDetails[0]?.company, { shouldDirty: true })
        setValue('PanCompanyId',userDetails[0]?.company_id, { shouldDirty: true })
        setValue('Billingcompanygst',userDetails[0]?.company_gst_number, { shouldDirty: true })
        setValue('PaidBy',userDetails[0]?.paid_by, { shouldDirty: true }) 
        setValue('PanId',userDetails[0]?.pan_id, { shouldDirty: true }) 
        setInvoicePaidBy(userDetails[0]?.paid_by)
        setStateList()
      }
    })
    .catch(err=>{
      console.log(err);
      if(err.response?.statusText=='Unauthorized' || err.response?.data?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })

  }
  else{
    //console.log("setPassword_UserId not found");
    setStateList();
    setLoading_flag(false)
    history.push('/');

  }

}, [])

const handlePostalPopulate=(userDetails)=>{
    let postalcountry= userDetails.country??"India"
    let postalstate=userDetails.state??"Telangana"
    let postalCity=userDetails?.city??"Hyderabad"
    Countries.map((item,index)=>{
      if(item.country===postalcountry)
      {
        setSelectedPostalCountryOption({value:index,label:postalcountry})
        setValue('postalcountry', postalcountry,{ shouldDirty: true });
        setStateOptions(item.states.map((stateitem,index2) => ({ value: index2, label: stateitem })));
          
        //state
        item.states.map((stateitem2,index3) =>{
          if(stateitem2===postalstate)
          {
            setSelectedPostalStateOption({value:index3,label:stateitem2})
            setValue("postalstate", stateitem2,{ shouldDirty: true });

                  //city
                  if(postalCity&&postalcountry=="India")
                  {
                    if(item.cities)
                    {
                      for (const key in item.cities) {
                        if (Object.hasOwnProperty.call(item.cities, key)) {
                          const citiArray = item.cities[key];
            
                          if(key===postalstate)
                          {
                            setCityOptions(
                              citiArray.map((city,index)=>({value: index, label: city}))
                              )
  
                              citiArray.map((citiItem,citiindex)=>{
                                if(citiItem===postalCity)
                                {
                                  handleCityChange({value:citiindex,label:citiItem},"postalCity")
                                }
                              })
            
                          }
                          
                        }
                      }
                    
                    }

                  }
                  else{
                    setValue('postalCity',postalCity, { shouldDirty: true })
                  } 
          }
        })
      
      }
    })
}


const handleBillingPopulate=(userDetails)=>{
    let BillingCountry= userDetails.billing_country??"India"
    let BillingState=userDetails.billing_state??"Telangana"
    let BillingCity=userDetails?.billing_city
    Countries.map((item,index)=>{
      if(item.country===BillingCountry)
      {
        setSelectedBillingCountryOption({value:index,label:BillingCountry})
        setValue('BillingCountry', BillingCountry,{ shouldDirty: true });
        setStateOptions(item.states.map((stateitem,index2) => ({ value: index2, label: stateitem })));
          
        //state
        item.states.map((stateitem2,index3) =>{
          if(stateitem2===BillingState)
          {
            setSelectedBillingStateOption({value:index3,label:stateitem2})
            setValue("BillingState", stateitem2,{ shouldDirty: true });

                  //city
                  if(BillingCity&&BillingCountry=="India")
                  {
                    if(item.cities)
                    {
                      for (const key in item.cities) {
                        if (Object.hasOwnProperty.call(item.cities, key)) {
                          const citiArray = item.cities[key];
            
                          if(key===BillingState)
                          {
                            setCityOptions(
                              citiArray.map((city,index)=>({value: index, label: city}))
                              )
  
                              citiArray.map((citiItem,citiindex)=>{
                                if(citiItem===BillingCity)
                                {
                                  handleCityChange({value:citiindex,label:citiItem},"BillingCity")
                                }
                              })
            
                          }
                          
                        }
                      }
                    
                    }

                  }
                  else{
                    setValue('BillingCity',BillingCity, { shouldDirty: true })
                  } 
          }
        })
      
      }
    }) 
}
const handleOnChange = (value, country) => {
  setPhone(value.toString());
  setDialCode(country.dialCode)
}

const handleBillingOnChange = (value, country) => {
  setBillingPhone(value.toString());
  setBillingDialCode(country.dialCode);
}



  const listofCountries = function (countries) {
    return (
      <option key={countries.country} value={countries.country}>
        {countries.country}
      </option>
    );
  };
  const listofitems = function (states) {
    return <option key={states}>{states}</option>;
  };
  const handleChangeDropdown = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name == 'PaidBy') {
        if (value !== "") {
          InvoiceDetails.map(obj => {
                if (value == obj.value) {
                  setInvoicePaidBy(obj.value)
                }
            })
        }
    }
}
  const handleChange = (e) => {
    const target = e.target;
    const name = target.name;
    const value = target.value;
    if (name == "Billing") {
      setBillingChecked(target.checked);
      if(target.checked)
      {
        const formValues = getValues()
        setValue('BillingStreetAddress1', formValues.postalAddress1, { shouldDirty: true })
        if (formValues.postalAddress2 !== undefined && formValues.postalAddress2 !== null && formValues.postalAddress2 !== '') {
        setValue('BillingStreetAddress2', formValues.postalAddress2, { shouldDirty: true })
        }
        setValue('BillingPostalCode', formValues.postalAddrPostCode, { shouldDirty: true })
        setValue('BillingCountry', formValues.postalcountry, { shouldDirty: true })
        setValue('BillingState', formValues.postalstate, { shouldDirty: true })
        setValue('BillingCity', formValues.postalCity, { shouldDirty: true })
        setValue('BillingTelephone',formValues.payeesMobile,{shouldDirty: true})
        setSelectedBillingCountryOption(selectedPostalCountryOption)
        setSelectedBillingStateOption(selectedPostalStateOption)
        setSelectedBillingCityOption(selectedPostalCityOption)
        // setBillingPhone(phone, { shouldDirty: true });        
        setBillingDialCode(dialCode);
        setDisableFlag(true)
      
      }else{
        setDisableFlag(false)
        setValue('BillingStreetAddress1', '', { shouldDirty: false })
        setValue('BillingStreetAddress2', '', { shouldDirty: false })
        setValue('BillingCity','', { shouldDirty: false })
        setValue('BillingPostalCode', '', { shouldDirty: false })
        setValue('BillingCountry', 'India', { shouldDirty: true })
        // setBillingPhone(default_dial_code, { shouldDirty: true });
        Countries.map((cntry,index) => {          
          if(cntry.country == 'India') {
            handleCountryChange({value:index,label:cntry.country},"BillingCountry")
             cntry.states.map((state,index2)=>{
              if(state == "Telangana")
              {
                setValue('BillingState','Telangana', { shouldDirty: true })
                handleStateChange({value:index2,label:state},"BillingState")
              }
            })
          }
        })
      }
    }
    if(name == 'postalcountry'){
      Countries.map((cntry) => {
        if (cntry.country == value) {
          setStateOption(cntry.states)
          setSelectedStateOption('');
          setValue('postalstate','', { shouldDirty: true })
        }
        setUserData({ ...userData, [name]: value });
      })
    }
        if (name == 'postalstate') {
          setSelectedStateOption(value);
          setValue('postalstate',value, { shouldDirty: true });
          setUserData({ ...userData, ['state']: value });
        }

  
    if(name == 'BillingCountry'){
      Countries.map((cntry) => {
        if (cntry.country == value) {
          billingSetStateOption(cntry.states);
          billingSetSelectedStateOption('');
          setValue('BillingState','', { shouldDirty: true })
        }
        setUserData({ ...userData, [name]: value });

       

      })
    }

    if (name == 'BillingState') {
      billingSetSelectedStateOption(value);
          setValue('BillingState',value, { shouldDirty: true });
          setUserData({ ...userData, ['BillingState']: value });
    }

  
  };
  const onSubmit = (data) => {
    let mobile;
    if(phone == dialCode){
      setMobileErr(true);
      window.scrollTo({'top':50,behavior:'smooth'})
      return false;
    }
    if(phone){
      mobile = phone.substring(dialCode.length);
      if(mobile.toString().length < 4 || mobile.toString().length > 13){
        setMobileErr(true);
        window.scrollTo({'top':50,behavior:'smooth'})
        return false;
      }else{
        setMobileErr(false);
      }
    }else{
      setMobileErr(true);
      window.scrollTo({'top':50,behavior:'smooth'})
      return false;
    }

    let billing_mobile="";
    if(billingPhone){
        billing_mobile = billingPhone.substring(billingDialCode.length);
    }

  let {  
        payeesFirstName,
        payeesLastName,
        payeesEmail,
        postalAddrPostCode,
        postalAddress1,
        postalAddress2,
        postalCity,
        postalcountry,
        postalstate,
        BillingCity,
        BillingCountry,
        BillingPostalCode,
        BillingState,
        BillingStreetAddress1,
        BillingStreetAddress2,
        Billingcompany,
        PanCompanyId,
        Billingcompanygst,
        PaidBy,PanId} =data

        let saveObj={
          "payee_first_name": formatSentence(payeesFirstName.trim().replace(/\s+/g, ' ')),
          "payee_email": payeesEmail,
          "payee_mobile": mobile,
          "street_address_1": postalAddress1.trim().replace(/\s+/g, ' '),
          "street_address_2":  postalAddress2?.trim().replace(/\s+/g, ' '),
          "country": postalcountry,
          "state": postalstate,
          "city": postalCity,
          "postcode": postalAddrPostCode,
          "company":PaidBy=='Company'?Billingcompany?.trim().replace(/\s+/g, ' '):Billingcompany?.trim().replace(/\s+/g, ' '),
          "company_id":PaidBy=='Company'?PanCompanyId?.trim().replace(/\s+/g, ' '):PanCompanyId?.trim().replace(/\s+/g, ' '),
          "billing_country":BillingCountry,
          "billing_state":BillingState,
          "billing_city":BillingCity,
          "billing_postcode":BillingPostalCode,
          "billing_street_address_1":BillingStreetAddress1?.trim().replace(/\s+/g, ' '),
          "billing_street_address_2":BillingStreetAddress2?.trim().replace(/\s+/g, ' '),
          "is_billing_address":billingChecked,
          "company_gst_number":PaidBy=='Company'?Billingcompanygst?.trim().replace(/\s+/g, ' '):Billingcompanygst?.trim().replace(/\s+/g, ' '),
          "billing_telephone" : billing_mobile,
          "company_gst_number":PaidBy=='Company'?Billingcompanygst.trim().replace(/\s+/g, ' '):'',
          "billing_telephone" : billing_mobile,
          "paid_by":PaidBy,
          "pan_id":PanId.trim().replace(/\s+/g, ' '),
          "payee_mobile_code":dialCode,
          "billing_telephone_code": billing_mobile.length>0 ? billingDialCode : ""
        }
    axios.post(base_url+'isb/user/update_user_details',{"id":localStorage.getItem('setPassword_UserId'),
    "user_details":saveObj
  })
    .then(res=>{
      localStorage.removeItem('app_user_details')
      localStorage.setItem('payment_type',fullpayment_flag == true ? full_payment:emi_payment)
      history.push('/paymentgateway')
    })
    .catch(err=>{
      localStorage.removeItem('app_user_details')
      console.log(err);
      if(err.response?.statusText=='Unauthorized'){
        localStorage.clear();
        history.push('/')
        window.location.reload();
      }else{
        toast.warn(api_error_message,{toastId:random_number});
      }
    })
   
  };
  const handleCountryChange = (newValue,inputName) => {
    if(inputName=="postalcountry")
    {
    setSelectedPostalCountryOption(newValue)
    setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });

      //resetting values
      setSelectedPostalStateOption({})
      setValue("postalstate", "");
      setSelectedPostalCityOption({})
      setValue("postalCity", "");
    }
    else if(inputName=="BillingCountry")
    {
      setSelectedBillingCountryOption(newValue)
      setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });
  
        //resetting values
        setSelectedBillingStateOption({})
        setValue("BillingState", "");
        setSelectedBillingCityOption({})
        setValue("BillingCity", "");
    }
    //common action
    Countries.map(item=>{
      if(item.country===newValue.label)
      {
        setStateOptions(
          item.states.map((item,index) => ({ value: index, label: item }))
        );
      }
    })
};

const handleStateChange = (newValue,inputName) => {
  if(inputName=="postalstate")
  {
  setSelectedPostalStateOption(newValue)
  setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });

  //resetting values
  setSelectedPostalCityOption({})
  setValue("postalCity", "");


  if(selectedPostalCountryOption.label)
  {
    Countries.map(item=>{
      if(item.country===selectedPostalCountryOption.label)
      {
        if(item.cities)
        {
          for (const key in item.cities) {
            if (Object.hasOwnProperty.call(item.cities, key)) {
              const citiArray = item.cities[key];

              if(key===newValue.label)
              {
                setCityOptions(
                  citiArray.map((city,index)=>({value: index, label: city}))
                  )

              }
              
            }
          }
        
        }
      }
    })
  }
  }
  else if(inputName=="BillingState")
  {
    setSelectedBillingStateOption(newValue)
    setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });
  
    //resetting values
    setSelectedBillingCityOption({})
    setValue("BillingCity", "");

    if(selectedBillingCountryOption.label)
    {
      Countries.map(item=>{
        if(item.country===selectedBillingCountryOption.label)
        {
          if(item.cities)
          {
            for (const key in item.cities) {
              if (Object.hasOwnProperty.call(item.cities, key)) {
                const citiArray = item.cities[key];
  
                if(key===newValue.label)
                {
                  setCityOptions(
                    citiArray.map((city,index)=>({value: index, label: city}))
                    )
  
                }
                
              }
            }
          
          }
        }
      })
    }
    }
  }
const handleCityChange=(newValue,inputName)=>{
  if(inputName=="postalCity")
  {
    setSelectedPostalCityOption(newValue)
    setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });
  }
  else if(inputName==="BillingCity")
  {
    setSelectedBillingCityOption(newValue)
    setValue(inputName, newValue.label ? newValue.label:"",{ shouldDirty: true });
  }
}


const validatePostalPostCode = (value) => {
  if(value.length < 3 || value.length > 8) {
   return "Postal Code must contain between 3 and 8 digits";
 }
   if(selectedPostalCountryOption?.label=="Argentina" || selectedPostalCountryOption?.label=="Brunei" || selectedPostalCountryOption?.label=="Canada" || selectedPostalCountryOption?.label=="Ireland" || selectedPostalCountryOption?.label=="Kazakhstan" || selectedPostalCountryOption?.label=="Malta" || selectedPostalCountryOption?.label=="Netherlands"|| selectedPostalCountryOption?.label=="Peru" || selectedPostalCountryOption?.label=="Somalia" || selectedPostalCountryOption?.label=="United Kingdom" || selectedPostalCountryOption?.label=="Swaziland"){
    if (!/^\w+$/.test(value)) {
      return "Please enter a valid Postal Code";
    }
   }
   else{
    if (!/^\d+$/.test(value)) {
      return "Postal Code should contain only numbers";
    }
  }
  return true;
};

const getChecked = (isChecked) => {
  setCheckBox(isChecked);
};

const validateBillingPostCode = (value) => {
  if(value.length < 3 || value.length > 8) {
   return "Postal Code must contain between 3 and 8 digits";
 }
   if(selectedBillingCountryOption?.label=="Argentina" || selectedBillingCountryOption?.label=="Brunei" || selectedBillingCountryOption?.label=="Canada" || selectedBillingCountryOption?.label=="Ireland" || selectedBillingCountryOption?.label=="Kazakhstan" || selectedBillingCountryOption?.label=="Malta" || selectedBillingCountryOption?.label=="Netherlands"|| selectedBillingCountryOption?.label=="Peru" || selectedBillingCountryOption?.label=="Somalia" || selectedBillingCountryOption?.label=="United Kingdom" || selectedBillingCountryOption?.label=="Swaziland"){
    if (!/^\w+$/.test(value)) {
      return "Please enter a valid Postal Code";
    }
   }
   else{
    if (!/^\d+$/.test(value)) {
      return "Postal Code should contain only numbers";
    }
  }
  return true;
};
  return (
    <>
      {loading_flag ? (
          <div>
          <img className="atrium_loader" src="images/atrium_loader.gif" alt="Loader" />
        </div>
      ) : (
        <>
          {showModal && (
            <Modal show={showModal} centered ClassName="role_modal" style={{backgroundColor: "rgba(0, 0, 0, 0.3)"}}>
              <Modal.Body className="p-3">
                <div className="text-center">
                <img  className="mt-4" src="images/payment_icon.svg" />
                <div className="fs-2 fw-bold mt-2 mb-0">Payment Link Expired</div>
                <p className="modal_text my-3 fs-5"><span>Your payment link has expired. Please contact</span><span><a className="fs-5 black text-decoration-underline" href="mailto:support@isb.edu"> support@isb.edu </a>to request an extension</span></p>
                <div className="pb-3 mt-4">
                  <button className="btn my_outline_button_cls submit_button fw-bold my_certificate_btn m-0 px-4 me-5 fs-18" onClick={() => history.push("/dashboard")}> <i className="fa fa-arrow-left me-2 fw-bolder payment_icon" aria-hidden="true" ></i>Dashboard</button>
                  <button className="btn my_btn_cls submit_button m-0 px-4 fs-18" onClick={() => window.location.href = 'mailto:support@isb.edu'}>Email Support</button>
                </div>
                </div>
              </Modal.Body>
            </Modal>
          )}  
          {showTermsConditionsModal && (
            <Modal show={showTermsConditionsModal} centered className="role_modal terms_conditions_modal" style={{ backgroundColor: "rgba(0, 0, 0, 0.3)", overflow: "hidden" }}>
              <Modal.Body className="p-3" style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                <button type="button" class="close" data-dismiss="modal" onClick={() => setTermsConditionsShowModal(false)}>&times;</button>
                <div className="terms_conditions_content_div p-4">
                  <h4 className="blue_text fw-bold">ISB Online Programmes - Terms & Conditions</h4>
                  <p className="my-3 fw-bold">
                    Language Proficiency
                  </p>
                  <p className="mb-0">
                    Proficiency in spoken and written English is essential for participation in Indian School of Business Executive Education programs. If English is your second language, or if you have less than one year's experience working in an English-speaking environment, please provide a brief statement documenting your proficiency. The Programme Advising Team may request an interview before proceeding with your application
                  </p>
                  <p className="my-3 fw-bold">
                    Payment Terms
                  </p>
                  <p className="mb-0">
                    Blocking fee confirms the participation of the applicant and once received is non-refundable.
                    Complete payment for the programmes is expected as per the schedule. Should we be unable to accept your application for any extraneous reason, your payment will be refunded to you.
                  </p>
                  <p className="my-3 fw-bold">
                    Cancellation/Refund Policy for ISB Online Programmes
                  </p>
                  <p className="mb-0">
                    All online programmes at ISB Executive Education & Digital Learning follow the cancellation/refund policy below. This policy applies for individual applicants as well as for company-sponsored applicants.
                  </p>
                  <p className="mt-3 mb-0 fw-bold">
                    1) Cancellation by ISB
                  </p>
                  <p className="mb-0">
                    If any online programme is cancelled by ISB, refund will be processed in full for the undelivered part of the programme within 30 days of such cancellation, provided that participant share their bank account and other necessary details within 5 days. Any delays in providing bank details from the participant’s side could result in delayed payment.
                  </p>
                  <p className="mt-3 mb-0 fw-bold">
                    2) Cancellation by Clients/ Participants
                  </p>
                  <p className="mb-0">
                    If a nomination is withdrawn by a Client/ Participant from any of the programmes, the refund policy is as below.
                  </p>
                  <p className="mt-3 mb-0 fw-bold">
                    Referral/Deferral Policy
                  </p>
                  <div>
                    <table className="mt-3 table table-bordered">
                      <thead>
                        <th>Request</th>
                        <th>Referral/Deferral Policy</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Cancelation request sent in writing more than 30 days prior to the programme start date</td>
                          <td>100%</td>
                        </tr>
                        <tr>
                          <td>Cancelation request sent in writing between 15-30 days prior to the programme start date</td>
                          <td>50%</td>
                        </tr>
                        <tr>
                          <td>Cancelation request sent in writing 14 days or less prior to the programme start date</td>
                          <td>0%</td>
                        </tr>
                        <tr>
                          <td>Deferral requests sent in writing before the programme start date</td>
                          <td>Considered on a case-to-case basis</td>
                        </tr>
                      </tbody>
                    </table>
                    <ol type="a">
                      <li className="mt-4">Refund requests from participant(s)/ company may be moved to be deferral/substitution scenarios on mutual consent between the participant and ISB</li>
                      <li>Up to a maximum of two deferral requests per participant are allowed. If the participant fails to enrol themselves in a programme which starts within 12 calendar months of the start date of the original programme, the amount paid will be forfeited.</li>
                      <li>In case of substitutions, the participant must qualify for the admissions criteria of the alternative programme(s)</li>
                      <li>In case of substitutions where the combined price(s) of the new programme(s) is higher than the enrolled programme price, the difference amount + applicable taxes is payable by the candidate</li>
                      <li>In case of substitutions/deferrals where the combined price(s) of the new programme(s) is lower than the enrolled programme price, the differential amount is forfeited</li>
                      <li>All programme fees must be paid in full before commencing deferral or substitution programmes.</li>
                    </ol>
                    <p className="my-3 fw-bold">
                      ISB Community Values Statement
                    </p>
                    <p className="mb-0">
                      Indian School of Business (ISB) is governed by a set of community values that foster passion for excellence, openness, caring for others, collaboration, initiative, and innovation. ISB considers these values essential for a secure and productive learning environment for all. ISB Executive Education & Digital Learning reserves the right to withdraw an offer of admission if any part of your application contains misrepresentations, or if you engage in, or have engaged in, behaviour that violates ISB Values.
                    </p>
                    <p className="my-3 fw-bold">
                      Discrimination Policy
                    </p>
                    <p className="mb-0">
                      The Indian School of Business does not discriminate against any person based on age, race, caste, colour, gender, gender identity, religion, age, nationality or ethnicity, disability or political views in admission, access, or treatment in its programmes and activities therein.
                    </p>
                  </div>
                  <div className="text-center">
                    <button
                      type="button"
                      className="btn my_btn_cls  col-sm-3 col-lg-2 col-md-2 online_app_submit"
                      onClick={() => setTermsConditionsShowModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          )}
          <div className="container container-onlineApp mt-5 background-transparent">
            <span className="form-head" data-testid="Main_Heading">Invoicing Details</span>
            <span className="form-subhead mt-4 mb-3" data-testid="Sub_heading">{program_name} </span>
            <>
            {program_type != standalone_program_type ? (
                <span data-testid="Start_date"> (Starting {date_formats.human_date_format(start_date)})</span>
            ) :(
              ""
             )}
                                            
            </> 
            <div className="row mt-2">
              <div className={"col-3"}>
                <select {...register("PaidBy", {
                   required: "Please select any option",
                })}
                    id="PaidBy"
                    data-testid="PaidBy"
                    name="PaidBy"
                    className="form-select paid_by"
                    role="presentation" autocomplete="off" onChange={handleChangeDropdown}>
                    <option value='' style={{display:'none',fontWeight:'800'}}>Paid By</option>
                    {InvoiceDetails.map((item, index) => {
                        return <option key={index} value={item._id} >{item.value}</option>;
                    })}
                </select>
                {errors.PaidBy?.message && (
                    <span style={{ color: "red" }}>{errors.PaidBy?.message}</span>
                  )}
              </div>
            </div>
          
          </div>
          <div className="container container-onlineApp mt-4">
            <div className="address_applictn_form_p1">
            <div className="address-head-main">
                <span className="address-head">
                  Payee's Details
                </span>
              </div>

              <div className="row form_field_cls mt-2">
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="Payee_label_name">
                  Full Name as per Bank Account
                  </label>
                  <span className="requiredInput">*</span>
                  <input
                    {...register("payeesFirstName",addressAppplicationFormValidations.firstName)}
                    id="payeesFirstName"
                    data-testid="payeesFirstName"
                    
                    name="payeesFirstName"
                    className="form-control boder-radius-0"
                    placeholder=" "
                 //   role="presentation"
                    autocomplete="off"
                    // value={userData.company}
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.payeesFirstName?.message && (
                    <span style={{ color: "red" }}>
                      {errors.payeesFirstName?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="Payee_label_email">
                  Payee Email
                  </label>
                  <span className="requiredInput">*</span>
                  <input
                    {...register("payeesEmail", addressAppplicationFormValidations.payeesEmail
                    )}
                    id="payeesEmail"
                    name="payeesEmail"
                    data-testid="payeesEmail"
                    type="nubmer"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autocomplete="off"
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.payeesEmail?.message && (
                    <span style={{ color: "red" }}>
                      {errors.payeesEmail?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel"  data-testid="Payee_label_mobile">
                  Payee Mobile
                  </label>
                  <span className="requiredInput">*</span>
                  <PhoneInput
                  className="payee_mobile"
                  country={countryCode}
                  value={phone}
                  onChange={handleOnChange}
                  separateDialCode={true} // Display flag and code in one block
                />
                <input data-testid="Payee_mobile" value={phone} onChange={handleOnChange}  className="payee_mobile d-none"/>
                <span className={`flag-icon flag-icon-${countryCode}`}></span>
                {mobileErr && <span style={{ color: "red" }}>Please enter a valid mobile</span>}
                </div>
                <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                        data-testid="Payee_pan_label"
                      >
                        Permanent Account N​umber (PAN)
                      </label>
                      <span className="requiredInput">*</span>
                      <input
                      {...register("PanId",addressAppplicationFormValidations.panId
                      )}
                      id="PanId"
                      name="PanId"
                      data-testid="PanId"
                      className="form-control boder-radius-0"
                      placeholder=" "
                      role="presentation"
                      autocomplete="off"
                      // value={userData.role}
                      onChange={handleChange}
                      required
                    />
                     <small>Eg:ABRDE1234A</small>&nbsp;&nbsp;
                      {errors.PanId?.message && (
                        <span style={{ color: "red" }}>
                          {errors.PanId?.message}
                        </span>
                      )}
                    </div>
                    <div className={colCls}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        style={{ display: "none" }}
                      />
                    </div>
               
              </div>
            </div>
          </div>
          <div className="container container-onlineApp mt-4">
            <div className="address_applictn_form_p1">
              <div className="address-head-main">
                <span className="address-head">
                {billingChecked?"Postal Address for Certificate":"Address for Certificate"}{" "}
                  {/* <span className="requiredInput">*</span> */}
                </span>
                <span className="address-subhead mt-1">
                  This address will be used to ship your printed certificate.
                  You may also fill this out later.{" "}
                </span>
              </div>

              <div className="row form_field_cls mt-2">
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="payee_label_street_address1">
                    Street Address 1{" "} 
                  </label>
                  <span className="requiredInput">*</span>
                  <input
                    {...register("postalAddress1",
                    addressAppplicationFormValidations.postalAddress1 
                    )}
                    id="postalAddress1"
                    name="postalAddress1"
                    data-testid="postalAddress1"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autocomplete="off"
                    // value={userData.company}
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.postalAddress1?.message && (
                    <span style={{ color: "red" }}>
                      {errors.postalAddress1?.message}
                    </span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="payee_label_street_address2">
                    Street Address 2{" "}
                  </label>
                  <input
                    {...register("postalAddress2", 
                    )}
                    id="postalAddress2"
                    name="postalAddress2"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autocomplete="off"
                    data-testid="postalAddress2"
                    // value={userData.role}
                    onChange={handleChange}
                    // required
                  />
                  {errors.postalAddress2?.message && (
                    <span style={{ color: "red" }}>{errors.postalAddress2?.message}</span>
                  )}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput">Country </label>
                  <span className="requiredInput">*</span>
                   <Controller
                    as={Select}
                    name="postalcountry"
                    placeholder="postalcountry"
                    options={countryOptions}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                        onChange={(e)=>handleCountryChange(e,"postalcountry")}
                        value={selectedPostalCountryOption}
                        name={name}
                        options={countryOptions}
                        />
                        )}
                    />
                    {errors.postalcountry && errors.postalcountry.type === 'required' && Object.keys(selectedPostalCountryOption).length==0 && <span className="form-label-color">Country is  required</span>}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput">State </label>
                  <span className="requiredInput">*</span>
                   <Controller
                    as={Select}
                    name="postalstate"
                    placeholder="postalstate"
                    options={stateOptions}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                        onChange={(e)=>handleStateChange(e,"postalstate")}
                        value={selectedPostalStateOption}
                        name={name}
                        options={stateOptions}
                        />
                        )}
                    />
                    {errors.postalstate && errors.postalstate.type === 'required' && Object.keys(selectedPostalStateOption).length==0 && <span className="form-label-color">State is  required</span>}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="payee_label_city">
                    City{" "}
                  </label>
                  <span className="requiredInput">*</span>

                  {selectedPostalCountryOption?.label=="India"?
                  <>
                   <Controller
                    as={Select}
                    name="postalCity"
                    placeholder="postalCity"
                    
                    options={cityOptions}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                        onChange={(e)=>handleCityChange(e,"postalCity")}
                        value={selectedPostalCityOption}
                        name={name}
                        options={cityOptions}
                        />
                        )}
                    />
                    <select
                      id="postalCity"
                      aria-label=".form-select example"
                      name="postalCity"
                      data-testid="postalCity"
                      className="form-select d-none"
                      role="presentation" autoComplete="off"
                      onChange={(e)=>handleCityChange(e,"postalCity")}
                      >
                      {cityOptions.map((item,index) => {
                        return <option  key={index} value={item.value} >{item.label}</option>;
                      })}
                    </select>
                    {errors.postalCity && errors.postalCity.type === 'required' && Object.keys(selectedPostalCityOption).length==0 && <span className="form-label-color">City is  required</span>}
                  </>:
                  <>
                  <input
                    {...register("postalCity",addressAppplicationFormValidations.postalCity 
                    )}
                    id="postalCity"
                    name="postalCity"
                    data-testid="postalCity"
                    type="nubmer"
                    className="form-control boder-radius-0"
                    placeholder=" "
                   // role="presentation"
                    autocomplete="off"
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.postalCity?.message && (
                    <span style={{ color: "red" }}>
                      {errors.postalCity?.message}
                    </span>
                  )}
                  </>}
                </div>
                <div className={colCls}>
                  <label htmlFor="exampleInput" className="Address-InputLabel" data-testid="payee_label_postalcode" >
                    Post Code{" "}
                  </label>
                  <span className="requiredInput">*</span>
                  <input

                    {...register("postalAddrPostCode", { validate: validatePostalPostCode } 
                    )}
                    id="postalAddrPostCode"
                    name="postalAddrPostCode"
                    data-testid="postalAddrPostCode"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autocomplete="off"
                    // value={userData.industry}
                    onChange={handleChange}
                    required
                  ></input>
                  {errors.postalAddrPostCode?.message && (
                    <span style={{ color: "red" }}>
                      {errors.postalAddrPostCode?.message}
                    </span>
                  )}
                </div>
            

                <div className={colCls}>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=" "
                    style={{ display: "none" }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="container container-onlineApp mt-4 background-transparent">
            <span className="Billing-check">
              <input
                type="checkbox"
                id="Billing"
                name="Billing"
                data-testid="billing_checkbox"
                checked={billingChecked}
                className="cursor-pointer"
                onChange={handleChange}
              />{" "}
              <label for="Billing" className="cursor-pointer mb-0">
                {" "}
                Billing Address is the same as Postal Address
              </label>
            </span>
          </div>

          {(
            <>
              <div className="container container-onlineApp mt-4">
                <div className="address_applictn_form_p1">
                  <div className="address-head-main">
                    <span className="address-head">Billing Address</span>
                    <span className="address-subhead mt-1">
                      This address will appear on your invoice
                    </span>
                  </div>

                  <div className="row form_field_cls mt-2">
                  {InvoicePaidBy== 'Company'?<div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        Company Name 
                      </label>
                     <span className="requiredInput">*</span>
                      
                      <input
                        {...register("Billingcompany",addressAppplicationFormValidations.billingcompany
                        // {
                        //    required: "Company is required",
                        // }
                        )}
                        id="Billingcompany"
                        name="Billingcompany"
                        data-testid="Billingcompany"
                        className="form-control boder-radius-0"
                        placeholder=" "
                        role="presentation"
                        autocomplete="off"
                        // value={userData.Billingcompany}
                        onChange={handleChange}
                        required
                      ></input>
                      { errors.Billingcompany?.message && (
                        <span style={{ color: "red" }}>
                          {errors.Billingcompany?.message}
                        </span>
                      )}

                    </div>:''}
                   {InvoicePaidBy== 'Company'?
                    <div className={colCls}>
                    <label
                      htmlFor="exampleInput"
                      className="Address-InputLabel"
                    >
                   Company PAN
                    </label>
                    <span className="requiredInput">*</span>
                    <input
                    {...register("PanCompanyId",addressAppplicationFormValidations.panCompanyIdS
                    )}
                    id="PanCompanyId"
                    name="PanCompanyId"
                    data-testid="PanCompanyId"
                    className="form-control boder-radius-0"
                    placeholder=" "
                    role="presentation"
                    autocomplete="off"
                    // value={userData.role}
                    onChange={handleChange}
                    required
                  />
                 {/* <small>Eg:ABRDE1234A</small>&nbsp;&nbsp; */}
                    {errors.PanCompanyId?.message && (
                      <span style={{ color: "red" }}>
                        {errors.PanCompanyId?.message}
                      </span>
                    )}
                  </div>:''
                   }
                    {InvoicePaidBy== 'Company'?  <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        Company GST Number 
                      </label>
                      <span className="requiredInput">*</span>
                      
                      <input
                        {...register("Billingcompanygst",addressAppplicationFormValidations.billingcompanygst
                       )}
                        id="Billingcompanygst"
                        name="Billingcompanygst"
                        data-testid="Billingcompanygst"
                        className="form-control boder-radius-0"
                        placeholder=" "
                        role="presentation"
                        autocomplete="off"
                        onChange={handleChange}
                        required
                      ></input>
                  <small>Eg:29ABRDE1234F8Z5</small>&nbsp;&nbsp;
                      {errors.Billingcompanygst?.message && (
                        <span style={{ color: "red" }}>
                          {errors.Billingcompanygst?.message}
                        </span>)}
                    </div>:''}
                    <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel" data-testid="billing_label_telephone"
                      >
                        Billing Telephone 
                      </label>
                      <PhoneInput
                      disabled={disable_flag}
                  className="billing_mobile"
                  country={billingCountryCode}
                  value={billingPhone}
                  onChange={handleBillingOnChange}
                  separateDialCode={true} // Display flag and code in one block
                />
                <input className="billing_mobile d-none"
                  data-testid="billing_telephone"
                  country={billingCountryCode}
                  value={billingPhone}
                />
                <span className={`flag-icon flag-icon-${countryCode}`}></span>
                {billingMobileErr && <span style={{ color: "red" }}>Please enter a valid mobile</span>}
                    </div>
                    
                    <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        Street Address 1{" "}
                      </label>
                      <span className="requiredInput">*</span>
                      <input
                        {...register("BillingStreetAddress1",addressAppplicationFormValidations.billingStreetAddress1 
                        )}
                        id="BillingStreetAddress1"
                        name="BillingStreetAddress1"
                        data-testid="BillingStreetAddress1"
                        className="form-control boder-radius-0"
                        placeholder=" "
                        role="presentation"
                        autocomplete="off"
                        disabled={disable_flag}
                        // value={userData.BillingStreetAddress1}
                        onChange={handleChange}
                        required
                      ></input>
                      {errors.BillingStreetAddress1?.message && (
                        <span style={{ color: "red" }}>
                          {errors.BillingStreetAddress1?.message}
                        </span>
                      )}
                    </div>
                    <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        Street Address 2{" "}
                      </label>
                      <input
                        {...register("BillingStreetAddress2", 
                        )}
                        id="BillingStreetAddress2"
                        name="BillingStreetAddress2"
                        className="form-control boder-radius-0"
                        placeholder=" "
                        role="presentation"
                        autocomplete="off"
                        disabled={disable_flag}
                        // value={userData.role}
                        onChange={handleChange}
                        // required
                      />
                      {errors.BillingStreetAddress2?.message && (
                        <span style={{ color: "red" }}>
                          {errors.BillingStreetAddress2?.message}
                        </span>
                      )}
                    </div>
                    <div className={colCls}>
                      <label htmlFor="exampleInput">Country </label>
                      <span className="requiredInput">*</span>
                   <Controller
                    as={Select}
                    name="BillingCountry"
                    placeholder="BillingCountry"
                    options={countryOptions}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                        onChange={(e)=>handleCountryChange(e,"BillingCountry")}
                        value={selectedBillingCountryOption}
                        name={name}
                        options={countryOptions}
                        isDisabled={disable_flag}
                        />
                        )}
                    />
                    {errors.BillingCountry && errors.BillingCountry.type === 'required' && Object.keys(selectedBillingCountryOption).length==0 && <span className="form-label-color">Country is  required</span>}
                    </div>
                    <div className={colCls}>
                      <label htmlFor="exampleInput">State </label>
                      <span className="requiredInput">*</span>
                   <Controller
                    as={Select}
                    name="BillingState"
                    placeholder="BillingState"
                    options={stateOptions}
                    control={control}
                    rules={{ required: true }}
                    render={({ onChange, value, name }) => (
                    <Select
                        onChange={(e)=>handleStateChange(e,"BillingState")}
                        value={selectedBillingStateOption}
                        name={name}
                        options={stateOptions}
                        isDisabled={disable_flag}
                        />
                        )}
                    />
                    {errors.BillingState && errors.BillingState.type === 'required' && Object.keys(selectedBillingStateOption).length==0 && <span className="form-label-color">State is  required</span>}
                    </div>
                    <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        City{" "}
                        <span className="requiredInput">*</span>
                      </label>

                      {selectedBillingCountryOption?.label=="India"?
                      <>
                      <Controller
                        as={Select}
                        name="BillingCity"
                        placeholder="BillingCity"
                        options={cityOptions}
                        control={control}
                        rules={{ required: true }}
                        render={({ onChange, value, name }) => (
                        <Select
                            onChange={(e)=>handleCityChange(e,"BillingCity")}
                            value={selectedBillingCityOption}
                            name={name}
                            options={cityOptions}
                            isDisabled={disable_flag}
                            />
                            )}
                        />
                        {errors.BillingCity && errors.BillingCity.type === 'required' && Object.keys(selectedBillingCityOption).length==0 && <span className="form-label-color">City is  required</span>}
                        </>:
                        <>
                          <input
                            {...register("BillingCity",addressAppplicationFormValidations.billingCity 
                            )}
                            id="BillingCity"
                            name="BillingCity"
                            data-testid="BillingCity"
                            type="nubmer"
                            className="form-control boder-radius-0"
                            placeholder=" "
                            role="presentation"
                            autocomplete="off"
                            disabled={disable_flag}
                            onChange={handleChange}
                            required
                          ></input>
                          {errors.BillingCity?.message && (
                            <span style={{ color: "red" }}>
                              {errors.BillingCity?.message}
                            </span>
                          )}
                        </>}
                    </div>
                    <div className={colCls}>
                      <label
                        htmlFor="exampleInput"
                        className="Address-InputLabel"
                      >
                        Post Code{" "}
                      </label>
                      <span className="requiredInput">*</span>
                      <input
                        {...register("BillingPostalCode", { validate: validateBillingPostCode } 
                        )}
                        id="BillingPostalCode"
                        name="BillingPostalCode"
                        data-testid="BillingPostalCode"
                        className="form-control boder-radius-0"
                        placeholder=" "
                        role="presentation"
                        autocomplete="off"
                        // value={userData.industry}
                        disabled={disable_flag}
                        onChange={handleChange}
                        required
                      ></input>
                      {errors.BillingPostalCode?.message && (
                        <span style={{ color: "red" }}>
                          {errors.BillingPostalCode?.message}
                        </span>
                      )}
                    </div>
                    <div className={colCls}>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=" "
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="container container-onlineApp mt-4">
            <div className="address_applictn_form_p1">
              <div className="address-head-main">
                <span className="address-head"> Select Payment Mode</span>
              </div>
              <div className="row form_field_cls mt-4">
                <div className="d-flex align-items-start justify-content-start">
                  <input className="program_input" id="pay_full_amount" type="radio" checked={fullpayment_flag} name="pay_full_amount" onChange={() => {
                    setFullPaymentFlag(true);
                    setInstallmentFlag(false);
                  }}/>&nbsp;&nbsp;&nbsp;
                  <div className="d-flex flex-column">
                    <span className="address-head" data-testid="pay_full_amount">Pay in Full</span>
                    <p className="address-subhead mt-1">Full Amount ₹  {program_cost} (Including GST)</p>
                  </div>
                </div>
              </div>
              {program_split_payments.length>0&&<>         
              <div className="row form_field_cls">
                <div className="d-flex align-items-start justify-content-start">
                  <input className="program_input" style={{marginTop:"2px"}} id="pay_installment_amount" type="radio" checked={installment_flag} name="pay_installment_amount" onChange={() => {
                    setFullPaymentFlag(false);
                    setInstallmentFlag(true);
                  }} />&nbsp;&nbsp;&nbsp;
                  <div className="d-flex flex-column">
                    <span className="address-head mb-1" data-testid="installment_payment">Pay in Instalments</span>
                    {program_split_payments.map((installment_obj,index) => {
                      return (
                        <p className="address-subhead mb-1" key={installment_obj.id}>
                          {index === 0 ? (
                            `${installment_obj.payment_name} ₹ ${installment_obj.payment_amount} (Including GST) (Pay Today)`
                          ) : (
                            `${installment_obj.payment_name} ₹ ${installment_obj.payment_amount} (Including GST) (Pay by ${date_formats.human_date_format(installment_obj.pay_by_date)})`
                          )}
                        </p>
                      );
                    })}
                  </div>
                </div>
              </div>
              </>}
              <div className="row form_field_cls mt-3"  style={{marginLeft:"5px"}}>
                <div className="d-flex align-items-start justify-content-start">
                    <input
                    {...register("terms_conditions_checkbox",addressAppplicationFormValidations.terms_conditions_checkbox)} 
                    style={{marginTop:"2px"}}
                      type="checkbox"
                      id="terms_conditions_checkbox"
                      name="terms_conditions_checkbox"
                      checked={checkBox}
                      data-testid="terms_conditions_checkbox"
                      className="cursor-pointer program_input d-flex flex-column"
                      onChange={(e) => getChecked(e.target.checked)} 
                    />&nbsp;&nbsp;
                    <div className="d-flex flex-column">
                    <label className="cursor-pointer mb-0">
                      I agree to the <span className="fw-bold text-underline"  onClick={() => setTermsConditionsShowModal(true)}>Terms & Conditions</span>
                    </label>
                    {errors.terms_conditions_checkbox?.message && (
                    <span style={{ color: "red" }}>{errors.terms_conditions_checkbox?.message}</span>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            <div className="goto_part_2_btn d-block text-center pb-45">
              <button
                type="button"
                className="btn my_btn_cls  col-sm-3 col-lg-2 col-md-2 online_app_submit"
                value="Go To Part 2"
                onClick={handleSubmit(onSubmit)}
                // disabled={!firstname}
              >
                Submit
              </button>
            </div>
          }
        </>
      )}
    </>
  );
};
export default withMaintenanceCheck(AddressApplicationForm,user_maintenance_type)
