import { useHistory } from "react-router-dom";
import { Container } from 'react-bootstrap';
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { admin_maintenance_type } from "../../utilities";

 const ModuleHomePage = () => {

let history = useHistory();

  const handleNextpage = () =>{
   history.push('/AdminUpload')

  }
   
   
    return (
        <>
           <Container className="shadow-lg border bg-light mt-3">
           <div className="d-flex justify-content-end mr-3">
    <button onClick={handleNextpage} className="btn btn-warning text-white btn-sm fs-4 fw-bold w-25 shadow rounded border">ADD NEW COURSE</button>
</div>
<div className="fw-bold fs-1 m-3 d-grid gap-4 ">
  <ul className='module_home_page m-1 text-white p-4 rounded shadow'>Managing High Performance Teams</ul>
  <ul className='module_home_page m-1 text-white p-4 rounded shadow'>Team Foundation</ul>
    <ul className='module_home_page m-1 text-white p-4 rounded shadow'>Work Group and Teams</ul>
    <ul className='module_home_page m-1 text-white p-4 rounded shadow'>Structural Foundation of a Team</ul>
   </div>
           </Container>
        </>
    )
}
export default withMaintenanceCheck(ModuleHomePage,admin_maintenance_type) 