import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { useRef, useState, useEffect,Fragment} from "react";

import { ActivityElementMultipleChoice } from "./activityElementMultipleChoice";
import { ActivityElementCheckAllApply } from "./activityElementCheckAllApply";
import { ActivityElementShortAnswer } from "./activityElementShortAnswer";
import { ActivityElementOrdering } from "./activityElementOrdering";
import { ActivityElementDragAndDrop } from "./activityElementDragAndDrop";
import { ActivityElementMultipleInput } from "./activityElementMultipleInput";
import {ActivityInput} from './activityInput'
import { VideoComponent } from "./videoComponent";
import { DataComponent } from "./dataComponent";
import { ImageDataComponent } from "./imageDataComponent";
import { ImageComponent } from "./imageComponent";
import { ObjectiveComponent } from "./objectivesComponent";
import { QuoteElement } from "./quoteElement";
import { FeedbackMultipleChoice } from "./feedbackMultipleChoice";
import { FeedbackOpenResponse } from "./feedbackOpenResponse";
import { AboutQuizComponent } from "./aboutQuizComponent";
import { ActivityFileUpload } from "./activityFileUpload";
import { VideoCheck } from "./videoCheck";




export const ContentPage = (props) => {
  var data = props.data, SubTagName = `span`, key = 0, list_content = '<li>';;
  var object_data = [{}];
  var [purpose,setPurpose] = useState('')
  const [final_data, setFinalData] = useState([]);
  const [isNavbarExist, setIsNavbarExist] = useState(false);

  useEffect(() => {
    var navbar = document.getElementById('top-nav-bar');
    if (navbar&&navbar.style.display != 'none') {
      setIsNavbarExist(true);
    } 
    let sessionID = localStorage.User_Accesstoken;
    let lessonID = localStorage.lessonId;
    let userID = localStorage.user_id;

    function recursive_ul_commmon_data(value1,str_li,value2=undefined,removeSpace=false){
     if(value2!=undefined && value2.type === 'a') {
        str_li +=`<a style="word-break: ${value2.href.length>60 ?"break-all":""} !important;" class="Article_link articleWithOutSpan ${removeSpace?'pr-0':''}" href='${value2.href}?x=${userID}&y=${sessionID}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)" >`
        str_li +=value1.text?value1.text:""
        str_li +='</a>'
       }
       else if (value1?.strong) {
        str_li += '<strong class="summary_header_cls">';
        str_li += value1.text;
        str_li += '</strong>';
      }else if (value1?.em) {
        str_li += '<em class="summary_header_cls">';
        str_li += value1.text;
        str_li += '</em>';
      }else{
        str_li += value1.text?value1.text:""
      }
    
      return str_li;
    }
    
    function recursive_ol_commmon_data(value1,str_li){
      if (value1?.strong) {
        str_li += '<strong class="summary_header_cls">';
        str_li += value1?.text;
        str_li += '</strong>';
      }else{
        str_li += value1?.text != undefined?value1?.text : ''
      }
      if (value1?.type === 'a') {
        str_li =  str_li + `<a class="Article_link articleWithOutSpan" href='${value1.href}?x=${userID}&y=${sessionID}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)"> ${value1.text}</a>`
        if (value1?.children) {
          for (const value2 of value1.children) {
            str_li =  str_li + value2.text
          }
        }
        str_li =  str_li + '</a>'
      }
      return str_li;
    }

    function recursive_title_data(value) {
      if (value?.children) {
        for (const value1 of value.children) {
          recursive_title_data(value1.children)
        }
      } else {
        let str = '';
        for (const value1 of value) {
          str = str + value1.text
        }
        str = str?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        return str;
      }
    }
    let str_ul="";
    let str_ul_count=0
    function recursive_ul_data(data){
      for(const value of data){ 
        if(value?.type !== 'li'){
          str_ul='<ul class="summary_uList_cls">';
          str_ul_count++;
          recursive_ul_data(value.children)
        }else{          
          let str_li = '<ul class="summary_uList_cls"><li class="summary_list_cls">';
          //check link with period combination or not if yes removespace=true
          let removeSpace=false
          for (let singleItem_index = 0; singleItem_index < value.children.length; singleItem_index++) {
            const singleItem = value.children[singleItem_index];
            if(singleItem.type=='a')
            {
              if(value.children[singleItem_index+1]&&value.children[singleItem_index+1].type=='h2'&&value.children[singleItem_index+1].text==".")
              {
                removeSpace=true
              }
            }
          }
       
          for(const value1 of value.children){
            if(value1?.children){
              for(const value2 of value1?.children){
                str_li = recursive_ul_commmon_data(value2,str_li,value1,removeSpace);
              }
            }else{
              str_li = recursive_ul_commmon_data(value1,str_li);
            }
          }
          str_li += '</li>' 
          str_li = str_li?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
          object_data[key]['str_data'] = object_data[key]?.str_data ? 
          object_data[key].str_data+(str_ul!==""?str_ul:"")+str_li + '</ul>'+(str_ul_count!==0?'</ul>':""): 
          (str_ul!==""?str_ul:"")+ str_li + '</ul>'+(str_ul_count!==0?'</ul>':"");
          // str_ul_count=0
          // str_ul=""
        }
      }
    }
    function recursive_ol_data(data){
      let str_li = '<ol class="summary_uList_cls">';
      for(const value of data){   
        str_li += '<li class="summary_list_cls">'      
          for(const value1 of value.children){
            if(value1?.children){
              for(const value2 of value1?.children){
                str_li = recursive_ol_commmon_data(value2,str_li)
              }
            }else{
              str_li = recursive_ol_commmon_data(value1,str_li)
            }
          }
          str_li += '</li>' 
        }
        str_li = str_li?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str_li + '</ol>' : str_li + '</ol>';
    }
    function recursive_table_data(data){
      let str_table = '<table class="table">'
      for(const value of data){ 
        if(value?.type == 'tr'){
          str_table += '<tr>';
          for(const value1 of value.children){
            if(value1.type == 'td'){
              for(const value2 of value1.children){
                if (value2.children[0]?.strong) {
                  str_table += '<td><strong class="summary_header_cls">';
                  str_table += value2.children[0].text;
                  str_table += '</strong></td>';
                }else{
                  str_table += '<td><span class="summary_header_cls">'+value2.children[0].text+'</span></td>'
                }
              }
            }
          }
          str_table += '</tr>' 
        }
      }
      str_table += '</table>' 
      str_table = str_table?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
      object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str_table : str_table;
    }
    function recursive_content_data(data) {
      let str = '';
      if (data?.type === 'p') {
        let itemArr=[]
        const childLength=data.children.length
        // console.log(data.children,"ttttttttt");
        data.children.filter(obj=>{
          if(obj.strong)
          {
            itemArr.push("strong")
          }
          else{
            if(obj.text!=="")
            {
              itemArr.push("text")
            }
          }
        })
        const contentHasStrongItem=itemArr.filter(item=>item=="strong")

       let textandAchorTagcombination=false;
       let isTextAnchorTextFormat=false;
        //check whether data.childeren is combined with text and anchor tag
        data.children.filter((obj,index)=>{
          if(obj.type=="a")
          {
            if(data.children[index-1])
            {
              if(data.children[index-1]['text']&&data.children[index-1]['text']!=='')
                {
                  textandAchorTagcombination=true
                  if(data.children[index+1])
                  {
                    if(data.children[index+1]['text']&&data.children[index+1]['text']!=='')
                    {
                      isTextAnchorTextFormat=true
                    }
                  }
                }
            } 
          }
        })

       // console.log(itemArr,childLength,"ffffffffffffffffffff");
        for (const [index, value] of data.children.entries()) {
          if(index==data.children.length-1)
          {
            isTextAnchorTextFormat=false;
          }
          if (value?.type === 'a') {
            str = str + `<a class="Article_link ${value.children[0].text==="article" ? "ps-0" :""} ${value?.link_type=="DMT" ? "d-block pt-3" : ""} ${textandAchorTagcombination?'articleWithSpan':'articleWithOutSpan'}" href='${value.href}?x=${userID}&y=${sessionID}' target='_blank' onclick="localStorage.setItem('artcle_LinkClicked', true)">`
            if (value?.children) {
              for (const value1 of value.children) {
                str = str + value1.text
              }
            }
            str = str + '</a>'
          } else {
              if (value?.strong) {
                str += `${data.children[0]?.text?.toLocaleLowerCase()?.includes("disclaimer")? "</br>":""}<strong class="summary_header_cls ${value?.text?.toLocaleLowerCase()?.includes("disclaimer")? "fs-16 font-italic" :""}">`;
                str += '<span> '+value.text+'</span>';
                str += '</strong>';
              }
              else if (value?.em) {
                str += `<em class="summary_header_cls ${data.children[0]?.text?.toLocaleLowerCase()?.includes("disclaimer")? "fs-16" :""}">`;
                str += value.text.charAt(0)=="."||value.text.charAt(0)==","? value.text: " "+value.text
                str += '</em>';
              }
              else {
                str += `<span class="summary_header_cls ${(contentHasStrongItem.length>0&&childLength==3 )? "":(value.text !== "" ? (contentHasStrongItem.length>0&&childLength>=2||isTextAnchorTextFormat)?"d-inline pb-4":"d-inline-block pb-1": "")}">`; 
              str += value.text.charAt(0)=="."||value.text.charAt(0)==","? value.text: " "+value.text;
                str += '</span>';
              }
          }
        }
        if(str){       
          str = str?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
        }
        object_data[key]['str_data'] = object_data[key]?.str_data ? object_data[key].str_data + str + '<br/>' : str + '<br/>';
        if(data.src){
          object_data[key]['video'] = data.src[0].url
        }
      }
      if(data?.type === 'ul'){
        str_ul="";
        str_ul_count=0
        recursive_ul_data(data.children);   
      }
      if(data?.type === 'ol'){
        recursive_ol_data(data.children);   
      }
      if(data?.type === 'table'){
        recursive_table_data(data.children);   
      }
      if (data?.type === 'img') {
        object_data[key]['img'] = data.src
      }
      if (data?.type === 'video') {
        object_data[key]['video'] = data.src[0].s3url ? data.src[0].s3url: data.src[0].url
        object_data[key]['srt'] = data.src[0].s3url ? data.src[0].SRT :"";
        object_data[key]['TN'] = data.src[0].s3url ? data.src[0].TN :"";
        object_data[key]['play_panopto']=data.src[0].s3url ? data.src[0].play_panopto :"";
      }
      if (data?.type === 'iframe') {
        object_data[key]['video'] = data.src
      }
      if(object_data[key]?.str_data === '<span class="summary_header_cls"></span><br/>'){
        object_data[key].str_data = '';
      }
    }

    function recursive_data(value) {
      let h_data = '', s_data = '', l_data = '';
      if (value.type == 'content' && value?.children.length > 0) {
        for (const value1 of value.children) {
          if (value1.type == 'h2' || value1.type == 'h1') {
            h_data = recursive_title_data(value1.children);
            h_data = h_data?.replace('\u200B','').replace(/[\xA0\u200B\u202F\x20]/g, " ")
            object_data[key]['title_data'] = object_data[key]?.title_data ? object_data[key].title_data + h_data : h_data;
          }
          else {
            recursive_content_data(value1);
          }
        }
      } else if (value.type == 'activity-reference') {
        object_data[key] = {};
        object_data[key]['activity'] = value;
      }
    }
    if (data?.type == 'group' && data?.children.length > 0) {
      setPurpose(data.purpose)
   
      for (const value of data.children) {
        recursive_data(value);
        key++;
      }
   
      setFinalData(object_data);
     // console.log(object_data,"finding the 135 data")
    }
  }, [])
  const handleCallbackChild = (flag) => {
    var activity;
    final_data.map((data) => {
      if (data?.activity?.activity_id != undefined) {
        activity = "false";
      }
    });
    if (activity) {
      if(flag == 'quiz_false'){
        props.parentCallback('quiz_false');
      }else if(flag == ''){
        props.parentCallback(flag);
      }else{
        props.parentCallback(activity);
      }
    }
  };
  // console.log(props.data?.children[0]?.children[0]?.content?.inputType,'kkkk')
  return (
    <>
      <div className={isNavbarExist ? "mt-5 " : ""}>
        {final_data.map((data, i) => (
          <Fragment key={i}>
            {
              purpose === 'walkthrough' ? (
                <ObjectiveComponent i={props.index} data={data} purpose={purpose}></ObjectiveComponent>
         
              ) :(
                <>
                  <div className="container myContainerCls mt-5" key={i}>
                    <div>
                      {data?.activity?.activity_id ? (
                        <>
                            {data.activity.children[0]  && props.data?.purpose != 'feedback' &&
                              data.activity.children[0].subType == "oli_multiple_choice" ? (
                              <ActivityElementMultipleChoice i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                              title={data.title_data} purpose={purpose}  quiz_review={props.quiz_review}></ActivityElementMultipleChoice>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_check_all_that_apply" ? (
                              <ActivityElementCheckAllApply i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose} quiz_review={props.quiz_review}></ActivityElementCheckAllApply>
                            ) : data.activity.children[0] && props.data?.purpose != 'feedback' && props.data?.purpose != 'labactivity' &&
                              data.activity.children[0].subType == "oli_short_answer" &&  props.data?.purpose != 'manystudentswonder'   ? (
                              <ActivityElementShortAnswer i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose}></ActivityElementShortAnswer>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_custom_dnd" ? (
                              <ActivityElementDragAndDrop i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} title={data.title_data} purpose={purpose}>
                                {" "}
                              </ActivityElementDragAndDrop >
                            ) : data.activity.children[0] && data.activity.children[0].subType == "oli_ordering" ? (
                              <ActivityElementOrdering i={props.index}
                                data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                                title={data.title_data} purpose={purpose}></ActivityElementOrdering>
                            ) : data.activity.children[0] &&
                              data.activity.children[0].subType == "oli_multi_input" ? (
                              <ActivityElementMultipleInput i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} title={data.title_data} purpose={purpose}></ActivityElementMultipleInput>
                            ) : data && props.data?.purpose == 'feedback' &&
                            data.activity.children[0].subType == "oli_multiple_choice" ?  (
                            <FeedbackMultipleChoice i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></FeedbackMultipleChoice>
                          ): data && props.data?.purpose == 'feedback' &&
                          data.activity.children[0].subType == "oli_short_answer" ?  (
                          <FeedbackOpenResponse i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></FeedbackOpenResponse>
                        )
                          :(
                            data && props.data?.purpose == 'about_quiz' &&
                            data.activity.children[0].subType == "about_quiz" ?  (
                            <AboutQuizComponent i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></AboutQuizComponent>
                          ):data && props.data?.purpose == 'labactivity' && props.data?.children[0]?.children[0]?.content?.inputType != "textarea" &&
                          data.activity.children[0].subType == "oli_short_answer"  ?  (
                          <ActivityInput i={props.index} data={data.activity.children[0]}  parentCallback={handleCallbackChild} purpose={purpose}></ActivityInput>):
                          data && props.data?.purpose == 'manystudentswonder' && props.data?.children[0]?.children[0]?.content?.inputType == "text"  ?  (
                            <ActivityFileUpload i={props.index}
                            data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                            title={data.title_data} purpose={purpose} />):
                         data.activity.children[0] && props.data?.purpose != 'feedback' && props.data?.purpose == 'labactivity' && props.data?.children[0]?.children[0]?.content?.inputType == "textarea" &&
                        data.activity.children[0].subType == "oli_short_answer" ? (
                        <ActivityElementShortAnswer i={props.index}
                          data={data.activity.children[0]}  parentCallback={handleCallbackChild}
                          title={data.title_data} purpose={purpose}></ActivityElementShortAnswer>
                      ):
                              <></>
                            )}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.img && !data.title_data && (!data.str_data) && (
                      <ImageComponent data={data} i={props.index} purpose={purpose}></ImageComponent>
                    )}
                    {data?.img && data?.title_data && (!data.str_data) && (props.data?.purpose != "example") &&(
                      <ImageDataComponent data={data} i={props.index} purpose={purpose}></ImageDataComponent>
                    )}
                    {data?.img && (data?.str_data) && (props.data?.purpose != "example") && (
                       
                      <ImageDataComponent data={data} i={props.index} purpose={purpose}></ImageDataComponent>              
                    )}
                    {data?.img && (data?.str_data) && (props.data?.purpose == "example") && (
                        <QuoteElement data={data} i={props.index} purpose={purpose}></QuoteElement>
                                 
                    )}
                    {data?.str_data && (!data.img) && !data.video &&  (
                      <DataComponent data={data} i={props.index} purpose={purpose} />
                    )}
                     {/* {props.data?.purpose == 'manystudentswonder'  && data?.str_data && (!data.img) && !data.video &&  (
                      <ActivityFileUpload data={data} i={props.index} purpose={purpose} />
                    )} */}
                    {data?.video && (
                      <VideoCheck data={data} i={props.index}/>
                    )}
                  </div>
                </>
              )
            }
          </Fragment>
        ))}
      </div>
    </>
  );
};
