import React, { useEffect, useState} from "react";
import axios from "../../axios-interceptor";
import {
  base_url,
  random_number,
  api_error_message,
  minimum_percentage,
  standalone_program_type,
  minimum_duration,
  admin_maintenance_type
} from "../../utilities";
import { Editor } from "@tinymce/tinymce-react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import { programmePolicyValidations } from "../../formValidationsRules";
import withMaintenanceCheck from "../../HigherOrderComponents/maintenancePageChecker";
import { Modal } from "react-bootstrap";

 const ProgrammePolicy = (props) => {
  let [data, setData] = useState({});
  const history = useHistory();
  const location = useLocation();
  let [loading_flag, SetLoading_flag] = useState(false);
  const [quizzesChecked, setQuizzesChecked] = useState(true);
  const [awardBadgeChecked, setAwardBadgeChecked] = useState(false);
  const [slasChecked, setSlasChecked] = useState(true);
  const [professorFeedbackInQuizChecked, setProfessorFeedbackInQuizChecked] = useState(false);
  const [genAIChecked, setGenAIChecked] = useState(false);
  const [awardCertificateChecked, setAwardCertificateChecked] = useState(false);
  const [courseCertificateChecked, setCourseCertificateChecked] = useState(false);
  const [distinctionCourseCertificateChecked, setDistinctionCourseCertificateChecked] = useState(false);
  const [distinctionProgrammeCertificateChecked, setDistinctionProgrammeCertificateChecked] = useState(false);
  const [endOfLessonAudioChecked, setEndOfLessonAudioChecked] = useState(false);
  const [endofLessonNotesSubmissionChecked, setEndofLessonNotesSubmissionChecked] = useState(false);
  const [showSubtitlesPanelChecked, setShowSubtitlesPanelChecked] = useState(false);
  const [ShowDictionaryChecked,setShowDictionaryChecked] = useState(false)
  const [programmeCertificateChecked, setProgrammeCertificateChecked] =
  useState(true);
  const [emailTemplateHeader, setEmailTemplateHeader] = useState("");
  const [supportEmailId,setSupportEmailId] = useState("");
  const [supportEmailName,setSupportEmailName] = useState("");
  const [emailErrors,setEmailErrors] = useState(false);
  const [nameErrors,setNameErrors] = useState(false);
  const save_button = document.querySelector(".save_button");
  const savedData = localStorage.getItem("save_obj");
  const [showModal, setShowModal] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm({ mode: "all" });
  const programme_id = location.state?.id;
  const learning_track_selected = localStorage.getItem(
    "learning_track_selected"
    );
  const learning_track_name = localStorage.getItem("learning_track_name");
  const [contentValues, setContentValues] = useState({
    freeLesson: "",
    applyNow: "",
    welcome: "",
    approvedEmail: "",
    declinedEmail: "",
  });

  const [subj_contentValues, setSubjContentValues] = useState({
    freelessonSub: "",
    applyNowSub: "",
    welcomeSub: "",
    approvedEmailSub: "",
    declinedEmailSub: "",
  });

  const editorData = [
    {
      key: "freeLesson",
      label: "Free Lesson Thank you Content",
      sub_label: "Free Lesson Thank you Subject",
      sub_key: "freelessonSub",
    },
    {
      key: "applyNow",
      label: "Apply Now Thank you Content",
      sub_label: "Apply Now Thank you Subject",
      sub_key: "applyNowSub",
    },
    {
      key: "welcome",
      label: "Welcome Email Content",
      sub_label: "Welcome Email Subject",
      sub_key: "welcomeSub",
    },
    {
      key: "approvedEmail",
      label: "Approved Email Content",
      sub_label: "Approved Email Subject",
      sub_key: "approvedEmailSub",
    },
    {
      key: "declinedEmail",
      label: "Declined Email Content",
      sub_label: "Declined Email Subject",
      sub_key: "declinedEmailSub",
    },
  ];

  useEffect(() => {
    if (JSON.parse(savedData)?.program_type === standalone_program_type) {
      setProgrammeCertificateChecked(false);
    }
    if (programme_id) {
      SetLoading_flag(true);
      axios
        .post(base_url + "program_policy/get_program_policy", {
          program_id: programme_id,
        })
        .then((res) => {
          if (res.data.status == 200) {
            setQuizzesChecked(res.data.data[0]?.quizzes);
            setSlasChecked(res.data.data[0]?.slas);
            setAwardBadgeChecked(res.data.data[0]?.badge_on_schedule);
            setAwardCertificateChecked(
              res.data.data[0]?.certificate_on_schedule
            );
            setProgrammeCertificateChecked(
              res.data.data[0]?.program_certificate
              );
              setValue("certificate_percentage", res.data.data[0]?.certificate_pass_percentage, {
                shouldDirty: true,
            });
            setValue("badge_percentage", res.data.data[0]?.badge_percentage, {
              shouldDirty: true,
            });
            setValue("course_duration", res.data.data[0]?.course_duration, {shouldDirty: true,
            });
            setValue("course_buffer", res.data.data[0]?.course_buffer_time, {shouldDirty: true,
            });
            setContentValues({
              freeLesson: res.data?.data[0]?.free_lesson_thank_you_content,
              applyNow: res.data?.data[0]?.apply_now_thank_you_content,
              welcome: res.data?.data[0]?.welcome_email_content,
              approvedEmail: res.data?.data[0]?.approved_email_content,
              declinedEmail: res.data?.data[0]?.declined_email_content,
            });
            setSubjContentValues({
              freelessonSub: res.data?.data[0]?.free_lesson_thank_you_subject,
              applyNowSub: res.data?.data[0]?.apply_now_thank_you_subject,
              welcomeSub: res.data?.data[0]?.welcome_email_subject,
              approvedEmailSub: res.data?.data[0]?.approved_email_subject,
              declinedEmailSub: res.data?.data[0]?.declined_email_subject,
            });
            setValue("trackURL", res.data?.data[0]?.learning_track_url, {
              shouldDirty: true,
            });
            setValue( "trackMessage",
              res.data?.data[0]?.learning_track_message,
              { shouldDirty: true }
            );
            setEmailTemplateHeader(
              res.data?.data[0]?.email_template_header_image
            );
            setProfessorFeedbackInQuizChecked(res.data.data[0]?.show_professor_feedback_in_quiz)
            setGenAIChecked(res.data.data[0]?.show_gen_ai)
            setCourseCertificateChecked(res.data.data[0]?.course_certificate)
            setValue("distinction_eligibility",res.data.data[0]?.distinction_percentage,{shouldDirty: true});
            setValue("course_certificate_eligibility",res.data.data[0]?.attendence_pecentage_for_certificate,{shouldDirty: true});
            setDistinctionCourseCertificateChecked(res.data.data[0]?.issue_distinction_course_certificate)
            setDistinctionProgrammeCertificateChecked(res.data.data[0]?.issue_distinction_programme_certificate)
            setEndOfLessonAudioChecked(res.data.data[0]?.show_mobile_end_of_lesson_audio)
            setEndofLessonNotesSubmissionChecked(res.data.data[0]?.show_mobile_end_of_lesson_notes_submission)
            setShowSubtitlesPanelChecked(res.data.data[0]?.show_mobile_show_subtitles_panel)
            setShowDictionaryChecked(res.data.data[0]?.show_dictionary)
            let support_email =res.data?.data[0]?.support_email
            const [SupportEmailName, SupportEmailId] = support_email? support_email?.split(/<|>/).map(str => str.trim()):['','']
          setSupportEmailName(SupportEmailName);
          setSupportEmailId(SupportEmailId);  
          }
          SetLoading_flag(false);
        })
        .catch((err) => {
          console.log(err);
          SetLoading_flag(false);
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    } else {
      setValue("trackURL", "", { shouldDirty: true });
      setValue("trackMessage", "", { shouldDirty: true });
      setValue("certificate_percentage", minimum_percentage)
      setValue("badge_percentage", minimum_percentage)
      setValue("distinction_eligibility",minimum_percentage)
      setValue("course_certificate_eligibility",minimum_percentage)
      setValue("course_duration", minimum_duration)
      setValue("course_buffer", minimum_duration)
    }
  }, []);

  const validateTrackURL = (value) => {
    if (
      JSON.parse(savedData)?.program_type === standalone_program_type &&
      !value
    ) {
      return "Track URL is required";
    }

    if (
      JSON.parse(savedData)?.program_type === standalone_program_type &&
      !/^https:\/\//.test(value)
    ) {
      return "Please enter a valid Learning Track URL starting with https";
    }

    return true;
  };

  const validateTrackMessage = (value) => {
    if (
      JSON.parse(savedData)?.program_type == standalone_program_type &&
      value.trim() === ""
    ) {
      return "Track Message is required";
    }
    return true;
  };

  const goBack = () => {
    history.goBack();
    history.push("/courseslist", { programme_id: programme_id });
  };

 
  const handleKeyDown = (event) => {
    const currentValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
    const keyPressed = event.key;
    const numericValue = parseInt(currentValue + keyPressed, 10);
    const fieldName = event.target.name;

    if ((currentValue === "" || (parseInt(currentValue) >= 1 && parseInt(currentValue) <= 100)) &&
        (fieldName === "certificate_percentage" || fieldName === "badge_percentage" ||
         fieldName === "distinction_eligibility" || fieldName === "course_certificate_eligibility")) {
            // Set the value based on field name
            setValue(fieldName, currentValue);
    } else if (numericValue > 1000) {
      event.preventDefault();
  }
  };

  const handleEditorChange = (content, editorKey) => {
    setContentValues((prevContentValues) => ({
      ...prevContentValues,
      [editorKey]: content,
    }));
  };
  
  const handleSuppportEmail = (event) => {
    const { name, value } = event.target;
    let sanitizedValue = value.trimStart();
    sanitizedValue = sanitizedValue.replace(/\s{2,}/g, ' ');
    if (name === "support_name") {
      setSupportEmailName(value ? sanitizedValue: "");
      if (sanitizedValue !== "" && (supportEmailId === "" ||  supportEmailId === undefined )) {
        setEmailErrors(true);
      }
      else if (sanitizedValue === "" && (supportEmailId !== undefined && supportEmailId!=="") ){
        setNameErrors(true);
      }else {
        setEmailErrors(false);
        setNameErrors(false);
      }
    } else if (name === "support_email") {
      let support_email_id = value
      setSupportEmailId(value ? value : "");
      if (support_email_id !== "" && supportEmailName === "") {
        setNameErrors(true);
      }
      else if (support_email_id === "" && supportEmailName !== ""){
        setEmailErrors(true);
      }
      else {
        setEmailErrors(false);
        setNameErrors(false);
      }
    }
  }

  const save_program_policy = (id) => {
    const formValues = getValues();
    let update_program_data = {
      program_id: id,
      certificate_pass_percentage: formValues.certificate_percentage,
      badge_percentage: formValues.badge_percentage,
      badge_on_schedule: awardBadgeChecked == true,
      certificate_on_schedule: awardCertificateChecked == true,
      slas: slasChecked == true,
      quizzes: quizzesChecked == true,
      program_certificate: programmeCertificateChecked == true,
      course_duration: formValues.course_duration,
      course_buffer_time: localStorage.getItem('course_buffer_time')?? formValues.course_buffer,
      free_lesson_thank_you_subject: subj_contentValues["freelessonSub"],
      free_lesson_thank_you_content: contentValues["freeLesson"],
      apply_now_thank_you_subject: subj_contentValues["applyNowSub"],
      apply_now_thank_you_content: contentValues["applyNow"],
      welcome_email_subject: subj_contentValues["welcomeSub"],
      welcome_email_content: contentValues["welcome"],
      approved_email_subject: subj_contentValues["approvedEmailSub"],
      approved_email_content: contentValues["approvedEmail"],
      declined_email_subject: subj_contentValues["declinedEmailSub"],
      declined_email_content: contentValues["declinedEmail"],
      learning_track_url: formValues.trackURL,
      learning_track_message: formValues.trackMessage,
      email_template_header_image: emailTemplateHeader,
      show_professor_feedback_in_quiz:professorFeedbackInQuizChecked,
      show_gen_ai:genAIChecked,
      course_certificate:courseCertificateChecked,
      distinction_percentage:formValues.distinction_eligibility||0,
      attendence_pecentage_for_certificate:formValues.course_certificate_eligibility||0,
      issue_distinction_course_certificate:distinctionCourseCertificateChecked,
      issue_distinction_programme_certificate:distinctionProgrammeCertificateChecked,
      show_mobile_end_of_lesson_audio:endOfLessonAudioChecked,
      show_mobile_end_of_lesson_notes_submission:endofLessonNotesSubmissionChecked,
      show_mobile_show_subtitles_panel:showSubtitlesPanelChecked,
      show_dictionary:ShowDictionaryChecked,
      support_email:(supportEmailName && supportEmailId) ?  `${supportEmailName?.trim()}<${supportEmailId}>` : "",       
    };
    axios
      .post(
        base_url + "program_policy/save_program_policy",
        update_program_data
      )
      .then((res) => {
        SetLoading_flag(false);
        if (save_button != null) {
          save_button.disabled = false;
        }
        if (res.data.status == 200) {
          if (res.data.message == "The given program name is already existed") {
            toast.error(res.data.message, { toastId: random_number });
          } else {
            toast.success(res.data.message, { toastId: random_number });
            localStorage.removeItem("learning_track_selected");
            localStorage.removeItem("learning_track_name");
            localStorage.removeItem("save_obj");
            localStorage.setItem("adminSelectedTab", "programlist");
            history.push("/admindashboard/programlist");
          }
        }
      })
      .catch((err) => {
        console.log(err);
        SetLoading_flag(false);
        save_button.disabled = false;
        if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
          localStorage.clear();
          history.push("/adminlogin");
          window.location.reload();
        } else {
          toast.warn(api_error_message, { toastId: random_number });
        }
      }); 
  };

  const updateProgram = () => {
    if ((supportEmailName === "" && (supportEmailId === "" || supportEmailId === undefined)) || (supportEmailName !== "" && (supportEmailId !== "" || supportEmailId !== undefined))){
        //show confirmation dialog
        setShowModal(true)
  }
  else if(supportEmailName === "") {
    setNameErrors(true)
  }
  else if (supportEmailId === "" || supportEmailId === undefined) {
    setEmailErrors(true);  
  }
};

  const handleUpdateProgramme=()=>{
      SetLoading_flag(true);
      if (save_button != null) {
        save_button.disabled = true;
      }
      axios
        .post(base_url + "update_program_details", JSON.parse(savedData))
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "The given program name is already existed") {
              SetLoading_flag(false);
              if (save_button != null) {
                save_button.disabled = false;
              }
              toast.error(res.data.message, { toastId: random_number });
            } else {
              save_program_policy(programme_id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          SetLoading_flag(false);
          if (save_button != null) {
            save_button.disabled = false;
          }
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
  }

  const saveProgram = () => {
    if ((supportEmailName === "" && (supportEmailId === "" || supportEmailId === undefined)) || (supportEmailName !== "" && (supportEmailId !== ""|| supportEmailId !== undefined))){
    SetLoading_flag(true);
    if (save_button != null) {
      save_button.disabled = true;
    }
    if (learning_track_selected == "false") {
      axios
        .post(base_url + "save_custom_program", JSON.parse(savedData))
        .then((res) => {
          if (res.data.status == 200) {
            if (
              res.data.message == "The given program name is already existed"
            ) {
              SetLoading_flag(false);
              if (save_button != null) {
                save_button.disabled = false;
              }
              toast.error(res.data.message, { toastId: random_number });
            } else {
              save_program_policy(res.data.program._id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          SetLoading_flag(false);
          if (save_button != null) {
            save_button.disabled = false;
          }
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
    } else {
      axios
        .post(base_url + "save_program", JSON.parse(savedData))
        .then((res) => {
          if (res.data.status == 200) {
            if (
              res.data.message == "The given program name is already existed"
            ) {
              SetLoading_flag(false);
              if (save_button != null) {
                save_button.disabled = false;
              }
              toast.error(res.data.message, { toastId: random_number });
            } else {
              save_program_policy(res.data.program._id);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          SetLoading_flag(false);
          if (save_button != null) {
            save_button.disabled = false;
          }
          if (err.response?.statusText == "Unauthorized" || err.response?.data?.statusText == "Unauthorized") {
            localStorage.clear();
            history.push("/adminlogin");
            window.location.reload();
          } else {
            toast.warn(api_error_message, { toastId: random_number });
          }
        });
  }  
}
else if(supportEmailName === "") {
  setNameErrors(true)
}
else if (supportEmailId === "" || supportEmailId === undefined) {
  setEmailErrors(true);
}
  };

  const handledialogCancel=()=>{
    setShowModal(false)
  }
  const handledialogConfirm=()=>{
    setShowModal(false)
    handleUpdateProgramme()
  }
  return (
    <>
      {loading_flag && (
        <div>
          <img
            className="atrium_loader"
            src="/images/atrium_loader.gif"
            alt="Loader"
          />
        </div>
      )}
      <div className="p-3 pt-5 mt-2 mb-0 gap-4">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="mb-0 programme_config">Programme Configuration</h3>
            </div>
            <div className="col-auto save_back_button">
              <div className="d-flex">
                <button
                  data-testid="goBack_btn"
                  className="btn my_outline_button_cls align-self-end mt-0 p-2 program_btn_back"
                  onClick={goBack}
                >
                  Back
                </button>
                &nbsp;
                {programme_id ? (
                  <button
                    type="button" data-testid="save-button"
                    className="my_btn_cls p-2 mt-0 save_button program_btn"
                    onClick={handleSubmit(updateProgram)}
                  >
                    Update
                  </button>
                ) : (
                  <button
                    type="button" data-testid="save-button"
                    className="my_btn_cls p-2 mt-0 program_btn save_button"
                    onClick={handleSubmit(saveProgram)}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>    
      <div className="bg-body container p-5 mt-5 mb-2 gap-4 course_list_container">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-auto me-auto">
              <h3 className="mb-0">{learning_track_name}</h3>
            </div>
          </div>
        </div>
        <div className="d-flex flex-row gap-3">
          <div className="col-lg-12">
            <label htmlFor="exampleInput">
              Programme Name (For Internal Reference)
            </label>
            <input
              type="text"
              className="form-control"
              id=""
              name=""
              disabled
              placeholder=" "
              role="presentation"
              autoComplete="off"
              data-testid="ProgrammeName"
              value={JSON.parse(savedData)?.program_id}
            />
          </div>
        </div>
        <div className="col-lg-12">
          <div className="mb-4">
            <span className="fw-bold program_policy_heading">
              Programme Policies
            </span>
          </div>
          {/* 1st section */}
          <div className="pl-4">
            <div className="mb-3 mt-2">
              <span className="policy_section_headings">Learning Experience</span>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-testid="quizzesCheckbox"
                      id="checkbox1"
                      checked={quizzesChecked}
                      onChange={() => setQuizzesChecked(!quizzesChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox1">
                      Quizzes
                    </label>
                  </div>
                </div>
                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox2"
                      data-testid="SLAsCheckbox"
                      checked={slasChecked}
                      onChange={() => setSlasChecked(!slasChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox2">
                      SLAs
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="col">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox3"
                      data-testid="ProfessorFeedbackCheckbox"
                      checked={professorFeedbackInQuizChecked}
                      onChange={() => setProfessorFeedbackInQuizChecked(!professorFeedbackInQuizChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox3">
                      Professor Feedback in Quiz?
                    </label>
                  </div>
                </div>
                <div className="col">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox4"
                      data-testid="GenAICheckbox"
                      checked={genAIChecked}
                      onChange={() =>
                        setGenAIChecked(!genAIChecked)
                      }
                    />
                    <label className="form-check-label" htmlFor="checkbox4">
                      Gen AI
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 2nd section */}
          <div className="pl-4">
            <div className="mb-3 mt-4">
              <span className="policy_section_headings">Badges & Certificates</span>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox5"
                      data-testid="awardBadgeCheckbox"
                      checked={awardBadgeChecked}
                      onChange={() => setAwardBadgeChecked(!awardBadgeChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox5">
                      Award Badge on Schedule?
                    </label>
                  </div>
                </div>

                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox6"
                      data-testid="awardCertificateCheckbox"
                      checked={awardCertificateChecked}
                      onChange={() => setAwardCertificateChecked(!awardCertificateChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox6">
                      Award Certificate on Schedule?
                    </label>
                  </div>
                </div>

                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox7"
                      data-testid="CourseCertificateToBeIssuedCheckbox"
                      checked={courseCertificateChecked}
                      onChange={() => setCourseCertificateChecked(!courseCertificateChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox7">
                      Course Certificate To Be Issued?
                    </label>
                  </div>
                </div>

                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox8"
                      data-testid="programCertificateCheckbox"
                      checked={programmeCertificateChecked}
                      onChange={() =>
                        setProgrammeCertificateChecked(
                          !programmeCertificateChecked
                        )
                      }
                    />
                    <label className="form-check-label" htmlFor="checkbox8">
                      Programme Certificate To Be Issued?
                    </label>
                  </div>
                </div>
                {/* <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox9"
                      data-testid="DistinctionCourseCertificateCheckbox"
                      checked={distinctionCourseCertificateChecked}
                      onChange={() => setDistinctionCourseCertificateChecked(!distinctionCourseCertificateChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox9">
                      Distinction Course Certificate To Be Issued?
                    </label>
                  </div>
                </div> */}
                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox10"
                      data-testid="DistinctionProgrammeCertificateCheckbox"
                      checked={distinctionProgrammeCertificateChecked}
                      onChange={() => setDistinctionProgrammeCertificateChecked(!distinctionProgrammeCertificateChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox10">
                      Distinction Programme Certificate To Be Issued?
                    </label>
                  </div>
                </div>
              </div>
          {/* 2rd section with 2nd col */}
              <div className="col-6">
               <div className="col d-flex align-items-center">
                  <div className="form-check mb-2 pl-lg-0">
                    <div className="input-percentage-checkbox programmpolicy_main">
                      <input
                        {...register("badge_percentage", {
                          required: "Please input a number between 0 to 100.",
                          pattern: {
                            value: /^(100|[1-9]?[0-9])$/,
                            message: "Please input a number between 0 to 100.",
                          },
                        })}
                        name="badge_percentage"
                        type="text"
                        className="form-control mb-0"
                        id="checkbox11"
                        data-testid="badge_percentage"
                        style={errors.badge_percentage?.message ? { width: "58px", textAlign: "left", borderRadius: "5px", borderColor: "red" } : { width: "px", textAlign: "left",borderRadius:"5px" }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className={errors.badge_percentage?.message ? "input-percentage-addon-checkbox form-check pl-lg-0 red_border_input percentage" : "input-percentage-addon-checkbox form-check pl-lg-0 percentage"}>%</span> 
                    </div>
                  </div>
                  <label className="ms-2 mb-2" htmlFor="checkbox11">
                    Badge Eligibility<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                {errors.badge_percentage?.message && (
                  <div className="col">
                    <p className="mb-2 mt-0" style={{ color: "red" }}>{errors.badge_percentage?.message}</p>
                  </div>
                )}

               <div className="col d-flex align-items-center">
                  <div className="form-check mb-2 pl-lg-0">
                    <div className="input-percentage-checkbox programmpolicy_main">
                      <input
                        {...register("certificate_percentage", {
                          required: "Please input a number between 0 to 100.",
                          pattern: {
                            value: /^(100|[1-9]?[0-9])$/,
                            message: "Please input a number between 0 to 100.",
                          },
                        })}
                        name="certificate_percentage"
                        type="text"
                        className="form-control mb-0"
                        id="checkbox12"
                        data-testid="certificate_percentage"
                        style={errors.certificate_percentage?.message ? { width: "58px", textAlign: "left", borderRadius: "5px", borderColor: "red" } : { width: "58px", textAlign: "left",borderRadius:"5px" }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className={errors.certificate_percentage?.message ? "input-percentage-addon-checkbox form-check pl-lg-0 red_border_input percentage" : "input-percentage-addon-checkbox form-check pl-lg-0 percentage"}>%</span>
                    </div>
                  </div>
                  <label className="ms-2 mb-2" htmlFor="checkbox12">
                 Course Certificate Eligibility<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                {errors.certificate_percentage?.message && (
                  <div className="col">
                    <p className="mb-2 mt-0" style={{ color: "red" }}>{errors.certificate_percentage?.message}</p>
                  </div>
                )}

                <div className="col d-flex align-items-center">
                  <div className="form-check mb-2 pl-lg-0">
                    <div className="input-percentage-checkbox programmpolicy_main">
                      <input
                        {...register("course_certificate_eligibility", {
                          required: "Please input a number between 0 to 100.",
                          pattern: {
                            value: /^(100|[1-9]?[0-9])$/,
                            message: "Please input a number between 0 to 100.",
                          },
                        })}
                        name="course_certificate_eligibility"
                        type="text"
                        className="form-control mb-0"
                        id="checkbox14"
                        data-testid="course_certificate_eligibility"
                        style={errors.course_certificate_eligibility?.message ? { width: "58px", textAlign: "left", borderRadius: "5px", borderColor: "red" } : { width: "58px", textAlign: "left",borderRadius:"5px" }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className={errors.course_certificate_eligibility?.message ? "input-percentage-addon-checkbox form-check pl-lg-0 red_border_input percentage" : "input-percentage-addon-checkbox form-check pl-lg-0 percentage"}>%</span>
                    </div>
                  </div>
                  <label className="ms-2 mb-2" htmlFor="checkbox14">
                  ITA Attendance Eligibility - Course Certificate<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                {errors.course_certificate_eligibility?.message && (
                  <div className="col">
                    <p className="mb-2 mt-0" style={{ color: "red" }}>{errors.course_certificate_eligibility?.message}</p>
                  </div>
                )}

<div className="col d-flex align-items-center">
                  <div className="form-check mb-2 pl-lg-0">
                    <div className="input-percentage-checkbox programmpolicy_main">
                      <input
                        {...register("distinction_eligibility", {
                          required: "Please input a number between 0 to 100.",
                          pattern: {
                            value: /^(100|[1-9]?[0-9])$/,
                            message: "Please input a number between 0 to 100.",
                          },
                        })}
                        name="distinction_eligibility"
                        type="text"
                        className="form-control mb-0"
                        id="checkbox13"
                        data-testid="distinction_eligibility"
                        style={errors.distinction_eligibility?.message ? { width: "58px", textAlign: "left", borderRadius: "5px", borderColor: "red" } : { width: "58px", textAlign: "left",borderRadius:"5px" }}
                        onKeyDown={handleKeyDown}
                      />
                      <span className={errors.distinction_eligibility?.message ? "input-percentage-addon-checkbox form-check pl-lg-0 red_border_input percentage" : "input-percentage-addon-checkbox form-check pl-lg-0 percentage"}>%</span>
                    </div>
                  </div>
                  <label className="ms-2 mb-2" htmlFor="checkbox13">
                 Programme Distinction Certificate Eligibility<span style={{ color: "red" }}>*</span>
                  </label>
                </div>
                {errors.distinction_eligibility?.message && (
                  <div className="col">
                    <p className="mb-2 mt-0" style={{ color: "red" }}>{errors.distinction_eligibility?.message}</p>
                  </div>
                )}
              </div>    
            </div>
          </div>

          {/* 3rd section */}
          <div className="pl-4">
            <div className="mb-3 mt-3">
              <span className="policy_section_headings">Mobile Features</span>
            </div>
            <div className="row">
              <div className="col-6">
                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      data-testid="EndofLessonAudioCheckbox"
                      id="checkbox15"
                      checked={endOfLessonAudioChecked}
                      onChange={() => setEndOfLessonAudioChecked(!endOfLessonAudioChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox15">
                      End of Lesson Audio
                    </label>
                  </div>
                </div>

                <div className="col pl-lg-1">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox16"
                      data-testid="EndofLessonNotesSubmissionCheckbox"
                      checked={endofLessonNotesSubmissionChecked}
                      onChange={() => setEndofLessonNotesSubmissionChecked(!endofLessonNotesSubmissionChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox16">
                      End of Lesson Notes Submission
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="col">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox17"
                      data-testid="ShowSubtitlesPanelCheckbox"
                      checked={showSubtitlesPanelChecked}
                      onChange={() => setShowSubtitlesPanelChecked(!showSubtitlesPanelChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox17">
                      Show Subtitles Panel
                    </label>
                  </div>
                </div>

                <div className="col">
                  <div className="form-check mb-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="checkbox18"
                      data-testid="ShowDictionaryCheckbox"
                      checked={ShowDictionaryChecked}
                      onChange={() => setShowDictionaryChecked(!ShowDictionaryChecked)}
                    />
                    <label className="form-check-label" htmlFor="checkbox18">
                      Show Dictionary
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>      
          </div>
        <>
        <div className="col-lg-12">
        <label htmlFor="support_name" className="fw-bold">
          Support Email Name
          {nameErrors && <span className="red-color">*</span>}
        </label>
        <input
          type="text"
          className="form-control mb-0"
          id="support_name"
          data-testid="support_name"
          name="support_name"
          value={supportEmailName}
          onChange={handleSuppportEmail}
        />
        {nameErrors && (
          <span className="text-danger mt-1">Please enter support email sender name</span>
        )}
      </div>
        <div className="col-lg-12">
        <label htmlFor="support_email" className="fw-bold">
          Support Email ID
          {emailErrors && <span className="red-color">*</span>}
        </label>
        <input
        {...register("support_email", programmePolicyValidations.support_email)}
          type="text"
          className="form-control"
          id="support_email"
          data-testid="support_email"
          name="support_email"
          value={supportEmailId}
          onChange={handleSuppportEmail}
        />
        {emailErrors && (
          <span className="text-danger mt-1">Please enter support email</span>
        )}
         {errors.support_email && (
          <span className="text-danger">{errors.support_email.message}</span>
        )}
      </div>
    
          {editorData.map((editor) => (
            <div
              className="col-lg-12"
              key={editor.key}
              style={{ marginBottom: "70px" }}
            >
              <div>
                <label className="fw-bold" htmlFor={editor.sub_key}>
                  {editor.sub_label}
                </label>
                <input
                  type="text"
                  className="form-control"
                  id={editor.sub_key}
                  name={editor.sub_key}
                  data-testid={editor.sub_key}
                  value={subj_contentValues[editor.sub_key]}
                  onChange={(e) =>
                    setSubjContentValues((prevState) => ({
                      ...prevState,
                      [editor.sub_key]: e.target.value,
                    }))
                  }
                />
              </div>
              <label className="fw-bold" htmlFor={editor.key}>
                {editor.label}
              </label>
              <Editor
                apiKey="1jl4n1cka72zftyk5y5sq27ioibmxki6jukqbch7ysalixae"
                cloudChannel="5-stable"
                className="mt-3"
                disabled={false}
                inline={false}
                onEditorChange={(content) =>
                  handleEditorChange(content, editor.key)
                }
                plugins=""
                tagName="div"
                textareaName={editor.key}
                value={contentValues[editor.key]}
                toolbar="undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat forecolor backcolor"
                init={{
                  height: 300,
                  language: "en",
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  convert_urls: false,
                }}
                aria-required={true}
              />
            </div>
          ))}
          <div>
          <>
          <div className="col-lg-12">
            <label htmlFor="exampleInput" className="fw-bold">
              Learning Track URL (for Standalone Courses Secondary CTA)
            </label>
            {JSON.parse(savedData)?.program_type == standalone_program_type && (
              <span className="requiredInput">*</span>
            )}
            <input
              {...register("trackURL", {
                validate: validateTrackURL,
              })}
              type="text"
              className="form-control"
              id="trackURL"
              name="trackURL"
              data-testid="trackURL"
              placeholder=""
              role="presentation"
              autoComplete="off"
              required={
                JSON.parse(savedData)?.program_type == standalone_program_type
              }
            />
            {errors.trackURL?.message && (
              <span style={{ color: "red" }}>{errors.trackURL?.message}</span>
            )}
          </div>
          <div className="col-lg-12">
            <label htmlFor="exampleInput" className="fw-bold">
              Learning Track Message (for Standalone Courses Secondary CTA Box)
            </label>
            {JSON.parse(savedData)?.program_type == standalone_program_type && (
              <span className="requiredInput">*</span>
            )}
            <input
              {...register("trackMessage", {
                validate: validateTrackMessage,
              })}
              type="text"
              className="form-control"
              id="trackMessage"
              data-testid="trackMessage"
              name="trackMessage"
              role="presentation"
              required={
                JSON.parse(savedData)?.program_type == standalone_program_type
              }
            />
            {errors.trackMessage?.message && (
              <span style={{ color: "red" }}>
                {errors.trackMessage?.message}
              </span>
            )}
          </div>
          <div className="col-lg-12">
            <label htmlFor="exampleInput" className="fw-bold">
              Email Template Header Image URL
            </label>
            <input
              type="text"
              className="form-control"
              id="email_template"
              data-testid="email_template"
              name="email_template"
              value={emailTemplateHeader}
              role="presentation"
              onChange={(e) => setEmailTemplateHeader(e.target.value)}
            />
          </div> 
          </>
          </div>
        </>
      </div>
      
      {showModal && (
        <Modal show={showModal}  className="programme_setup_dialog  popup_bgcolor" backdrop="static">
          <Modal.Header>
          </Modal.Header>
          <Modal.Body className='pt-0 mt-4'>
            <div className="text-center">
              <span className="text-center popup_heading">Confirm Message!</span>
            </div>
            <div className="text-center mt-2 mb-3">
              <span>
              The changes you have made will affect the User experience. Are you sure you want to update?
              </span>
            </div>
            <div className="d-flex justify-content-center gap-3 mb-1">
              <button className="btn bordered_btn" data-testid="programme_warning_cancel" onClick={handledialogCancel}>Cancel</button>
              <button className="btn my_btn_cls popup_confirm_update_btn" data-testid="programme_warning_confirm" onClick={handledialogConfirm}>Update</button>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );

};
export default withMaintenanceCheck(ProgrammePolicy,admin_maintenance_type)