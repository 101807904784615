import { useEffect } from "react"
import { useRef,useLayoutEffect} from "react";
export const ActivityElementDragAndDrop = (props) =>{
    const drag_drop_Component=useRef(null)
useLayoutEffect(() => {
  props.i==0?
 setTimeout(() => {
     window.scrollTo({
       top: window.scrollY,
       left: 0,
       behavior: "smooth",
     });
   }, 350):
   setTimeout(() => {
       window.scrollTo({
        top:  drag_drop_Component.current.offsetTop-40,
        left: 0,
         behavior: "smooth",
       });
     }, 350)
}, []);
    useEffect(()=>{
        props.parentCallback(false);
    })
    return(
        <p data-testid="ActivityElementDragAndDrop_content">New Element Type coming soon.....</p>
//         <>
//         {/* <h2>{arr[0] && arr[0].title}</h2> */}
//         {/* <h5>{arr && arr.previewText}</h5> */}
// {/*         
// <div id="targetContainer">

// <div id="lefttitle" style={{position:'absolute',top:'10px'}} >Label1</div>
// <div id="middletitle" style={{position:'absolute',top:'60px'}} >Label2</div>
// <div id="bottomtitle" style={{position:'absolute',top:'110px'}} >Label3</div>
// <div id="inputdivs">
// <div input_ref="option1_area" id="d1" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" style={{left:'240px', top:'10px', width:'240px', height:'40px'}}> </div>
// <div input_ref="option2_area" id="d2" onDragStart={(e)=>startDrag(e)}  onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" style={{left:'240px', top:'60px', width:'240px', height:'40px'}}> </div>
// <div input_ref="option3_area" id="d3" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" style={{left:'240px', top:'110px', width:'240px', height:'40px'}}> </div>
// </div>
// </div> */}
// <div id="targetContainer">

// <div id="lefttitle"  style={{top:'10px',left:'5px'}} >Fluid multi team membership</div>
// <div id="middletitle"  style={{position:'absolute',top:'60px',left:'5px'}} >Flexible, shifting processes</div>
// <div id="bottomtitle" style={{position:'absolute',top:'110px',left:'5px'}} >Open office plan with meeting areas</div>
// <div id="righttitle"  style={{position:'absolute',top:'170px',left:'5px'}} >Governance is member intensive</div>
// <div id="inputdivs">
// <div input_ref="option1_area" id="d1" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" 
// style={{left:'240px', top:'10px', }}
// > 
// </div>
// <div input_ref="option2_area" id="d2" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" 
// style={{left:'240px', top:'60px',  }}
// > 
// </div>
// <div input_ref="option3_area" id="d3" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" 
// style={{left:'240px', top:'110px',  }}
// > 
// </div>
// <div input_ref="option" id="d4" onDragStart={(e)=>startDrag(e)} onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} className="target" 
// style={{left:'240px', top:'170px',  }}
// > 
// </div>
// </div>
// </div>

// <div className="container text-center border shadow-sm"  >
// <div onDrop={(e)=>drops(e)} onDragOver={(e)=>dragOver(e)} id="targetdiv">

// <span input_val="input1" id="div1" draggable="true" onDragStart={(e)=>startDrag(e)} className="initiator">Expertise can be used in multiple teams</span>
// <span input_val="input2" id="div2" draggable="true" onDragStart={(e)=>startDrag(e)} className="initiator">Ability to be agile and make quick changes</span>
// <span input_val="input3" id="div3" draggable="true" onDragStart={(e)=>startDrag(e)} className="initiator">Freedom of movement</span>
// <span input_val="input" id="div4" draggable="true" onDragStart={(e)=>startDrag(e)} className="initiator">Hands-on leadership and participation from everyone</span>

// {/* <div input_val="input1" id="div1" className="initiator" draggable="true" onDragStart={(e)=>startDrag(e)}>Initiator 1</div>
// <div input_val="input2" id="div2" className="initiator" draggable="true" onDragStart={(e)=>startDrag(e)}>Initiator 2</div>
// <div input_val="input3" id="div3" className="initiator" draggable="true" onDragStart={(e)=>startDrag(e)}>Initiator 3</div> */}
// </div>

// </div>
// <button className="btn my_btn_cls submit_button" onClick={resetAll}>
//           Reset
//         </button>
//         <button className="btn my_btn_cls submit_button">
//           Submit
//         </button>
//       </>
    )
}